import { fold, right } from 'fp-ts/es6/Either'
import { pipe } from 'fp-ts/lib/function'
import { JwtTokenProps } from '../types'
import {
  AppQuery,
  AppQueryRequest,
  AppQueryResponse,
  GetCountryListResponse,
  GetCountryListSuccessResponse,
  GetVersionResponse,
  PostAppQueryRequest,
  PostStoreCommentRequest,
  PostStoreCommentResponse,
  PutUpdateUserRequest,
  PutUpdateUserResponse
} from './api-types'
import { externalApi, formatAuthorizationHeader, onApiJsonValidationError } from './api-utils'
// import { PathReporter } from 'io-ts/lib/PathReporter'

export const postAppQuery =
  (controller: AbortController) =>
    async (postAppQueryRequest: PostAppQueryRequest): Promise<AppQueryResponse> => {
      const url = '/AppRegisteredUser/appQuery'

      const body: AppQueryRequest = { randomizeCounts: false }

      return externalApi({ apiType: 'hydra', doNotRetryStatusCodes: [] })
        .url(url)
        .signal(controller)
        .auth(formatAuthorizationHeader(postAppQueryRequest.jwtToken))
        .post(body)
        .json((json) => {
          if (json && json.simCards) {
            // TODO: Use for detailed error parsing
            // var testOutput = AppQuery.decode(json)
            // const errorMessage = PathReporter.report(testOutput)
            // console.log(errorMessage)
            return pipe(
              AppQuery.decode(json),
              fold(onApiJsonValidationError, (data) => right({ type: 'full-response', appQuery: data }))
            )
          }
          // TODO THIS NEED TO BE FIXED AS AppQueryPartial
          return pipe(
            formatAppQueryPartial(json),
            AppQuery.decode,
            fold(onApiJsonValidationError, (data) => right({ type: 'partial-response', appQuery: data }))
          )
        })
    }

const formatAppQueryPartial = (data: any) => ({
  ...data,
  availablePackages: (data.availablePackages = {
    recommendedPackageIndex: 0,
    packages: [],
    subscriptionPackages: [],
  }),
  simCards: (data.simCards = []),
  endUserPackages: (data.endUserPackages = {
    totalDataBytesBought: 0,
    remainingPercentage: 0,
    totalUnitsRemaining: 0,
    totalUnitsBought: 0,
    packages: [],
    packagesActive: [],
    dataBytesRemainingByZone: [],
  }),
  paymentMethodList: (data.paymentMethodList = []),
  paymentList: (data.paymentList = []),
  endUserGlobalId: (data.endUserGlobalId = ''),
  endUserCreatedDate: (data.endUserCreatedDate = ''),
  billingInformation: {
    country: '',
    email: '',
    line1: '',
  },
  icc_range_list: data.icc_range_list,
})

export const getCountryList =
  (controller: AbortController) =>
    (language: string): Promise<GetCountryListResponse> => {
      const url = `/HydraBackend/countryList2?lang=${language}`

      return externalApi({ apiType: 'hydra', doNotRetryStatusCodes: [] })
        .url(url)
        .signal(controller)
        .get()
        .json((json) =>
          pipe(
            GetCountryListSuccessResponse.decode(json),
            fold(onApiJsonValidationError, (data) => right(data))
          )
        )
    }

export const putUpdateUser =
  (controller: AbortController) =>
    ({ body, jwtToken }: PutUpdateUserRequest): Promise<PutUpdateUserResponse> => {
      const url = `/AppRegisteredUser/updateUser`

      return externalApi({ apiType: 'hydra', doNotRetryStatusCodes: [] })
        .url(url)
        .auth(formatAuthorizationHeader(jwtToken))
        .signal(controller)
        .put(body)
        .text()
    }

export const postStoreComment =
  (controller: AbortController) =>
    ({ body, jwtToken }: PostStoreCommentRequest): Promise<PostStoreCommentResponse> => {
      const { url, auth } = (() => {
        if (jwtToken) {
          return {
            url: '/AppRegisteredUser/storeComment',
            auth: formatAuthorizationHeader(jwtToken),
          }
        }
        return {
          url: '/HydraBackend/storeComment',
          auth: '',
        }
      })()

      return externalApi({ apiType: 'hydra', doNotRetryStatusCodes: [] })
        .url(url)
        .auth(auth)
        .signal(controller)
        .post(body)
        .text()
    }

// TODO add dataLoader support if this is used in production
export const deleteMyData = async ({ jwtToken }: JwtTokenProps) => {
  const url = '/AppRegisteredUser/deleteMyData?alsoRegistration=true'
  return externalApi({ apiType: 'auth', doNotRetryStatusCodes: [] })
    .auth(formatAuthorizationHeader(jwtToken))
    .url(url)
    .delete()
    .json()
}

export const getVersion = (): Promise<GetVersionResponse> => {
  const url = `/version?${Date.now()}`

  return externalApi({ apiType: 'local', doNotRetryStatusCodes: [] })
    .url(url)
    .get()
    .setTimeout(5000)
    .text((version) => right({ type: 'version', version }))
}

export const checkNetworkConnection = (): Promise<boolean> =>
  getVersion()
    .then((response) =>
      pipe(
        response,
        fold(
          (error) => {
            switch (error.type) {
              case 'ABORT_ERROR':
              case 'FETCH_ERROR':
                return false
              case 'API_ERROR':
              case 'AUTHENTICATION_EXPIRED_ERROR':
              case 'AUTHENTICATION_ERROR':
              case 'THROTTLE_ERROR':
              case 'WRONG_PIN_CODE':
                return true
            }
          },
          (_data) => true
        )
      )
    )
    .catch(() => false)
