import React from 'react'
import styled from 'styled-components'
import { Country, GetCountryListSuccessResponse } from '../../api/api-types'
import { COUNTRY_SELECT_ID } from '../../test-selectors'

const SelectContainer = styled.div`
  border-radius: 0.5rem;
  border: 0.125rem solid #00b7ae;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`

const Select = styled.select`
  border-color: transparent;
  outline: none;
  padding: 0.875rem;
  font-size: 14px;
  font-weight: 500;
  background: transparent;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
  width: 100%;
`

interface Props {
  onChange: (country: Country) => void
  className?: string
  countryList: GetCountryListSuccessResponse
  defaultCountry?: Country | undefined
}

const emptyPlaceholder = { name: '', twoLetterCode: '', threeLetterCode: '', numericCode: '' }

export const CountrySelect = ({ onChange, className, countryList, defaultCountry }: Props) => (
  <SelectContainer>
    <Select
      id={COUNTRY_SELECT_ID}
      className={className}
      defaultValue={defaultCountry !== undefined ? defaultCountry.twoLetterCode : undefined}
      onChange={(e) => {
        const country = countryList.find((c) => c.twoLetterCode === e.target.value)
        if (country) {
          return onChange(country)
        }
      }}
    >
      {[emptyPlaceholder].concat(countryList).map((country) => (
        <option key={country.twoLetterCode} value={country.twoLetterCode}>
          {country.name}
        </option>
      ))}
    </Select>
  </SelectContainer >
)
