import * as queryString from 'query-string'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SimCard } from '../../api/api-types'
import { AppQueryContext } from '../../context/AppQueryContext'
import { formatPageTitle } from '../../formatPageTitle'
import { clearIcc, getIcc } from '../../storage-api'
import { JwtTokenProps } from '../../types'
import { getSimNames } from '../../utils/appQuery-utils'
import { isValidIccNumber } from '../../utils/icc-utils'
import { formatPathQueryParam } from '../../utils/url-utils'
import { AdaptiveWrapper } from '../AdaptiveWrapper'
import { Page } from '../common/Page'
import { SignInWrapper } from '../SignIn/SignInWrapper'
import { RegisterWavelySimCard } from './RegisterWavelySimCard'

type Props = JwtTokenProps & { onSimAddedSuccess?: () => void }

export const RegisterSimCardRoute = ({ jwtToken, onSimAddedSuccess }: Props) => {
  const { t } = useTranslation()
  const context = useContext(AppQueryContext)
  const { from } = queryString.parse(location.search)
  useEffect(() => {
    document.title = formatPageTitle(t('registerSimCardRoute.pageTitle'))
  }, [t])

  const iccPrefixes = ['894542842000', '894542843000']

  const initialIcc = (() => {
    const icc = getIcc()
    clearIcc()
    return icc && isValidIccNumber(icc, context?.appQuery?.icc_range_list ?? iccPrefixes) ? icc : undefined
  })()

  const view = (
    <RegisterWavelySimCard
      registeredSimNames={getSimNames(context?.appQuery?.simCards || [])}
      from={formatPathQueryParam(from)}
      initialIcc={initialIcc}
      jwtToken={jwtToken}
      onCardRegisteredSuccess={(simCards: SimCard[]) => {
        if (context?.appQuery) {
          context.dispatch({ type: 'update-sim-cards', simCards })
        }
        if (onSimAddedSuccess) {
          onSimAddedSuccess()
        }
      }}
    />
  )

  return from ? (
    <Page menu={true} footer={true}>
      {view}
    </Page>
  ) : (
    <AdaptiveWrapper mobileView={view} desktopView={<SignInWrapper>{view}</SignInWrapper>} adaptiveEnabled={true} />
  )
}
