import React from 'react'
import { useLocation, Redirect, useHistory } from 'react-router'
import * as queryString from 'query-string'
import { urls } from '../../urls'
import { registerAccountWithEmailApi } from '../../api/api-auth'
import { LoadingPage } from '../Loading/LoadingPage'
import { useCountryListLoader } from '../../customHooks/useCountryListLoader'
import { CreateAccount } from './CreateAccount'

export const EmailCreateAccountRoute = () => {
  const location = useLocation()
  const history = useHistory()
  const { email } = queryString.parse(location.search)
  const countryList = useCountryListLoader()

  if (typeof email !== 'string') {
    return <Redirect to={urls.signIn} />
  }

  if (!countryList) {
    return <LoadingPage />
  }

  const api = registerAccountWithEmailApi(email)

  return (
    <CreateAccount
      countryList={countryList}
      api={api}
      onAccountCreated={(pinToken) => {
        if (pinToken !== undefined) {
          history.replace(urls.enterEmailLoginPinCode.clientUrl(email, pinToken))
        }
      }}
    />
  )
}
