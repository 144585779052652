import { clearCachedJwtToken, clearCachedJwtRefreshToken, clearNoSimActivated } from '../storage-api'
import { urls } from '../urls'

export const asyncSome = async <T>(
  array: T[],
  f: (value: T, index: number, array: T[]) => Promise<boolean>
): Promise<boolean> => {
  for (let index = 0; index < array.length; index++) {
    if (await f(array[index], index, array)) {
      return true
    }
  }
  return false
}

export const logOut = () => {
  clearCachedJwtToken()
  clearCachedJwtRefreshToken()
  clearNoSimActivated()
  location.href = urls.signIn
}
