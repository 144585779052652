import React from 'react'
import { Link } from 'react-router-dom'
import { LeftArrowIcon } from '../../icons/LeftArrowIcon'
import { StyledBaseButton } from '../Button/StyledBaseButton'

interface Props {
  to: string
  className?: string
  color: string
  text?: string
}

export const BackButton = ({ to, className, color, text }: Props) => (
  <StyledBaseButton
    css={`
      color: ${color};
    `}
    className={className}
    as={Link}
    to={to}
  >
    <LeftArrowIcon
      color={color}
      css={`
        margin-right: 0.625rem;
      `}
    />
    {text}
  </StyledBaseButton>
)
