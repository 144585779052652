export interface DataLayerEvent {
  event: string
  eventCategory: string
  eventAction: string
  eventLabel: string
  eventValue?: any
}

export const sendEvent = (event: DataLayerEvent | Record<string, unknown>): void => {
  window.dataLayer.push(event)
}
