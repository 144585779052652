import * as React from 'react'
declare const window: any

interface FreshChatProps {
  token: string
  host: string
  externalId?: string
  firstName: string
  lastName: string
  phone: string
  tags: string[]
  onClose: () => void
}

export const Freshchat = ({ token, host, externalId, firstName, lastName, phone, tags, onClose }: FreshChatProps) => {
  const loadScript = () =>
    new Promise((resolve, reject) => {
      const id = 'freshchat-lib'
      const script = document.createElement('script')
      script.async = true
      script.type = 'text/javascript'
      script.src = 'https://wchat.eu.freshchat.com/js/widget.js'
      script.id = id
      script.onerror = reject
      script.onload = resolve
      document.head.appendChild(script)
    })

  React.useEffect(() => {
    const updateUser = () =>
      window.fcWidget.user
        .setProperties({
          firstName,
          lastName,
          email: externalId,
          phone,
        })
        .then(
          window.fcWidget.user.update({
            meta: {
              countryCode: 'United States',
              application: 'Wavely',
              browserVersion: navigator.userAgent,
              browserLanguage: navigator.language,
            },
          })
        )

    loadScript()
      .then(() => {
        window.fcWidget.init({
          host,
          token,
          externalId: externalId || '',
          firstName,
          lastName,
          tags,
          open: true,
          config: {
            headerProperty: {
              appName: 'Wavely',
              hideChatButton: false,
              // backgroundColor: colors.teal,
            },
            cssNames: {
              expanded: 'custom_fc_expanded',
              widget: 'custom_fc_frame',
            },
            hideFAQ: true,
            agent: {
              hideName: true,
              hidePic: true,
              hideBio: true,
            },
            content: {
              headers: {
                channel_response: {
                  offline: ' ',
                },
              },
            },
          },
        })

        window.fcWidget.on('widget:closed', () => {
          onClose()
          window.fcWidget.destroy()
        })
        window.fcWidget.on('user:created', () => {
          window.fcWidget.user
            .get()
            .then(updateUser)
            .catch(() => {})
        })
      })
      .catch(() => {})

    return () => window.fcWidget.destroy()
  }, [externalId, firstName, host, lastName, phone, onClose, tags, token])

  return (
    <div
      css={`
        max-height: calc(100% - 100px);
        opacity: 1;
        height: 720px;
        width: 500px;
      `}
    />
  )
}
