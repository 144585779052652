import { fold } from 'fp-ts/es6/Either'
import { pipe } from 'fp-ts/lib/function'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMedia } from 'react-media'
import styled from 'styled-components'
import { postRequestSimPinCode, registerSimCardWithPinCodeAndAppQuery } from '../../api/api-sim'
import { SimCard } from '../../api/api-types'
import { redirectToSignInOnAuthenticationError, renewJwtTokenOnAuthenticationExpiredError } from '../../api/api-utils'
import { useDialogContext } from '../../context/DialogContext'
import { useErrorContext } from '../../context/ErrorContext'
import { useDataLoader } from '../../customHooks/useDataLoader'
import { exhaustiveCheck } from '../../exhaustive-switch-check'
import {
  FixedFooter,
  MobileContentWrapper,
  MobileSubtitle,
  MobileTitle,
  SignInToolbar,
} from '../../MobileStyledComponents'
import { REGISTER_SIM_BUTTON, REQUEST_SIM_PIN_BUTTON } from '../../test-selectors'
import { colors } from '../../theme'
import { iccNumberTestLength, isValidIccNumber } from '../../utils/icc-utils'
import { WavelyHeader, WavelyHelpText } from '../../wavely-text-styles'
import { Button } from '../Button/Button'
import { Checkbox } from '../Checkbox/Checkbox'
import { BackButton } from '../common/BackButton'
import { ValidationError } from '../common/styles'
import { PageTitle } from '../styles/PageTitle'
import {
  Header,
  HelpText,
  PhoneNumberInput,
  PinCodeInput,
  pinLength,
  SimCardIccEditor,
  SimCardNameEditor,
} from './RegisterSimCardComponents'

interface Props {
  jwtToken: string
  onCardRegisteredSuccess: (simCards: SimCard[]) => void
  initialIcc?: string
  from: string | null
  registeredSimNames: string[]
}

const Form = styled.form`
  label {
    display: block;
    margin-top: 20px;
  }
`
const TermsWrapper = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  background-color: #dcf8f9;
`

export const RegisterWavelySimCard = ({
  jwtToken,
  registeredSimNames,
  onCardRegisteredSuccess,
  initialIcc,
  from,
}: Props) => {
  const { t } = useTranslation()
  const { onError } = useErrorContext()
  const [icc, setIcc] = useState(initialIcc || '')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [pin, setPin] = useState('')
  const [name, setName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const { setDialog } = useDialogContext()
  const [simCardPinEntry, setSimCardPinEntry] = useState(false)
  const [twilioCheckBox, setTwilioCheckBox] = useState(false)
  const checkBoxHandleClick = () => setTwilioCheckBox(!twilioCheckBox)

  const postRequestSimPinCodeApi = useDataLoader(postRequestSimPinCode)
  const { query: postRequestSimPinCodeQuery, isLoading: isPostRequestSimPinCodeLoading } = postRequestSimPinCodeApi
  const registerSimCardWithPinCodeApi = useDataLoader(registerSimCardWithPinCodeAndAppQuery)
  const {
    query: registerSimCardWithPinCodeQuery,
    isLoading: isRegisterSimCardWithPinCodeApiLoading,
  } = registerSimCardWithPinCodeApi

  const onChange = (setValue: (value: React.SetStateAction<string>) => void) => (value: string) => {
    setErrorMessage('')
    setValue(value)
  }

  const iccIsInvalid = !(icc && isValidIccNumber(icc, []))
  const nameIsInvalid = registeredSimNames.find((simName) => simName === name)

  const isSmallScreen = useMedia({ query: '(max-width: 700px)' })

  const iccEntryDesktop = (
    <>
      {!from && (
        <>
          <WavelyHeader
            css="margin: 0 103.7px 5px 0; 
     @media screen and (min-width: 1024px) {
        grid-row: 1;
        grid-column-start: 1;
        grid-column-end: 13;
      }
    "
          >
            {t('registerSimCardRoute.title')}
          </WavelyHeader>
          <WavelyHelpText
            css=" margin: 5px 0 0; 
    @media screen and (min-width: 1024px) {
      grid-row: 2;
      grid-column-start: 1;
      grid-column-end: 13;
    }
"
          >
            {t('registerSimCardRoute.helpText')}
          </WavelyHelpText>
        </>
      )}

      <div
        css={`
          grid-column-start: 1;
          grid-column-end: 5;
          @media screen and (min-width: 600px) {
            grid-column-start: 1;
            grid-column-end: 9;
          }

          @media screen and (min-width: 1024px) {
            grid-column-start: 1;
            grid-column-end: 13;
          }
        `}
      >
        {from && (
          <>
            <BackButton to={from} color={colors.hydraGreen} text={t('registerSimCard.backButton')} />
            <PageTitle>{t('registerSimCard.pageTitle')}</PageTitle>
          </>
        )}
        <Form>
          <SimCardIccEditor
            placeholder={'Enter your ICC ID or click the QR code'}
            icc={icc}
            iccPrefixes={[]}
            validationError={iccIsInvalid ? t('registerSimCard.invalidSimNumber') : undefined}
            onChange={(e) => {
              const value = e.target.value.slice(0, iccNumberTestLength).replace(/\D/g, '')
              return onChange(setIcc)(value)
            }}
            onScannerValidRead={(icc: string) => {
              setIcc(icc)
              setDialog(null)
            }}
          />
          <PhoneNumberInput
            placeholder={'+1 123 456 7890'}
            phoneNumber={phoneNumber}
            onChange={(phoneNumber) => onChange(setPhoneNumber)(phoneNumber)}
          />
          <TermsWrapper>
            {/* <input type="checkbox" id="twilio" name="twilio" checked={twilioCheckBox} onClick={checkBoxHandleClick} style={{
            marginRight: "1rem",
          }} /> */}
            <Checkbox css="margin-top: -20px;" checked={twilioCheckBox} onChange={checkBoxHandleClick} />
            <WavelyHelpText css="margin-left: 1rem; font0-size: 1.2rem;">
              By continuing you will receive a one-time verification code to your phone number by SMS. Message and data
              rates may apply.
            </WavelyHelpText>
          </TermsWrapper>

          <Button
            id={REQUEST_SIM_PIN_BUTTON}
            css={`
              margin-top: 153px;
              width: 100%;
              box-shadow: 0 3px 20px 0 rgba(0, 183, 174, 0.4);
            `}
            disabled={isPostRequestSimPinCodeLoading || iccIsInvalid || !twilioCheckBox || phoneNumber.length < 3}
            loading={isPostRequestSimPinCodeLoading}
            text={'Get Activation PIN Code'}
            onClick={async (e) => {
              e.preventDefault()

              postRequestSimPinCodeQuery({
                jwtToken,
                body: {
                  icc,
                  phoneNumber,
                },
              })
                .then((response) =>
                  pipe(
                    response,
                    fold(
                      (error) => {
                        switch (error.type) {
                          case 'FETCH_ERROR':
                          case 'ABORT_ERROR':
                          case 'API_ERROR':
                          case 'THROTTLE_ERROR':
                          case 'WRONG_PIN_CODE':
                            return
                          case 'AUTHENTICATION_EXPIRED_ERROR':
                            return renewJwtTokenOnAuthenticationExpiredError()
                          case 'AUTHENTICATION_ERROR':
                            return redirectToSignInOnAuthenticationError()
                          default:
                            return exhaustiveCheck(error)
                        }
                      },
                      () => {
                        setSimCardPinEntry(true)
                      }
                    )
                  )
                )
                .catch(onError)
            }}
          />
          {errorMessage && (
            <ValidationError>{t('registerSimCard.simRegistrationError', { errorMessage })}</ValidationError>
          )}
        </Form>
      </div>
    </>
  )

  const iccEntryMobile = (
    <>
      <div>
        {
          // TODO: Verify how it works for logged in users
          from && (
            <>
              <BackButton to={from} color={colors.hydraGreen} text={t('registerSimCard.backButton')} />
            </>
          )
        }
        <Form>
          <MobileContentWrapper css="min-height: 800px;">
            <SignInToolbar />
            <MobileTitle>{t('registerSimCardRoute.title')}</MobileTitle>
            <MobileSubtitle css="margin-bottom: 0.3rem;">{t('registerSimCardRoute.helpText')}</MobileSubtitle>
            <SimCardIccEditor
              placeholder={'Enter your ICC ID or click the QR code'}
              icc={icc}
              iccPrefixes={[]}
              validationError={iccIsInvalid ? t('registerSimCard.invalidSimNumber') : undefined}
              onChange={(e) => {
                const value = e.target.value.slice(0, iccNumberTestLength).replace(/\D/g, '')
                return onChange(setIcc)(value)
              }}
              onScannerValidRead={(icc: string) => {
                setIcc(icc)
                setDialog(null)
              }}
            />
            <PhoneNumberInput
              placeholder={'+1 123 456 7890'}
              phoneNumber={phoneNumber}
              onChange={(phoneNumber) => onChange(setPhoneNumber)(phoneNumber)}
            />
            <TermsWrapper>
              {/* <input type="checkbox" id="twilio" name="twilio" checked={twilioCheckBox} onClick={checkBoxHandleClick} style={{
          marginRight: "1rem",
        }} /> */}
              <Checkbox css="margin-top: -20px;" checked={twilioCheckBox} onChange={checkBoxHandleClick} />
              <WavelyHelpText
                css="margin: 0 1rem 0; font-size: 11px;
"
              >
                By continuing you will receive a one-time verification code to your phone number by SMS. Message and
                data rates may apply.
              </WavelyHelpText>
            </TermsWrapper>

            {errorMessage && (
              <ValidationError>{t('registerSimCard.simRegistrationError', { errorMessage })}</ValidationError>
            )}
          </MobileContentWrapper>

          <FixedFooter css="background-color: transparent">
            <Button
              id={REQUEST_SIM_PIN_BUTTON}
              css={`
                :disabled {
                  opacity: 1;
                  cursor: default;
                }
                margin: 1.875rem 0;
                padding: 0.9375rem;
                width: 100%;
                box-shadow: 0 3px 20px 0 rgba(0, 183, 174, 0.4);
                background-color: #00b7ae;
                font-size: 14px;
                font-weight: 800;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.54;
                letter-spacing: normal;
                text-align: center;
                color: #ffffff;
              `}
              disabled={isPostRequestSimPinCodeLoading || iccIsInvalid || !twilioCheckBox || phoneNumber.length < 3}
              loading={isPostRequestSimPinCodeLoading}
              text={'Get Activation PIN Code'}
              onClick={async (e) => {
                e.preventDefault()

                postRequestSimPinCodeQuery({
                  jwtToken,
                  body: {
                    icc,
                    phoneNumber,
                  },
                })
                  .then((response) =>
                    pipe(
                      response,
                      fold(
                        (error) => {
                          switch (error.type) {
                            case 'FETCH_ERROR':
                            case 'ABORT_ERROR':
                            case 'API_ERROR':
                            case 'THROTTLE_ERROR':
                            case 'WRONG_PIN_CODE':
                              return
                            case 'AUTHENTICATION_EXPIRED_ERROR':
                              return renewJwtTokenOnAuthenticationExpiredError()
                            case 'AUTHENTICATION_ERROR':
                              return redirectToSignInOnAuthenticationError()
                            default:
                              return exhaustiveCheck(error)
                          }
                        },
                        () => {
                          setSimCardPinEntry(true)
                        }
                      )
                    )
                  )
                  .catch(onError)
              }}
            />
          </FixedFooter>
        </Form>
      </div>
    </>
  )

  const smsEntryDesktop = (
    <>
      {!from && (
        <>
          <Header>{`Enter your Activation PIN Code`}</Header>
          <HelpText>{`To activate your SIM Card, please enter the 4-digit PIN Code sent to you via SMS`}</HelpText>
        </>
      )}
      <div
        css={`
          grid-column-start: 1;
          grid-column-end: 5;
          @media screen and (min-width: 600px) {
            grid-column-start: 1;
            grid-column-end: 9;
          }

          @media screen and (min-width: 1024px) {
            grid-column-start: 1;
            grid-column-end: 13;
          }
        `}
      >
        {from && (
          <>
            <BackButton to={from} color={colors.hydraGreen} text={t('registerSimCard.backButton')} />
            <PageTitle>{t('registerSimCard.pageTitle')}</PageTitle>
          </>
        )}
        <Form>
          <PinCodeInput pin={pin} showHelpButton={false} onChange={(pinCode) => onChange(setPin)(pinCode)} />
          <div
            css={`
              margin-top: 1rem;
            `}
          >
            <SimCardNameEditor
              name={name}
              validationError={nameIsInvalid ? t('registerSimCard.nameAlreadyInUse', { name }) : undefined}
              onChange={(e) => onChange(setName)(e.target.value)}
            />
          </div>
          <Button
            id={REGISTER_SIM_BUTTON}
            css={`
              margin-top: 225px;
              padding: 0.9375rem;
              width: 100%;
              box-shadow: 0 3px 20px 0 rgba(0, 183, 174, 0.4);
            `}
            disabled={isRegisterSimCardWithPinCodeApiLoading || pin.length !== pinLength || !name || !!nameIsInvalid}
            loading={isRegisterSimCardWithPinCodeApiLoading}
            text={t('registerSimCard.add')}
            onClick={async (e) => {
              e.preventDefault()

              registerSimCardWithPinCodeQuery({
                jwtToken,
                body: {
                  icc,
                  pinCode: pin,
                  name,
                },
              })
                .then((response) =>
                  pipe(
                    response,
                    fold(
                      (error) => {
                        switch (error.type) {
                          case 'ABORT_ERROR':
                          case 'THROTTLE_ERROR':
                          case 'WRONG_PIN_CODE':
                            return
                          case 'FETCH_ERROR':
                          case 'API_ERROR':
                          case 'UNSUPPORTED_RESPONSE':
                            return onError(error, error.type)
                          case 'AUTHENTICATION_EXPIRED_ERROR':
                            return renewJwtTokenOnAuthenticationExpiredError()
                          case 'AUTHENTICATION_ERROR':
                            return redirectToSignInOnAuthenticationError()
                          default:
                            return exhaustiveCheck(error)
                        }
                      },
                      ({ appQuery }) => {
                        onCardRegisteredSuccess(appQuery.simCards)
                      }
                    )
                  )
                )
                .catch(onError)
            }}
          />
        </Form>
      </div>
    </>
  )

  const smsEntryMobile = (
    <>
      <MobileContentWrapper>
        {/* <SignInToolbar /> */}
        <MobileTitle css="margin-bottom: 20px; margin-top: 30px;">{`Enter your Activation PIN Code`}</MobileTitle>
        {/* <MobileSubtitle css="margin-bottom: 30px;">{`To activate your SIM Card, please enter the 4-digit PIN Code sent to you via SMS`}</MobileSubtitle> */}
        {from && (
          <>
            <BackButton to={from} color={colors.hydraGreen} text={t('registerSimCard.backButton')} />
          </>
        )}
        <Form>
          <PinCodeInput pin={pin} showHelpButton={false} onChange={(pinCode) => onChange(setPin)(pinCode)} />
          <div
            css={`
              margin-top: 1rem;
            `}
          >
            <SimCardNameEditor
              name={name}
              validationError={nameIsInvalid ? t('registerSimCard.nameAlreadyInUse', { name }) : undefined}
              onChange={(e) => onChange(setName)(e.target.value)}
            />
          </div>
        </Form>
      </MobileContentWrapper>
      <FixedFooter css="background-color: transparent">
        <Button
          id={REGISTER_SIM_BUTTON}
          css={`
            :disabled {
              opacity: 1;
              cursor: default;
            }
            width: 100%;
            box-shadow: 0 3px 20px 0 rgba(0, 183, 174, 0.4);
            background-color: #00b7ae;
            font-size: 14px;
            font-weight: 800;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.54;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
          `}
          disabled={isRegisterSimCardWithPinCodeApiLoading || pin.length !== pinLength || !name || !!nameIsInvalid}
          loading={isRegisterSimCardWithPinCodeApiLoading}
          text={t('registerSimCard.add')}
          onClick={async (e) => {
            e.preventDefault()

            registerSimCardWithPinCodeQuery({
              jwtToken,
              body: {
                icc,
                pinCode: pin,
                name,
              },
            })
              .then((response) =>
                pipe(
                  response,
                  fold(
                    (error) => {
                      switch (error.type) {
                        case 'ABORT_ERROR':
                        case 'THROTTLE_ERROR':
                        case 'WRONG_PIN_CODE':
                          return
                        case 'FETCH_ERROR':
                        case 'API_ERROR':
                        case 'UNSUPPORTED_RESPONSE':
                          return onError(error, error.type)
                        case 'AUTHENTICATION_EXPIRED_ERROR':
                          return renewJwtTokenOnAuthenticationExpiredError()
                        case 'AUTHENTICATION_ERROR':
                          return redirectToSignInOnAuthenticationError()
                        default:
                          return exhaustiveCheck(error)
                      }
                    },
                    ({ appQuery }) => {
                      onCardRegisteredSuccess(appQuery.simCards)
                    }
                  )
                )
              )
              .catch(onError)
          }}
        />
      </FixedFooter>
    </>
  )

  const iccEntryView = isSmallScreen && !from ? iccEntryMobile : iccEntryDesktop
  const smsEntryView = isSmallScreen && !from ? smsEntryMobile : smsEntryDesktop

  // Content Wrapper

  return !simCardPinEntry ? iccEntryView : smsEntryView
}
