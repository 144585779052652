import { useCallback, useEffect, useRef, useState } from 'react'
import { ApiParams } from '../api/api-types'

export type DataLoaderApi<K, T extends ApiParams> = (controller: AbortController) => (requestParams: T) => Promise<K>

export function useDataLoader<K, T>(api: DataLoaderApi<K, T>) {
  const [response, setResponse] = useState<K>()
  const [isLoading, setIsLoading] = useState(false)
  const controllerRef = useRef(new AbortController())
  const isMountedRef = useRef(true)

  const query = (arg: T) => {
    controllerRef.current = new AbortController()
    setIsLoading(true)

    return api(controllerRef.current)(arg).then((response) => {
      if (!isMountedRef.current) {
        return response
      }

      setIsLoading(false)
      setResponse(response)
      return response
    })
  }
  const queryCb = useCallback(query, [api])

  useEffect(
    () => () => {
      isMountedRef.current = false
      controllerRef.current.abort()
    },
    []
  )

  return { response, isLoading, query: queryCb }
}
