import { fold } from 'fp-ts/es6/Either'
import { pipe } from 'fp-ts/es6/pipeable'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getAccountProfile } from '../../../api/api-auth'
import { AccountProfile } from '../../../api/api-types'
import {
  redirectToSignInOnAuthenticationError,
  renewJwtTokenOnAuthenticationExpiredError,
} from '../../../api/api-utils'
import { config } from '../../../config'
import { useErrorContext } from '../../../context/ErrorContext'
import { useDataLoader } from '../../../customHooks/useDataLoader'
import { exhaustiveCheck } from '../../../exhaustive-switch-check'
import { formatPageTitle } from '../../../formatPageTitle'
import { colors } from '../../../theme'
import { urls } from '../../../urls'
import { BackButton } from '../../common/BackButton'
import { Page } from '../../common/Page'
import { LoadingPage } from '../../Loading/LoadingPage'
import { PageTitle } from '../../styles/PageTitle'
import { Freshchat } from './freshchat'

interface Props {
  jwtToken: string | null
}

export const SupportLiveChatPage = ({ jwtToken }: Props) => {
  const { t } = useTranslation()
  const { onError } = useErrorContext()
  const [state, setState] = useState<null | AccountProfile>(null)
  const { query: getProfileQuery } = useDataLoader(getAccountProfile)

  useEffect(() => {
    document.title = formatPageTitle('Online support')

    if (state) {
      return
    }

    if (jwtToken !== null) {
      getProfileQuery({ jwtToken })
        .then((response) =>
          pipe(
            response,
            fold(
              (error) => {
                switch (error.type) {
                  case 'ABORT_ERROR':
                    return
                  case 'THROTTLE_ERROR':
                  case 'WRONG_PIN_CODE':
                    return
                  case 'FETCH_ERROR':
                  case 'API_ERROR':
                  case 'UNSUPPORTED_RESPONSE':
                    return onError(error, error.type)
                  case 'AUTHENTICATION_EXPIRED_ERROR':
                    return renewJwtTokenOnAuthenticationExpiredError()
                  case 'AUTHENTICATION_ERROR':
                    return redirectToSignInOnAuthenticationError()
                  default:
                    return exhaustiveCheck(error)
                }
              },
              (profile) => setState(profile)
            )
          )
        )
        .catch(onError)
    }
  }, [onError, getProfileQuery, jwtToken, state, t])

  if (!state) {
    return <LoadingPage />
  }

  return (
    <Page menu={true} footer={true}>
      <div
        css={`
          grid-column-start: 1;
          grid-column-end: 5;
          @media screen and (min-width: 600px) {
            grid-column-start: 1;
            grid-column-end: 9;
          }

          @media screen and (min-width: 1024px) {
            grid-column-start: 1;
            grid-column-end: 13;
          }
        `}
      >
        <BackButton
          color={colors.hydraGreen}
          to={urls.support.root}
          text={'Support'}
          css={`
            display: flex;
            align-items: center;
            justify-content: flex-start;
          `}
        />
        <PageTitle>Online support</PageTitle>
        <Freshchat
          token={config.freshchatToken}
          host={'https://wchat.eu.freshchat.com'}
          externalId={state.email}
          firstName={state.firstName ?? ''}
          lastName={state.lastName ?? ''}
          phone={''}
          tags={['en_Wavely']}
          onClose={() => {
            history.back()
          }}
        />
      </div>
    </Page>
  )
}
