import React from 'react'
import { IconWithColor } from '../types'
import HomeIconPng from '../../assets/wavely_home.png'

export const HomeIcon = (props: IconWithColor) => (
  <svg className={props.className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g id="icons/_glyphs/home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon fill={props.color} points="10 20 10 14 14 14 14 20 19 20 19 12 22 12 12 3 2 12 5 12 5 20" />
    </g>
  </svg>
)

interface Props {
  margin: boolean
}

export const WavelyHomeIcon = ({ margin }: Props) => (
  <img src={HomeIconPng} width="30px" height="30px" style={margin ? { marginRight: '5px' } : { marginRight: '0px' }} />
  // <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  //   <circle cx="15" cy="15" r="15" fill="#DBE9F7" />
  //   <path d="M22.92 11.202L15.7609 5.59882C15.641 5.50505 15.4931 5.4541 15.3409 5.4541C15.1887 5.4541 15.0408 5.50505 14.9209 5.59882L7.76182 11.202C7.68033 11.2657 7.61441 11.3471 7.56905 11.4401C7.52369 11.5331 7.50008 11.6352 7.5 11.7386V20.2702C7.5 20.451 7.57183 20.6244 7.6997 20.7523C7.82757 20.8802 8.00099 20.952 8.18182 20.952H12.6136V16.8611C12.6136 16.1378 12.901 15.4441 13.4124 14.9326C13.9239 14.4212 14.6176 14.1338 15.3409 14.1338C16.0642 14.1338 16.7579 14.4212 17.2694 14.9326C17.7808 15.4441 18.0682 16.1378 18.0682 16.8611V20.952H22.5C22.6808 20.952 22.8543 20.8802 22.9821 20.7523C23.11 20.6244 23.1818 20.451 23.1818 20.2702V11.7386C23.1817 11.6352 23.1581 11.5331 23.1128 11.4401C23.0674 11.3471 23.0015 11.2657 22.92 11.202Z" fill="url(#paint0_linear)" />
  //   <defs>
  //     <linearGradient id="paint0_linear" x1="15.3409" y1="5.4541" x2="15.3409" y2="20.952" gradientUnits="userSpaceOnUse">
  //       <stop stopColor="#6196E7" />
  //       <stop offset="1" stopColor="#487ED0" />
  //     </linearGradient>
  //   </defs>
  // </svg>
)
