import * as queryString from 'query-string'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router'
import { useHistory } from 'react-router-dom'
import { formatPageTitle } from '../../formatPageTitle'
import { urls } from '../../urls'
import { AdaptiveWrapper } from '../AdaptiveWrapper'
import { PinActivation } from './PinActivation'
import { PinActivationMobile } from './PinActivationMobile'

export const EmailPinCodeLoginRoute = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { email, code } = queryString.parse(location.search)
  useEffect(() => {
    document.title = formatPageTitle(t('emailPinCodeLoginRoute.pageTitle'))
  }, [t])

  if (typeof email !== 'string' || typeof code !== 'string') {
    return <Redirect to={urls.signIn} />
  }

  return (
    <AdaptiveWrapper
      mobileView={
        <PinActivationMobile
          onPinToken={(token: string) => history.replace(urls.enterEmailLoginPinCode.clientUrl(email, token))}
          magicLinkResendToken={code}
          email={email}
        />
      }
      desktopView={
        <PinActivation
          onPinToken={(token: string) => history.replace(urls.enterEmailLoginPinCode.clientUrl(email, token))}
          magicLinkResendToken={code}
          email={email}
        />
      }
      adaptiveEnabled={true}
    />
  )
}
