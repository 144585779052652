import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingPage } from './LoadingPage'

interface Props {
  children: React.ReactNode
}

export const LocalisationLoader = (props: Props) => {
  const { i18n } = useTranslation()
  const [currentLanguage, setCurrentLanguage] = useState<string | null>(i18n.language || null)
  const [storeAddedEmitted, setStoreAddedEmitted] = useState<Record<string, boolean>>({})
  const languageIsloaded = (lang: string): boolean => i18n.isInitialized && !!i18n.getDataByLanguage(lang)
  const currentLanguageIsLoaded: boolean = currentLanguage ? languageIsloaded(currentLanguage) : false

  const storeAddedCallback = useCallback(
    (lng, _ns) => {
      setStoreAddedEmitted({ ...storeAddedEmitted, [lng]: true })
    },
    [storeAddedEmitted, setStoreAddedEmitted]
  )

  useEffect(() => {
    i18n.store.on('added', storeAddedCallback)
  })

  useEffect(() => {
    i18n.on('languageChanged', (lng) => {
      setCurrentLanguage(lng)
    })
  })

  return currentLanguage && (storeAddedEmitted[currentLanguage] || currentLanguageIsLoaded) ? (
    <>{props.children}</>
  ) : (
    <LoadingPage />
  )
}
