import React from 'react'
import styled from 'styled-components'
import { ExternalLink } from '../src/components/common/ExternalLink'
import { SignInLanguageSelector } from './components/SignIn/SignInLanguageSelector'
import { config } from './config'
import { LogoIcon } from './icons/LogoIcon'

const WavelyLogoMobile = styled(LogoIcon)`
  width: 100px;
  margin-top: 10px;
`
export const SignInToolbar = () => (
  <div
    css="
  margin-top: 43px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  "
  >
    <div css="width: 38px;" />
    <WavelyLogoMobile />
    {config.enableLocalization ? (
      <SignInLanguageSelector css="background-color: #f9faff;" />
    ) : (
      <div css="width: 38px;" />
    )}
  </div>
)

// export const FixedHeader = styled.div`
//             height: 100px;
//             width: 100%;
//             position: fixed;
//             top:0%;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             padding: 24px 30px 24px 30px;
//             background-color: #f9faff;
// `

export const FixedFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  bottom: 0%;
  justify-content: center;
  align-items: center;
  padding: 24px 30px 24px 30px;
  background-color: #f9faff;
`

export const MobileContentWrapper = styled.div`
  height: 70vh;
  min-height: 700px;
  margin: 24px 30px 24px 30px;
  background-color: #f9faff;
  display: flex;
  flex-direction: column;
`
// TODO: Remove this due to redunancy
export const MobileContentBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`

export const MobileTitle = styled.label`
  font-size: 20px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #19224c;
`

export const MobileSubtitle = styled.label`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: center;
  color: #7d88af;
`

export const MobileNote = styled.div`
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  letter-spacing: normal;
  text-align: center;
  color: #7d88af;
  margin-bottom: 24px;
`

export const MobileBottomTermsLink = styled(ExternalLink)`
  color: #00b7ae;
  text-decoration: underline;
`
