import React, { useContext } from 'react'

export interface JwtContext {
  jwtToken: string | null
  setJwtToken: React.Dispatch<React.SetStateAction<string | null>>
  jwtRefreshToken: string | null
  setJwtRefreshToken: React.Dispatch<React.SetStateAction<string | null>>
}

export const JwtContext = React.createContext<null | JwtContext>(null)

export const useJwtContext = () => {
  const context = useContext(JwtContext)
  if (!context) {
    throw new Error('JWT Context is not available, Component needs to be child of JWT Context provider')
  }
  return context
}
