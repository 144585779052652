import * as queryString from 'query-string'

const authenticatedUserBaseUrl = '/reg'

const urlsWithParams = {
  selectedPackage: `${authenticatedUserBaseUrl}/package/:globalId`,
  createAccountWithSome: '/create-account',
  createAccountWithEmail: '/create-account-email',
  enterEmailLoginPinCode: '/enter-email-pin-code',
  registerSimCard: `${authenticatedUserBaseUrl}/register-sim-card`,
  availablePackages: `${authenticatedUserBaseUrl}/available-packages`,
  userPackages: `${authenticatedUserBaseUrl}/user-packages`,
  paymentMethods: `${authenticatedUserBaseUrl}/paymentmethods`,
  showMobileEmailLogin: '',
}

export const urls = {
  signIn: '/',
  createAccountWithSome: {
    url: urlsWithParams.createAccountWithSome,
    clientUrl: (t?: string) => {
      const queryParams = queryString.stringify({ t })
      return queryParams
        ? `${urlsWithParams.createAccountWithSome}?${queryParams}`
        : urlsWithParams.createAccountWithSome
    },
  },
  showMobileEmailLogin: '/sign-in-email-mobile',
  createAccountWithEmail: {
    url: urlsWithParams.createAccountWithEmail,
    clientUrl: (email: string) => `${urlsWithParams.createAccountWithEmail}?${queryString.stringify({ email })}`,
  },
  enterEmailLoginPinCode: {
    url: urlsWithParams.enterEmailLoginPinCode,
    clientUrl: (email: string, code: string) =>
      `${urlsWithParams.enterEmailLoginPinCode}?${queryString.stringify({ email })}&${queryString.stringify({ code })}`,
  },
  registeredUserRedirect: '/registered-user-redirect',
  authenticatedUserBaseUrl,
  registerSimCard: {
    url: urlsWithParams.registerSimCard,
    clientUrl: (from?: string) => {
      const queryParams = queryString.stringify({ from })
      return queryParams ? `${urlsWithParams.registerSimCard}?${queryParams}` : urlsWithParams.registerSimCard
    },
  },
  initialRegisterSimCard: `/register-sim-card`,
  purchaseHistory: `${authenticatedUserBaseUrl}/purchases`,
  paymentMethods: {
    url: urlsWithParams.paymentMethods,
    clientUrl: (from: string, label: string) => {
      const queryParams = queryString.stringify({ from, label })
      return queryParams ? `${urlsWithParams.paymentMethods}?${queryParams}` : urlsWithParams.paymentMethods
    },
  },
  billingInformation: `${authenticatedUserBaseUrl}/billinginformation`,
  account: `${authenticatedUserBaseUrl}/account`,
  userPackages: {
    url: urlsWithParams.userPackages,
    clientUrl: (packageId?: string) => {
      const queryParams = queryString.stringify({ packageId })
      return queryParams ? `${urlsWithParams.userPackages}?${queryParams}` : urlsWithParams.userPackages
    },
  },
  home: `${authenticatedUserBaseUrl}/home`,
  simList: `${authenticatedUserBaseUrl}/sim-list`,
  esimInstructions: `/esim-instructions`,
  support: {
    root: '/support',
    index: {
      url: '/',
      clientUrl: () => '/support',
    },
    coverage: {
      url: '/support/coverage',
      clientUrl: () => '/support/coverage',
    },
    getConnected: {
      url: '/support/get-connected',
      clientUrl: () => '/support/get-connected',
    },
    deviceSettings: {
      url: '/support/device-settings',
      clientUrl: () => '/support/device-settings',
    },
    feedback: {
      url: '/support/feedback',
      clientUrl: () => '/support/feedback',
    },
    liveChat: {
      url: '/support/live-chat',
      clientUrl: () => '/support/live-chat',
    },
    supportTicket: {
      url: `/support/support-ticket`,
      clientUrl: () => `/support/support-ticket`,
    },
  },
  availablePackages: {
    url: urlsWithParams.availablePackages,
    clientUrl: (packageId?: string, esimPurchase?: boolean) => {
      const queryParams = queryString.stringify({ packageId, esimPurchase })
      return queryParams ? `${urlsWithParams.availablePackages}?${queryParams}` : urlsWithParams.availablePackages
    },
  },
}
