import React from 'react'
import { IconWithColor } from '../types'
import ReamEmailIcon from '../images/read-email-circle.png'

export const MailIcon = (props: IconWithColor) => (
  <svg className={props.className} width={`24px`} height={`24px`} viewBox="0 0 24 24" version="1.1">
    <g id="icons/_glyphs/mail" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M20,4 L4,4 C2.9,4 2.01,4.9 2.01,6 L2,18 C2,19.1 2.9,20 4,20 L20,20 C21.1,20 22,19.1 22,18 L22,6 C22,4.9 21.1,4 20,4 Z M20,8 L12,13 L4,8 L4,6 L12,11 L20,6 L20,8 Z"
        fill={props.color}
      />
    </g>
  </svg>
)

export const MailIconColor = (props: IconWithColor) => (
  <img
    className={props.className}
    style={{
      width: '24px',
      height: '24px',
      objectFit: 'contain',
    }}
    src={ReamEmailIcon}
  />
)
