import React from 'react'
import wavelyTutorialBackgroundImage from '../../images/wavely-tutorial-background.jpg'

export const WavelyTutorialSection = () => (
  <div
    style={{
      height: '100vh',
      backgroundImage: `url(${wavelyTutorialBackgroundImage})`,
      backgroundSize: 'cover',
    }}
  >
    <div
      style={{
        height: '100vh',
      }}
    />
  </div>
)
