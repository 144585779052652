import { fold, left, right } from 'fp-ts/es6/Either'
import { pipe } from 'fp-ts/es6/pipeable'
import { config } from '../config'
import {
  PostCreateFreshdeskTicketRequest,
  PostCreateFreshdeskTicketResponse,
  PostCreateFreshdeskTicketSuccessResponse,
} from './api-types'
import { externalApi, formatAuthorizationHeader, onApiJsonValidationError } from './api-utils'

export const postCreateFreshdeskTicket =
  (controller: AbortController) =>
  async (body: PostCreateFreshdeskTicketRequest): Promise<PostCreateFreshdeskTicketResponse> => {
    const url = `/api/v2/tickets`
    const key = config.freshdeskEmailTicketApiKey

    return externalApi({ apiType: 'freshdesk', doNotRetryStatusCodes: [] })
      .signal(controller)
      .auth(formatAuthorizationHeader(btoa(`${key}:X`)))
      .url(url)
      .post(body)
      .error(400, (_error) => left({ type: 'error', message: 'Client or Validation Error' }))
      .error(401, (_error) => left({ type: 'error', message: 'Freshdesk authentication failed' }))
      .json((json) =>
        pipe(
          PostCreateFreshdeskTicketSuccessResponse.decode(json),
          fold(onApiJsonValidationError, (data) => right(data))
        )
      )
  }
