import { createGlobalStyle } from 'styled-components'
import { colors } from '../../theme'

// HMD Connect Fonts

import GroteskLightWoff from '../../fonts/space-grotesk-116/SpaceGrotesk-Light.woff'
import GroteskLightWoff2 from '../../fonts/space-grotesk-116/SpaceGrotesk-Light.woff2'

import GroteskRegularWoff from '../../fonts/space-grotesk-116/SpaceGrotesk-Regular.woff'
import GroteskRegularWoff2 from '../../fonts/space-grotesk-116/SpaceGrotesk-Regular.woff2'

import GroteskMediumWoff from '../../fonts/space-grotesk-116/SpaceGrotesk-Medium.woff'
import GroteskMediumWoff2 from '../../fonts/space-grotesk-116/SpaceGrotesk-Medium.woff2'

import GroteskSemiBoldWoff from '../../fonts/space-grotesk-116/SpaceGrotesk-SemiBold.woff'
import GroteskSemiBoldWoff2 from '../../fonts/space-grotesk-116/SpaceGrotesk-SemiBold.woff2'

import GroteskBoldWoff from '../../fonts/space-grotesk-116/SpaceGrotesk-Bold.woff'
import GroteskBoldWoff2 from '../../fonts/space-grotesk-116/SpaceGrotesk-Bold.woff2'

// Wavely Fonts

import NunitoSansLatinRegularWoff from '../../fonts/wavely/nunito-sans-v6-latin-ext_latin-regular.woff'
import NunitoSansLatinRegularWoff2 from '../../fonts/wavely/nunito-sans-v6-latin-ext_latin-regular.woff2'

import NunitoSansLatin300Woff from '../../fonts/wavely/nunito-sans-v6-latin-ext_latin-300.woff'
import NunitoSansLatin300Woff2 from '../../fonts/wavely/nunito-sans-v6-latin-ext_latin-300.woff2'

import NunitoSansLatin600Woff from '../../fonts/wavely/nunito-sans-v6-latin-ext_latin-600.woff'
import NunitoSansLatin600Woff2 from '../../fonts/wavely/nunito-sans-v6-latin-ext_latin-600.woff2'

import NunitoSansLatin700Woff from '../../fonts/wavely/nunito-sans-v6-latin-ext_latin-700.woff'
import NunitoSansLatin700Woff2 from '../../fonts/wavely/nunito-sans-v6-latin-ext_latin-700.woff2'

export const WavelyGlobalStyle = createGlobalStyle`
  /* nunito-sans-regular - latin-ext_latin */
  @font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Nunito Sans'),
       url(${NunitoSansLatinRegularWoff}) format('woff'),
       url(${NunitoSansLatinRegularWoff2}) format('woff2'),
  }

  /* nunito-sans-300 - latin-ext_latin */
  @font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Nunito Sans 300'),
    url(${NunitoSansLatin300Woff}) format('woff'),
    url(${NunitoSansLatin300Woff2}) format('woff2'),
  }
  /* nunito-sans-600 - latin-ext_latin */
  @font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: local('Nunito Sans 600'),
    url(${NunitoSansLatin600Woff}) format('woff'),
    url(${NunitoSansLatin600Woff2}) format('woff2'),
  }
  /* nunito-sans-700 - latin-ext_latin */
  @font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Nunito Sans 700'),
    url(${NunitoSansLatin700Woff}) format('woff'),
    url(${NunitoSansLatin700Woff2}) format('woff2'),
  }

  * {
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
  }

  html,body{
    overscroll-behavior-x: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    margin:0;
    padding:0;
    height:100%;
    user-select: none;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    position: relative;
    background-color: #f9faff;
    color: ${colors.textColor};
  }
  /* a {
    color: #D8D8D8;
    &:hover{
      opacity: 0.7;
    }
  } */
`

export const GlobalStyle = createGlobalStyle`

  /* spacegrotesk-300normal - latin */
  @font-face {
    font-family: 'Space Text';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src:
      local('Space Text Light normal'),
      local('Space Text-Lightnormal'),
      url(${GroteskLightWoff2}) format('woff2'),
      url(${GroteskLightWoff}) format('woff');
  }

  /* spacegrotesk-400normal - latin */
  @font-face {
    font-family: 'Space Text';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src:
      local('Space Text Regular normal'),
      local('Space Text-Regularnormal'),
      url(${GroteskRegularWoff2}) format('woff2'),
      url(${GroteskRegularWoff}) format('woff');
  }

  /* spacegrotesk-500normal - latin */
  @font-face {
    font-family: 'Space Text';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src:
      local('Space Text Medium normal'),
      local('Space Text-Mediumnormal'),
      url(${GroteskMediumWoff2}) format('woff2'),
      url(${GroteskMediumWoff}) format('woff');
  }

  /* spacegrotesk-600normal - latin */
  @font-face {
    font-family: 'Space Text';
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src:
      local('Space Text SemiBold normal'),
      local('Space Text-SemiBoldnormal'),
      url(${GroteskSemiBoldWoff2}) format('woff2'),
      url(${GroteskSemiBoldWoff}) format('woff');
  }

  /* spacegrotesk-700normal - latin */
  @font-face {
    font-family: 'Space Text';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src:
      local('Space Text Bold normal'),
      local('Space Text-Boldnormal'),
      url(${GroteskBoldWoff2}) format('woff2'),
      url(${GroteskBoldWoff}) format('woff');
  }

  * {
    font-family: 'Space Text', 'Open Sans', sans-serif;
  }

  html,body{
    overscroll-behavior-x: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    margin:0;
    padding:0;
    height:100%;
    user-select: none;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    position: relative;
    background-color: #fff;
    color: ${colors.textColor};
  }
  a {
    color: #D8D8D8;
    &:hover{
      opacity: 0.7;
    }
  }
`
