import React from 'react'
import { IconWithColor } from '../types'

export const DataPlansIcon = (props: IconWithColor) => (
  <svg className={props.className} width="25px" height="25px" viewBox="0 0 25 25" version="1.1">
    <g id="Home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="navigation/bottom-navigation/inactive-item" fillRule="evenodd">
        <g id="icons/icon-24px">
          <g id="icons/_glyphs/data-color">
            <polygon
              id="data-plans-bg-color"
              fill="transparent"
              points="5 22 19 22 19 2 5 2"
              css={`
                fill: transparent !important;
              `}
            />
            <path
              d="M15,15.0098 L15,9.9998 L13,9.9998 L13,15.0098 L11,15.0098 L14,18.9998 L17,15.0098 L15,15.0098 Z M10,4.9998 L7,8.9898 L9,8.9898 L9,13.9998 L11,13.9998 L11,8.9898 L13,8.9898 L10,4.9998 Z M6,20.9998 L18,20.9998 L18,2.9998 L6,2.9998 L6,20.9998 Z M18,-0.0002 L6,-0.0002 C4.9,-0.0002 4,0.8998 4,1.9998 L4,21.9998 C4,23.0998 4.9,23.9998 6,23.9998 L18,23.9998 C19.1,23.9998 20,23.0998 20,21.9998 L20,1.9998 C20,0.8998 19.1,-0.0002 18,-0.0002 L18,-0.0002 Z"
              fill={props.color}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const WavelyDataPlansIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15" fill="#DCF8F9" />
    <path
      d="M19.4319 6.13672H10.5682C9.81511 6.13672 9.20459 6.74724 9.20459 7.50036V12.9549C9.20459 13.708 9.81511 14.3185 10.5682 14.3185H19.4319C20.185 14.3185 20.7955 13.708 20.7955 12.9549V7.50036C20.7955 6.74724 20.185 6.13672 19.4319 6.13672Z"
      fill="#72ECE0"
    />
    <path
      d="M22.7912 19.9727C22.8376 20.1044 22.8518 20.2453 22.8325 20.3836C22.8132 20.522 22.7611 20.6537 22.6804 20.7677C22.5997 20.8817 22.4929 20.9747 22.3689 21.0388C22.2448 21.103 22.1072 21.1365 21.9675 21.1365H8.03253C7.89286 21.1365 7.75524 21.103 7.6312 21.0388C7.50716 20.9747 7.40032 20.8817 7.31964 20.7677C7.23897 20.6537 7.18681 20.522 7.16753 20.3836C7.14826 20.2453 7.16245 20.1044 7.20889 19.9727L8.75048 15.6043C8.88322 15.2282 9.12933 14.9026 9.45489 14.6723C9.78045 14.442 10.1694 14.3183 10.5682 14.3184H19.4318C19.8306 14.3183 20.2195 14.4419 20.5449 14.6722C20.8704 14.9026 21.1164 15.2282 21.2489 15.6043L22.7912 19.9727Z"
      fill="#54E1D3"
    />
    <path d="M10.5649 16.3643H11.2468" stroke="#DCF8F9" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.6104 16.3643H13.2922" stroke="#DCF8F9" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.7012 16.3643H17.383" stroke="#DCF8F9" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.7466 16.3643H19.4284" stroke="#DCF8F9" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.6558 16.3643H15.3376" stroke="#DCF8F9" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.8833 17.7275H10.5651" stroke="#DCF8F9" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.9287 17.7275H18.0651" stroke="#DCF8F9" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19.4287 17.7275H20.1105" stroke="#DCF8F9" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
