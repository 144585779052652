export const stripeErrors = {
  card_declined: 'The card was declined.',
  expired_card: 'The card has expired.',
  incorrect_cvc: `The card's security code is incorrect.`,
  incorrect_number: 'The card number is incorrect.',
  incorrect_zip: `The card's zip code failed validation.`,
  invalid_cvc: `The card's security code is invalid.`,
  invalid_expiry_month: `The card's expiration month is invalid.`,
  invalid_expiry_year: `The card's expiration year is invalid.`,
  invalid_number: 'The card number is not a valid credit card number.',
  missing: 'There is no card on a customer that is being charged.',
  processing_error: 'An error occurred while processing the card.',
  rate_limit: `An error occurred due to requests hitting the API too quickly. Please let us know if you're consistently running into this error.`,
}
