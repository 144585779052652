import React from 'react'
import { IconWithColor } from '../types'
import simIcon from '../../assets/simIcon.png'

export const SimIcon = (props: IconWithColor) => (
  <svg className={props.className} width={`24px`} height={`24px`} viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M19.99,4 C19.99,2.9 19.1,2 18,2 L10,2 L4,8 L4,20 C4,21.1 4.9,22 6,22 L18.01,22 C19.11,22 20,21.1 20,20 L19.99,4 L19.99,4 Z M9,19 L7,19 L7,17 L9,17 L9,19 Z M17,19 L15,19 L15,17 L17,17 L17,19 Z M9,15 L7,15 L7,11 L9,11 L9,15 Z M13,19 L11,19 L11,15 L13,15 L13,19 Z M13,13 L11,13 L11,11 L13,11 L13,13 Z M17,15 L15,15 L15,11 L17,11 L17,15 Z"
        fill={props.color}
        transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) "
      />
    </g>
  </svg>
)

// TODO: Fix SVG rendering issue
// export const WavelySimIcon = () => (
//   <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <circle cx="15" cy="15" r="15" fill="#F9ECDD" />
//     <path d="M21.1363 21.8184C21.1363 21.9992 21.0645 22.1726 20.9366 22.3005C20.8088 22.4283 20.6353 22.5002 20.4545 22.5002H10.2272C10.0464 22.5002 9.87298 22.4283 9.74511 22.3005C9.61724 22.1726 9.54541 21.9992 9.54541 21.8184V7.50018C9.54541 7.31935 9.61724 7.14593 9.74511 7.01806C9.87298 6.89019 10.0464 6.81836 10.2272 6.81836H17.104C17.2849 6.8184 17.4583 6.89026 17.5861 7.01813L20.9365 10.3686C21.0644 10.4964 21.1363 10.6698 21.1363 10.8506V21.8184Z" fill="url(#paint0_linear)" />
//     <path d="M18.409 13.6367H12.2726C11.8961 13.6367 11.5908 13.942 11.5908 14.3185V19.7731C11.5908 20.1496 11.8961 20.4549 12.2726 20.4549H18.409C18.7856 20.4549 19.0908 20.1496 19.0908 19.7731V14.3185C19.0908 13.942 18.7856 13.6367 18.409 13.6367Z" fill="#F9ECDD" />
//     <path d="M11.5908 17.0459H19.0908" stroke="#FFB351" strokeLinecap="round" strokeLinejoin="round" />
//     <path d="M11.5908 15H13.6363L17.0454 19.0909H19.0908" stroke="#FFB351" strokeLinecap="round" strokeLinejoin="round" />
//     <path d="M19.0908 15H17.0454L13.6363 19.0909H11.5908" stroke="#FFB454" strokeLinecap="round" strokeLinejoin="round" />
//     <defs>
//       <linearGradient id="paint0_linear" x1="15.3409" y1="6.81836" x2="15.3409" y2="22.5002" gradientUnits="userSpaceOnUse">
//         <stop stopColor="#FEC06F" />
//         <stop offset="1" stopColor="#FFAD43" />
//       </linearGradient>
//     </defs>
//   </svg>
// )

interface Props {
  margin: boolean
}

export const WavelySimIcon = ({ margin }: Props) => (
  <img src={simIcon} width="30px" height="30px" style={margin ? { marginRight: '5px' } : { marginRight: '0px' }} />
)
