import { fold } from 'fp-ts/es6/Either'
import { pipe } from 'fp-ts/lib/function'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { postValidateMagicLink, putResendMagicLink } from '../../api/api-auth'
import { redirectToSignInOnAuthenticationError, renewJwtTokenOnAuthenticationExpiredError } from '../../api/api-utils'
import { config } from '../../config'
import { useErrorContext } from '../../context/ErrorContext'
import { useJwtContext } from '../../context/JwtContext'
import { useDataLoader } from '../../customHooks/useDataLoader'
import { exhaustiveCheck } from '../../exhaustive-switch-check'
import { formatPageTitle } from '../../formatPageTitle'
import {
  FixedFooter,
  MobileContentBody,
  MobileContentWrapper,
  MobileSubtitle,
  MobileTitle,
  SignInToolbar
} from '../../MobileStyledComponents'
import { ACCESS_CODE_FORM_ID, ACCESS_CODE_FORM_INPUT_CLASS } from '../../test-selectors'
import { urls } from '../../urls'
import { TextButton } from '../Button/Button'

const PinInputWavely = styled.input`
  font-size: 1.5rem;
  font-weight: 600;
  width: 3.375rem;
  height: 3.375rem;
  margin: 0.625rem;
  text-align: center;
  background-color: #ffffff;
  border-radius: 10px;
  border: solid 1px #ebeef8;
  :focus {
    outline: none;
    border: solid 1px #00b7ae;
    background-color: #ffffff;
  }
`

interface Props {
  onPinToken: (token: any) => void
  magicLinkResendToken: string
  email: string
}

export const PinActivationMobile = ({ onPinToken, magicLinkResendToken, email }: Props) => {
  const pinItems = [1, 2, 3, 4]
  const [pin, setPin] = useState(new Array(pinItems.length).fill(''))
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState('')
  const history = useHistory()

  const { setJwtToken } = useJwtContext()

  useEffect(() => {
    document.title = formatPageTitle(t('pinActivation.pageTitle'))
  }, [t])

  const updatePinField = (value: any, index: number): string[] => {
    const pinDigits = [...pin]
    pinDigits[index] = value
    setPin(pinDigits)
    return pinDigits
  }

  const handlePinInputChange = (event: any, index: number) => {
    const form = event.target.form
    const pinDigits = updatePinField(event.target.value, index)
    if (event.target.value !== '' && form.elements[index + 1]) {
      form.elements[index + 1].focus()
      event.preventDefault()
    }
    submitPinIfFilled(pinDigits)
  }

  const submitPinIfFilled = (pinDigits: string[]) => {
    const pinCodeHasBeenFilled = pinDigits.every((n) => n !== '' && /^\d$/.test(n))
    if (pinCodeHasBeenFilled) {
      const pinString: string = pinDigits.join('')
      submitPin(pinString, magicLinkResendToken)
    }
  }

  const handleBackspace = (event: any, index: number) => {
    const form = event.target.form
    updatePinField(event.target.value, index)
    if (event.keyCode === 8 && event.target.value === '' && form.elements[index - 1]) {
      form.elements[index - 1].focus()
    }
  }

  const api = useDataLoader(putResendMagicLink)
  const validatePinApi = useDataLoader(postValidateMagicLink)
  const { onError } = useErrorContext()

  const submitPin = (magicLinkValidationCode: string, magicLinkResendToken: string) => {
    const requestData = {
      magicLinkResendToken,
      magicLinkValidationCode,
      apiKey: config.emailLoginApiKey,
    }

    validatePinApi
      .query(requestData)
      .then((response) =>
        pipe(
          response,
          fold(
            (error) => {
              switch (error.type) {
                case 'WRONG_PIN_CODE':
                  return setErrorMessage(t('pinActivation.invalidCode'))
                case 'FETCH_ERROR':
                case 'API_ERROR':
                case 'UNSUPPORTED_RESPONSE':
                  return onError(error, error.type)
                case 'ABORT_ERROR':
                  return
                case 'THROTTLE_ERROR':
                  return setErrorMessage(t('pinActivation.throttleError'))
                case 'AUTHENTICATION_EXPIRED_ERROR':
                  return onError(new Error('email magiclink login authentication renewal error'))
                case 'AUTHENTICATION_ERROR':
                  return onError(new Error('email magiclink login authentication error'))
                default:
                  return exhaustiveCheck(error)
              }
            },
            (data) => {
              setJwtToken(data.accessToken)
              history.push(urls.home)
            }
          )
        )
      )
      .catch(onError)
  }

  return (
    <>
      <SignInToolbar />
      <MobileContentWrapper>
        <MobileContentBody>
          <MobileTitle css="margin-bottom: 17px;">{t('pinActivation.signInPageTitle')}</MobileTitle>
          <MobileSubtitle css="margin-bottom: 30px;">
            {' '}
            {t('pinActivation.helpText', { emailAddress: email })}
          </MobileSubtitle>
          <div id={ACCESS_CODE_FORM_ID}>
            <form
              css={`
                margin-top: 28px;
                display: flex;
                justify-content: start;
              `}
            >
              {pinItems.map((i) => (
                <PinInputWavely
                  css={
                    i === 1
                      ? `
                  margin-top: 0.625rem;
                  margin-bottom: 0.625rem;
                  margin-right: 0.625rem;
                  margin-left: 0px;
                `
                      : ``
                  }
                  key={i}
                  type={'tel'}
                  autoFocus={i === 1}
                  className={ACCESS_CODE_FORM_INPUT_CLASS}
                  maxLength={1}
                  onChange={(event) => handlePinInputChange(event, i - 1)}
                  onKeyDown={(event) => handleBackspace(event, i - 1)}
                />
              ))}
            </form>
          </div>
          {errorMessage && (
            <div>
              <p
                css={`
                  color: rgb(239, 78, 82);
                  font-size: 0.75rem;
                  line-height: 0.75rem;
                  margin-top: 1rem;
                `}
              >
                {errorMessage}
              </p>
            </div>
          )}
        </MobileContentBody>

      </MobileContentWrapper>
      <FixedFooter>
        <TextButton
          css={`
                font-size: 11px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.82;
                letter-spacing: normal;
                text-align: center;
                color: #00b7ae;
            `}
          text={t('pinActivation.resendCode')}
          onClick={async () => {
            api
              .query({ apiKey: config.emailLoginApiKey, token: magicLinkResendToken, email })
              .then((response) =>
                pipe(
                  response,
                  fold(
                    (error) => {
                      switch (error.type) {
                        case 'THROTTLE_ERROR':
                          return setErrorMessage(t('pinActivation.throttleError'))
                        case 'ABORT_ERROR':
                          return
                        case 'WRONG_PIN_CODE':
                          return
                        case 'FETCH_ERROR':
                        case 'API_ERROR':
                        case 'UNSUPPORTED_RESPONSE':
                          return onError(error, error.type)

                        case 'AUTHENTICATION_EXPIRED_ERROR':
                          return renewJwtTokenOnAuthenticationExpiredError()
                        case 'AUTHENTICATION_ERROR':
                          return redirectToSignInOnAuthenticationError()
                        default:
                          return exhaustiveCheck(error)
                      }
                    },
                    (data) => {
                      onPinToken(data.code)
                      alert('Sent')
                    }
                  )
                )
              )
              .catch(onError)
          }}
        />
      </FixedFooter>
    </>
  )
}
