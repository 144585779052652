import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { LANGUAGES } from '../../i18n'

// const BorderlessSelect = styled.select`
//   border: none;
//   margin-top: 10px;
//   font-size: 16px;
//   font-weight: bold;
//   text-transform: uppercase;
//  `

const BorderlessSelectWavely = styled.select`
  border: none;
  margin-top: 10px;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  color: #7d88af;
  background-color: transparent;
`

export const SignInLanguageSelector = () => {
  const { i18n } = useTranslation()

  const onLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault()
    void i18n.changeLanguage(event.target.value)
  }

  const sortedLanguages = [...LANGUAGES].sort((a, b) => a.name.localeCompare(b.name))

  return (
    <BorderlessSelectWavely
      css={`
        float: right;
      `}
      onChange={onLanguageChange}
      defaultValue={i18n.language}
    >
      {sortedLanguages.map(({ name, code }) => (
        <option value={code} key={code} title={name}>
          {code}
        </option>
      ))}
    </BorderlessSelectWavely>
  )
}
