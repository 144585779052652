import { fold, left, right } from 'fp-ts/es6/Either'
import * as TE from 'fp-ts/es6/TaskEither'
import { chain } from 'fp-ts/es6/TaskEither'
import { pipe } from 'fp-ts/lib/function'
import PThrottle from 'p-throttle'
import { postAppQuery } from './api'
import {
  AppQuerySuccessResponse,
  CommonApiError,
  EsimPurchaseResponse,
  EsimPurchaseStatusType,
  PostPurchaseEsimRequest,
  PostPurchaseEsimResponse,
  PostPurchaseEsimResponseError,
  PostPurchaseEsimSuccessResponse,
  PostRegisterSimCardWithPinCodeRequest,
  PostRequestSimPinCodeRequest,
  PostRequestSimPinCodeResponse,
  PostSimCardDeactivateFailureResponse,
  PostSimCardDeactivateRequest,
  PostSimCardDeactivateResponse,
  PostSimCardDeactivateSuccessResponse,
  PostSimCardRequest,
  PostSimCardResponse,
  PurchaseEsimIsFinished,
  PurchaseEsimIsFinishedRequest,
  PurchaseEsimIsFinishedResponse,
  PurchaseEsimIsFinishedSuccess,
  PutSimCardRequest,
  PutSimCardResponse,
  SimCardResponseSuccess,
} from './api-types'
import { externalApi, formatAuthorizationHeader, onApiJsonValidationError } from './api-utils'

export const postSimCard = async (body: PostSimCardRequest, jwtToken: string): Promise<PostSimCardResponse> => {
  const url = `/AppRegisteredUser/simcard`

  return externalApi({ apiType: 'hydra', doNotRetryStatusCodes: [409, 413, 414, 421] })
    .url(url)
    .auth(formatAuthorizationHeader(jwtToken))
    .post(body)
    .error(409, async (error) => {
      const jsonString = await error.response.json()

      const errorDetails: { success: boolean; errorMessage: string } = JSON.parse(jsonString as string)
      return { type: 'error', message: errorDetails.errorMessage }
    })
    .error(413, async (error) => left({ type: 'error-pin/icc', message: error.message }))
    .error(414, async (error) => left({ type: 'error-414', message: error.message }))
    .error(421, async (error) => left({ type: 'error-name-in-use', message: error.message }))
    .json((json) =>
      pipe(
        SimCardResponseSuccess.decode(json),
        fold(onApiJsonValidationError, (data) => right(data))
      )
    )
}

export const putSimCard =
  (controller: AbortController) =>
  async ({ jwtToken, body }: PutSimCardRequest): Promise<PutSimCardResponse> => {
    const url = '/AppRegisteredUser/simcard'

    return externalApi({ apiType: 'hydra', doNotRetryStatusCodes: [421] })
      .signal(controller)
      .url(url)
      .auth(formatAuthorizationHeader(jwtToken))
      .put(body)
      .error(421, async (error) => left({ type: 'error-name-in-use', message: error.message }))
      .json((json) =>
        pipe(
          SimCardResponseSuccess.decode(json),
          fold(onApiJsonValidationError, (data) => right(data))
        )
      )
  }

export const postSimCardDeactivate =
  (controller: AbortController) =>
  async ({ body, jwtToken }: PostSimCardDeactivateRequest): Promise<PostSimCardDeactivateResponse> => {
    const url = '/AppRegisteredUser/simcardDeactivate'

    return externalApi({ apiType: 'hydra', doNotRetryStatusCodes: [400] })
      .signal(controller)
      .url(url)
      .auth(formatAuthorizationHeader(jwtToken))
      .post(body)
      .error(400, async (_error) => left({ type: 'SIM_CARD_IMSI_STATE_IMMUTABLE' }))
      .text((_text) => right({ type: 'success' }))
  }

export const deactivateSim = (controller: AbortController) => (requestData: PostSimCardDeactivateRequest) => {
  const first = () => postSimCardDeactivate(controller)(requestData)
  const second = () => () => postAppQuery(controller)({ jwtToken: requestData.jwtToken })

  return pipe(
    first,
    chain<
      CommonApiError | PostSimCardDeactivateFailureResponse,
      PostSimCardDeactivateSuccessResponse,
      AppQuerySuccessResponse
    >(second)
  )()
}

export const purchaseEsim =
  (controller: AbortController) =>
  ({ jwtToken }: PostPurchaseEsimRequest): Promise<PostPurchaseEsimResponse> => {
    const url = '/AppRegisteredUser/purchaseEsim'

    return externalApi({ apiType: 'hydra', doNotRetryStatusCodes: [400] })
      .signal(controller)
      .url(url)
      .auth(formatAuthorizationHeader(jwtToken))
      .post({})
      .error(421, async (error) => left({ type: 'error-name-in-use', message: error.message }))
      .error(422, async (error) => left({ type: 'error-no-payment-method', message: error.message }))
      .error(423, async (error) => left({ type: 'error-price-missing', message: error.message }))
      .error(424, async (error) => left({ type: 'error-esim-purchase-ongoing', message: error.message }))
      .text((_text) => right({ type: 'success' }))
  }

// TODO add io-ts response validation
export const postPurchaseEsimIsFinished = (
  { body, jwtToken }: PurchaseEsimIsFinishedRequest,
  controller: AbortController
): Promise<PurchaseEsimIsFinishedResponse> => {
  const url = '/AppRegisteredUser/purchaseSimIsFinished'
  return externalApi({ apiType: 'hydra', doNotRetryStatusCodes: [] })
    .signal(controller)
    .url(url)
    .auth(formatAuthorizationHeader(jwtToken))
    .post(body)
    .json((json) => {
      const response = json as PurchaseEsimIsFinished
      if (!response.finished) {
        return right({ type: 'not-ready' })
      }
      const successResponse = json as PurchaseEsimIsFinishedSuccess
      return right({ type: 'success', icc: successResponse.icc })
    })
}

const throttledPostPurchaseEsimIsFinished = PThrottle(postPurchaseEsimIsFinished, 1, 1500)
export const pollEsimPurchaseStatus =
  (controller: AbortController) =>
  (requestData: PurchaseEsimIsFinishedRequest): Promise<EsimPurchaseResponse> =>
    throttledPostPurchaseEsimIsFinished(requestData, controller).then((response) =>
      pipe(
        response,
        fold(
          (error): EsimPurchaseResponse => left(error),
          (data): EsimPurchaseResponse | Promise<EsimPurchaseResponse> => {
            switch (data.type) {
              case 'not-ready':
                return pollEsimPurchaseStatus(controller)(requestData)
              case 'success':
              case 'purchase-error':
                return right(data)
            }
          }
        )
      )
    )

const purchaseEsimProfileAndPollCompletion =
  (controller: AbortController) => (requestData: PostPurchaseEsimRequest) => {
    const first = () => purchaseEsim(controller)(requestData)
    const second = () => () =>
      pollEsimPurchaseStatus(controller)({
        body: { endUserGuid: requestData.endUserGuid },
        jwtToken: requestData.jwtToken,
      })

    return pipe(
      first,
      chain<CommonApiError | PostPurchaseEsimResponseError, PostPurchaseEsimSuccessResponse, EsimPurchaseStatusType>(
        second
      )
    )()
  }

export const purchaseEsimProfileWithAppQuery =
  (controller: AbortController) => (requestData: PostPurchaseEsimRequest) =>
    pipe(
      () => purchaseEsimProfileAndPollCompletion(controller)(requestData),
      chain<
        CommonApiError | PostPurchaseEsimResponseError,
        EsimPurchaseStatusType,
        {
          appQueryData: AppQuerySuccessResponse
          esimPurchaseStatusType: EsimPurchaseStatusType
        }
      >(
        (esimPurchaseStatusType) => () =>
          pipe(
            () => postAppQuery(controller)({ jwtToken: requestData.jwtToken }),
            TE.map((appQueryData) => ({
              appQueryData,
              esimPurchaseStatusType,
            }))
          )()
      )
    )()

export const postRequestSimPinCode =
  (controller: AbortController) =>
  ({ body, jwtToken }: PostRequestSimPinCodeRequest): Promise<PostRequestSimPinCodeResponse> => {
    const url = `/AppRegisteredUser/simRegistration1`

    return externalApi({ apiType: 'hydra', doNotRetryStatusCodes: [409, 413, 415, 416, 414, 421] })
      .url(url)
      .auth(formatAuthorizationHeader(jwtToken))
      .signal(controller)
      .post(body)
      .error(409, async (error) => {
        const jsonString = await error.response.json()

        const errorDetails: { success: boolean; errorMessage: string } = JSON.parse(jsonString as string)
        return { type: 'error', message: errorDetails.errorMessage }
      })
      .error(416, async (error) => left({ type: 'error-pin/icc', message: error.message }))
      .error(415, async (error) => left({ type: 'error-pin/icc', message: error.message }))
      .error(413, async (error) => left({ type: 'error-pin/icc', message: error.message }))
      .error(414, async (error) => left({ type: 'error-414', message: error.message }))
      .error(421, async (error) => left({ type: 'error-name-in-use', message: error.message }))
      .text()
  }

const postRegisterSimCardWithSimPinCode =
  (controller: AbortController) =>
  ({ body, jwtToken }: PostRegisterSimCardWithPinCodeRequest): Promise<PostRequestSimPinCodeResponse> => {
    const url = `/AppRegisteredUser/simRegistration2`

    return externalApi({ apiType: 'hydra', doNotRetryStatusCodes: [409, 413, 414, 421] })
      .url(url)
      .auth(formatAuthorizationHeader(jwtToken))
      .signal(controller)
      .post(body)
      .error(409, async (error) => {
        const jsonString = await error.response.json()

        const errorDetails: { success: boolean; errorMessage: string } = JSON.parse(jsonString as string)
        return { type: 'error', message: errorDetails.errorMessage }
      })
      .error(413, async (error) => left({ type: 'error-pin/icc', message: error.message }))
      .error(414, async (error) => left({ type: 'error-414', message: error.message }))
      .error(421, async (error) => left({ type: 'error-name-in-use', message: error.message }))
      .text()
  }

export const registerSimCardWithPinCodeAndAppQuery =
  (controller: AbortController) => (requestData: PostRegisterSimCardWithPinCodeRequest) => {
    const first = () => postRegisterSimCardWithSimPinCode(controller)(requestData)
    const second = () => () => postAppQuery(controller)({ jwtToken: requestData.jwtToken })

    return pipe(first, chain<CommonApiError, void, AppQuerySuccessResponse>(second))()
  }
