import React from 'react'
import { IconWithColor } from '../types'

export const FeedbackIcon = (props: IconWithColor) => (
  <svg className={props.className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M20,2 L4,2 C2.9,2 2.01,2.9 2.01,4 L2,22 L6,18 L20,18 C21.1,18 22,17.1 22,16 L22,4 C22,2.9 21.1,2 20,2 Z M13,11 L11,11 L11,5 L13,5 L13,11 Z M13,15 L11,15 L11,13 L13,13 L13,15 Z"
        fill={props.color}
      />
    </g>
  </svg>
)
