import { fold } from 'fp-ts/es6/Either'
import { pipe } from 'fp-ts/lib/function'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { registerAccountWithEmailApi, registerAccountWithSoMeApi } from '../../api/api-auth'
import { Country, GetCountryListSuccessResponse } from '../../api/api-types'
import { redirectToSignInOnAuthenticationError, renewJwtTokenOnAuthenticationExpiredError } from '../../api/api-utils'
import { config } from '../../config'
import { useErrorContext } from '../../context/ErrorContext'
import { useJwtContext } from '../../context/JwtContext'
import { useDataLoader } from '../../customHooks/useDataLoader'
import { exhaustiveCheck } from '../../exhaustive-switch-check'
import { formatPageTitle } from '../../formatPageTitle'
import {
  FixedFooter,
  MobileContentWrapper,
  MobileSubtitle,
  MobileTitle,
  SignInToolbar,
} from '../../MobileStyledComponents'
import { SIGN_UP_BUTTON_ID } from '../../test-selectors'
import { colors, containerWidths } from '../../theme'
import { DataLayerEvent, sendEvent } from '../../utils/analytics-utils'
import { WavelyHeader, WavelyHelpText } from '../../wavely-text-styles'
import { AdaptiveWrapper } from '../AdaptiveWrapper'
import { Button } from '../Button/Button'
import { Checkbox } from '../Checkbox/Checkbox'
import { ExternalLink } from '../common/ExternalLink'
import { InputField } from '../common/InputField'
import { SignInWrapper } from '../SignIn/SignInWrapper'
import { CountrySelect } from './CountrySelect'

const FormItem = styled.div`
  padding: 1.25rem 0;
`

const Label = styled.label`
  font-size: 0.75rem;
  font-weight: 600;
`

const BottomTermsLink = styled(ExternalLink)`
  color: ${colors.hydraGreen};
  text-decoration: underline;
`

interface Props {
  api: ReturnType<typeof registerAccountWithSoMeApi | typeof registerAccountWithEmailApi>
  onAccountCreated: (token?: string) => void
  countryList: GetCountryListSuccessResponse
}

export const CreateAccount = ({ api, onAccountCreated, countryList }: Props) => {
  const { t } = useTranslation()
  const [firstName, setFirstName] = useState<null | string>(null)
  const [lastName, setLastName] = useState<null | string>(null)
  const [country, setCountry] = useState<Country>({
    exchangeRate: 1,
    name: 'United States',
    numericCode: '2843029628806470',
    supported: true,
    threeLetterCode: 'USA',
    twoLetterCode: 'US',
    zoneName: 'Zone 1',
  } as Country)
  const [marketingConsent, setMarketingConsent] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const { setJwtToken } = useJwtContext()
  const { onError } = useErrorContext()
  const dataLoader = useDataLoader(api)
  useEffect(() => {
    document.title = formatPageTitle('Create account')
  }, [])

  const onMarketingConsentChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setMarketingConsent(event.target.checked)

  const sendAnalyticsOnAccountCreated = (countryCode: string) => {
    const event: DataLayerEvent = {
      event: 'GAEvent',
      eventCategory: 'click_create_account',
      eventAction: 'click_create_account',
      eventLabel: countryCode,
    }
    sendEvent(event)
  }

  const register = (countryCode: string) => {
    setIsLoading(true)
    dataLoader
      .query({
        firstName,
        lastName,
        country: countryCode,
        marketingConsent,
      })
      .then((response) =>
        pipe(
          response,
          fold(
            (error) => {
              setIsLoading(false)

              switch (error.type) {
                case 'ABORT_ERROR':
                case 'THROTTLE_ERROR':
                case 'WRONG_PIN_CODE':
                  return
                case 'AUTHENTICATION_EXPIRED_ERROR':
                  return renewJwtTokenOnAuthenticationExpiredError()
                case 'AUTHENTICATION_ERROR':
                  return redirectToSignInOnAuthenticationError()
                case 'FETCH_ERROR':
                case 'API_ERROR':
                case 'UNSUPPORTED_RESPONSE':
                  return onError(error, error.type)
                default:
                  return exhaustiveCheck(error)
              }
            },
            (successData) => {
              sendAnalyticsOnAccountCreated(countryCode)
              if (successData.type === 'some') {
                setJwtToken(successData.jwt.accessToken)
                onAccountCreated()
              }
              setIsLoading(false)
              if (successData.type === 'email') {
                onAccountCreated(successData.code)
              }
            }
          )
        )
      )
      .catch(onError)
  }

  const registerButtonIsDisabled = isLoading || !country || !firstName || !lastName

  return (
    <AdaptiveWrapper
      mobileView={
        <>
          <MobileContentWrapper>
            <SignInToolbar />
            <div
              css="

        display: flex;
        flex-direction: column;
        justify-content: center;

        "
            >
              <FormItem
                css="

          display: flex;
          flex-direction: column;
          justify-content: center;

          "
              >
                <MobileTitle css={'margin-top: 50px;'}>{t('createAccount.finalizeYourAccount')}</MobileTitle>
                <MobileSubtitle
                  css={`
                    margin-bottom: 2rem;
                    margin-top: 10px;
                  `}
                >
                  Before you roam and wander,
                  <br /> please enter your name.
                </MobileSubtitle>
                <div>
                  <Label htmlFor="firstName">{t('editProfilePage.firstName')}</Label>
                  <br />
                  <InputField
                    onChange={(event) => setFirstName(event.target.value)}
                    placeholder={'Enter your first name'}
                  />
                  <br />
                  <Label htmlFor="lastName">{t('editProfilePage.lastName')}</Label>
                  <br />
                  <InputField
                    onChange={(event) => setLastName(event.target.value)}
                    placeholder={'Enter your last name'}
                  />
                </div>
                <label
                  css={`
                    margin-top: 0.5rem;
                    display: flex;
                    flex-direction: column;
                    font-size: 0.75rem;
                    font-weight: 600;
                  `}
                >
                  {t('createAccount.countryOfResidence')}
                  <CountrySelect
                    defaultCountry={country}
                    countryList={countryList}
                    css={`
                      height: 3.375rem;
                      width: 100%;
                      @media screen and (min-width: ${containerWidths.md}px) {
                        width: 100%;
                      }
                    `}
                    onChange={(countryCode) => setCountry(countryCode)}
                  />
                </label>
                <div
                  css={`
                    margin-top: 1.5rem;
                    display: flex;
                    /* justify-content: space-between; */
                    align-items: center;
                  `}
                >
                  <Checkbox checked={marketingConsent} onChange={onMarketingConsentChange} />
                  <WavelyHelpText css=" margin-left: 15px; font-size: 11px;">
                    {' '}
                    {t('editProfilePage.marketingConcent')}
                  </WavelyHelpText>
                </div>
              </FormItem>
            </div>
          </MobileContentWrapper>
          <FixedFooter>
            <Button
              css={'width: 100%; box-shadow: 0 3px 20px 0 rgba(0, 183, 174, 0.4); font-size: 14px; font-weight: 800;'}
              id={SIGN_UP_BUTTON_ID}
              text={t('createAccount.createAccount')}
              disabled={registerButtonIsDisabled}
              onClick={() => {
                if (country?.twoLetterCode) {
                  register(country.twoLetterCode)
                }
              }}
            />

            <div
              css={`
                margin-top: 1rem;
                marign-bottom: 2rem;
                font-size: 11px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.82;
                letter-spacing: normal;
                text-align: center;
                color: #7d88af;
              `}
            >
              <Trans i18nKey="terms">
                {`By continuing, you agree to ` + `Wavely's `}
                <BottomTermsLink href={config.serviceTermsUrl}>
                  <>Service Terms</>
                </BottomTermsLink>
                ,{' '}
                <BottomTermsLink href={config.privacyPolicyUrl}>
                  <>Privacy Policy</>
                </BottomTermsLink>{' '}
                {'Wavely'}{' '}
                <BottomTermsLink href={config.privacySupplementUrl}>
                  <>Privacy Supplement</>
                </BottomTermsLink>
              </Trans>
            </div>
          </FixedFooter>
        </>
      }
      desktopView={
        <SignInWrapper>
          <>
            <FormItem>
              <WavelyHeader>{t('createAccount.finalizeYourAccount')}</WavelyHeader>
              <WavelyHelpText
                css={`
                  margin-top: 9px;
                  margin-bottom: 28px;
                `}
              >
                {t('createAccount.countryHelpText')}
              </WavelyHelpText>
              <div>
                <Label htmlFor="firstName">{t('editProfilePage.firstName')}</Label>
                <br />
                <InputField
                  onChange={(event) => setFirstName(event.target.value)}
                  placeholder={'Enter your first name'}
                />
                <br />
                <Label htmlFor="lastName">{t('editProfilePage.lastName')}</Label>
                <br />
                <InputField
                  onChange={(event) => setLastName(event.target.value)}
                  placeholder={'Enter your last name'}
                />
              </div>
              <label
                css={`
                  margin-top: 0.5rem;
                  display: flex;
                  flex-direction: column;
                  font-size: 0.75rem;
                  font-weight: 600;
                `}
              >
                {t('createAccount.countryOfResidence')}
                <CountrySelect
                  countryList={countryList}
                  defaultCountry={country}
                  css={`
                    height: 3.375rem;
                    width: 100%;
                    @media screen and (min-width: ${containerWidths.md}px) {
                      width: 100%;
                    }
                  `}
                  onChange={(countryCode) => setCountry(countryCode)}
                />
              </label>
              <div
                css={`
                  margin-top: 1.5rem;
                  display: flex;
                  /* justify-content: space-between; */
                  align-items: center;
                `}
              >
                <Checkbox checked={marketingConsent} onChange={onMarketingConsentChange} />
                <WavelyHelpText css=" margin-left: 15px; font-size: 11px;">
                  {' '}
                  {t('editProfilePage.marketingConcent')}
                </WavelyHelpText>
              </div>
            </FormItem>

            <Button
              css={'margin-top: 88px; box-shadow: 0 3px 20px 0 rgba(0, 183, 174, 0.4);'}
              id={SIGN_UP_BUTTON_ID}
              text={t('createAccount.createAccount')}
              disabled={registerButtonIsDisabled}
              onClick={() => {
                if (country?.twoLetterCode) {
                  register(country.twoLetterCode)
                }
              }}
            />

            <div
              css={`
                margin-top: 1rem;
                font-size: 11px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.82;
                letter-spacing: normal;
                text-align: left;
                color: #7d88af;
              `}
            >
              <Trans i18nKey="terms">
                {`By continuing, you agree to ` + `Wavely's `}
                <BottomTermsLink href={config.serviceTermsUrl}>
                  <>Service Terms</>
                </BottomTermsLink>
                ,{' '}
                <BottomTermsLink href={config.privacyPolicyUrl}>
                  <>Privacy Policy</>
                </BottomTermsLink>{' '}
                {'Wavely'}{' '}
                <BottomTermsLink href={config.privacySupplementUrl}>
                  <>Privacy Supplement</>
                </BottomTermsLink>
              </Trans>
            </div>
          </>
        </SignInWrapper>
      }
      adaptiveEnabled={true}
    />
  )
}
