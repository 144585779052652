import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Page } from '../../common/Page'
import { colors } from '../../../theme'
import { PageTitle } from '../../styles/PageTitle'
import { StyledBaseButton } from '../../Button/StyledBaseButton'
import { LeftArrowIcon } from '../../../icons/LeftArrowIcon'

const PageSubtitle = styled.h2`
  font-weight: bold;
  font-size: 1.5rem;
`

const Content = styled.p`
  font-weight: medium;
  font-size: 1.125rem;
  line-height: 1.875rem;
`

const BackButton = ({ text }: { text: string }) => {
  const history = useHistory()
  return (
    <StyledBaseButton
      css={`
        color: ${colors.hydraGreen};
      `}
      onClick={() => history.goBack()}
    >
      <LeftArrowIcon
        color={colors.hydraGreen}
        css={`
          margin-right: 0.625rem;
        `}
      />
      {text}
    </StyledBaseButton>
  )
}

export const DeviceSettingsPage = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { name } = (history.location.state as any) || { name: undefined }

  return (
    <Page footer={true} menu={true} css={``}>
      <div
        css={`
          grid-column-start: 1;
          grid-column-end: 5;
          @media screen and (min-width: 600px) {
            grid-column-start: 1;
            grid-column-end: 9;
          }

          @media screen and (min-width: 1024px) {
            grid-column-start: 1;
            grid-column-end: 13;
          }
        `}
      >
        <>
          <BackButton text={name ?? t('support.getConnected.backButton')} />
          <PageTitle>{t('support.deviceSettings.pageTitle')}</PageTitle>
        </>
        <PageSubtitle>{t('support.deviceSettings.pageSubtitle')}</PageSubtitle>
        <Content>
          As of August 2020 the devices know to support eSIM profiles are as follows:
          <ul>
            <li>iPhone SE</li>
            <li>iPhone 11, 11 Pro, 11 Pro Max</li>
            <li>iPhone XS, XS Max</li>
            <li>iPhone XR</li>
            <li>Samsung Galaxy Note 20 Ultra</li>
            <li>Samsung Galaxy Note Ultra 5G</li>
            <li>Samsung Note 20+</li>
            <li>Samsung Fold LTE model</li>
            <li>Google Pixel 4</li>
            <li>Google Pixel 4a</li>
          </ul>
          For more information, check out this list over at GSMA.
        </Content>
      </div>
    </Page>
  )
}
