import React from 'react'

interface Props {
  size?: number
  margin?: number
  className?: string
  color: string
  'data-testid'?: string
}

export const Loading = (props: Props) => {
  const size = props.size || 38
  const margin = typeof props.margin === 'number' ? props.margin : 10
  return (
    <div
      data-testid={props['data-testid']}
      className={props.className}
      css={`
        margin: ${margin}px;
      `}
    >
      <svg width={size} height={size} viewBox={`0 0 38 38`} stroke={props.color}>
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)" strokeWidth="2">
            <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="1s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
    </div>
  )
}
