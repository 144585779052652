import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import { formatPageTitle } from '../../formatPageTitle'
import { colors, containerWidths } from '../../theme'
import { urls } from '../../urls'
import { BackButton } from '../common/BackButton'
import { Page, PageContent } from '../common/Page'
import { Picture } from '../common/Picture'
import { PageTitle } from '../styles/PageTitle'
import EsimInstructions from '../../images/esim-instructions.png'
import { SectionContainer } from '../common/styles'
import { PlusIcon } from '../../icons/PlusIcon'
import { QrCodeIcon } from '../../icons/QrcodeIcon'
import { EsimIcon } from '../../icons/EsimIcon'
import { CopyIcon } from '../../icons/CopyIcon'
import { TrashcanIcon } from '../../icons/TrashcanIcon'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const PageSubtitle = styled.p`
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.6875rem;
  color: ${colors.darkGreyOpaPointSix};
`

const Card = styled(SectionContainer)`
  width: 100%;
  margin: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  box-shadow: 0 1rem 2rem rgba(0, 30, 45, 0.25);
`

const CardTextRows = styled.div`
  width: 100%;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
`

const CardTextTitle = styled.p`
  margin: 0 0 4px 0;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  letter-spacing: 0.025rem;
`

const CardTextContent = styled.p`
  margin-top: 1rem;
  margin-left: 5rem;
  margin-right: 4rem;
  font-weight: medium;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.025rem;
  text-align: left;

  &.content-enter {
    opacity: 0;
  }
  &.content-enter-active {
    opacity: 1;
    transition: opacity 800ms;
  }
  &.content-exit {
    opacity: 1;
  }
  &.content-exit-active {
    opacity: 0;
    transition: opacity 800ms;
  }
`

const SmallIconContainer = styled.div`
  height: 3.5rem;
  width: 100%;
  max-width: 3.5rem;
  background-color: ${colors.hydraGreenOpaPointOne};
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const CardContent = ({
  icon,
  title,
  content,
  showContent,
}: {
  icon: JSX.Element
  title: string
  content: JSX.Element
  showContent: boolean
}) => (
  <div
    css={`
      display: flex;
      flex-direction: column;
      width: 100%;
    `}
  >
    <div
      css={`
        display: flex;
        flex-direction: row;
        align-items: center;
      `}
    >
      <SmallIconContainer>{icon}</SmallIconContainer>
      <CardTextRows>
        <CardTextTitle>{title}</CardTextTitle>
      </CardTextRows>
      <PlusIcon
        css={`
          height: 14px;
        `}
        color={colors.hydraGreen}
      />
    </div>
    <CSSTransition classNames="content" in={showContent} timeout={800} unmountOnExit>
      <CardTextContent>{content}</CardTextContent>
    </CSSTransition>
  </div>
)

export const EsimInstructionsRoute = () => {
  const { t } = useTranslation()
  const [showOptionOne, setShowOptionOne] = useState(false)
  const [showOptionTwo, setShowOptionTwo] = useState(false)
  const [showOptionThree, setShowOptionThree] = useState(false)
  const [showOptionFour, setShowOptionFour] = useState(false)

  useEffect(() => {
    document.title = formatPageTitle(t('esimInstructions.pageTitle'))
  }, [t])

  return (
    <Page
      menu={true}
      footer={true}
      css={`
        ${PageContent} {
          min-height: calc(100vh - 208px);
          @media screen and (min-width: 600px) {
            min-height: calc(100vh - 163px);
          }
        }
      `}
    >
      <div
        css={`
          grid-column-start: 1;
          grid-column-end: 5;
          @media screen and (min-width: 600px) {
            grid-column-start: 1;
            grid-column-end: 9;
          }

          @media screen and (min-width: 1024px) {
            grid-column-start: 1;
            grid-column-end: 13;
          }
        `}
      >
        <BackButton
          color={colors.hydraGreen}
          to={urls.simList}
          text={t('esimInstructions.simCards')}
          css={`
            display: flex;
            align-items: center;
            justify-content: flex-start;
          `}
        />
        <PageTitle>{t('esimInstructions.setupAndManageYourEsimProfile')}</PageTitle>
        <Container>
          <div
            css={`
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-bottom: 1.875rem;

              flex-direction: column-reverse;
              @media screen and (min-width: ${containerWidths.md}px) {
                flex-direction: row;

                ${Picture} {
                  margin-left: 1.875rem;
                }
              }
            `}
          >
            <PageSubtitle>
              <Trans i18nKey="esimInstructions.pageSubtitle" />
            </PageSubtitle>
            <Picture
              css={`
                margin-left: 2.5rem;
                img {
                  height: 16rem;
                }
              `}
              src={EsimInstructions}
              alt={'esim instructions'}
            />
          </div>
          {[
            {
              icon: <QrCodeIcon color={colors.hydraGreen} />,
              title: t('esimInstructions.qrCodeInstallationInstructionTitle'),
              onClick: () => setShowOptionOne(!showOptionOne),
              showContent: showOptionOne,
              content: <Trans i18nKey="esimInstructions.qrCodeInstallationInstructionContent" />,
            },
            {
              icon: <PlusIcon color={colors.hydraGreen} />,
              title: t('esimInstructions.esimManualInstallationInstructionTitle'),
              onClick: () => setShowOptionTwo(!showOptionTwo),
              showContent: showOptionTwo,
              content: (
                <Trans i18nKey="esimInstructions.esimManualInstallationInstructionContent">
                  <CopyIcon
                    css={`
                      transform: translate(0, 25%);
                    `}
                    color={colors.hydraGreen}
                  />
                </Trans>
              ),
            },
            {
              icon: <EsimIcon color={colors.hydraGreen} />,
              title: t('esimInstructions.esimCanOnlyBeInstalledOnceTitle'),
              onClick: () => setShowOptionThree(!showOptionThree),
              showContent: showOptionThree,
              content: <Trans i18nKey="esimInstructions.esimCanOnlyBeInstalledOnceContent" />,
            },
            {
              icon: <TrashcanIcon color={colors.hydraGreen} />,
              title: t('esimInstructions.deletingEsimProfileTitle'),
              onClick: () => setShowOptionFour(!showOptionFour),
              showContent: showOptionFour,
              content: <Trans i18nKey="esimInstructions.deletingEsimProfileContent" />,
            },
          ].map((card) => (
            <Card key={card.title} onClick={card.onClick}>
              <CardContent icon={card.icon} title={card.title} showContent={card.showContent} content={card.content} />
            </Card>
          ))}
        </Container>
      </div>
    </Page>
  )
}
