import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import styled from 'styled-components'
import { fold } from 'fp-ts/es6/Either'
import { PageTitle } from '../../styles/PageTitle'
import { urls } from '../../../urls'
import { Page } from '../../common/Page'
import { BackButton } from '../../common/BackButton'
import { Button } from '../../Button/Button'
import { useDataLoader } from '../../../customHooks/useDataLoader'
import { postStoreComment } from '../../../api/api'
import {
  redirectToSignInOnAuthenticationError,
  renewJwtTokenOnAuthenticationExpiredError,
} from '../../../api/api-utils'
import { exhaustiveCheck } from '../../../exhaustive-switch-check'
import { useErrorContext } from '../../../context/ErrorContext'
import { useJwtContext } from '../../../context/JwtContext'
import { AppQueryContext } from '../../../context/AppQueryContext'
import { colors } from '../../../theme'
import { SelectBaseStyle } from '../../styles/SelectBaseStyle'

const Textarea = styled.textarea`
  border-radius: 0.25rem;
  width: 100%;
  min-height: 6.25rem;
  outline: none;
  padding: 0.625rem;
  margin-bottom: 0.625rem;
  border: 0.0625rem solid ${colors.borderColor};
`

const Label = styled.div`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.3125rem;
`

const Select = styled.select`
  ${SelectBaseStyle};
  margin-bottom: 0.3125rem;
`

export const FeedbackPage = () => {
  const { t } = useTranslation()
  const { onError } = useErrorContext()
  const appQueryContext = useContext(AppQueryContext)
  const { jwtToken } = useJwtContext()
  const api = useDataLoader(postStoreComment)
  const { query, isLoading } = api
  const [text, setText] = useState('')
  const [success, setSuccess] = useState(false)

  const feedbackCategories = [
    'feedback.categories.generalFeedback',
    'feedback.categories.reportABug',
    'feedback.categories.suggestAFeature',
  ].map((key) => t(key))

  const [category, setCategory] = useState(feedbackCategories[0])

  return (
    <Page menu={true} footer={true}>
      <div
        css={`
          grid-column-start: 1;
          grid-column-end: 5;
          @media screen and (min-width: 600px) {
            grid-column-start: 1;
            grid-column-end: 9;
          }

          @media screen and (min-width: 1024px) {
            grid-column-start: 1;
            grid-column-end: 13;
          }
        `}
      >
        <>
          <BackButton
            to={urls.support.index.clientUrl()}
            color={colors.hydraGreen}
            text={t('support.feedback.support')}
          />
          <PageTitle>{t('support.feedback.pageTitle')}</PageTitle>
        </>
        {success ? (
          <div>{t('support.feedback.thanks')}</div>
        ) : (
          <>
            <Label>{t('support.feedback.topic')}</Label>
            <Select onChange={(e) => setCategory(e.target.value)}>
              {feedbackCategories.map((c) => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </Select>

            <Label
              css={`
                margin-top: 1.25rem;
              `}
            >
              {t('support.feedback.feedback')}
            </Label>
            <Textarea
              autoFocus={true}
              onChange={(e) => setText(e.target.value)}
              placeholder={t('support.feedback.messagePlaceholder')}
            />
            <Button
              loading={isLoading}
              disabled={text.length === 0}
              text={t('support.feedback.send')}
              onClick={() => {
                query({
                  jwtToken,
                  body: {
                    title: category,
                    text: `${text} (sent from web-ui)`,
                    email: appQueryContext?.appQuery?.email,
                  },
                })
                  .then((response) =>
                    pipe(
                      response,
                      fold(
                        (error) => {
                          switch (error.type) {
                            case 'FETCH_ERROR':
                            case 'ABORT_ERROR':
                            case 'API_ERROR':
                            case 'THROTTLE_ERROR':
                            case 'WRONG_PIN_CODE':
                              return
                            case 'AUTHENTICATION_EXPIRED_ERROR':
                              return renewJwtTokenOnAuthenticationExpiredError()
                            case 'AUTHENTICATION_ERROR':
                              return redirectToSignInOnAuthenticationError()
                            default:
                              return exhaustiveCheck(error)
                          }
                        },
                        () => {
                          setSuccess(true)
                        }
                      )
                    )
                  )
                  .catch(onError)
              }}
            />
          </>
        )}
      </div>
    </Page>
  )
}
