import React from 'react'
import { useTranslation } from 'react-i18next'
import { DataPlansIcon } from '../../icons/DataPlansIcon'

export const OfflineToast = () => {
  const { t } = useTranslation()
  return (
    <div
      css={`
        align-items: center;
        display: flex;
      `}
    >
      <DataPlansIcon
        css={`
          margin-right: 0.3125rem;
        `}
        color={'#fff'}
      />
      {t('offlineToast.text')}
    </div>
  )
}
