import React from 'react'
import { IconWithColor } from '../types'

export const CloseIcon = (props: IconWithColor) => (
  <svg className={props.className} width="21px" height="21px" viewBox="0 0 21 21" version="1.1">
    <defs>
      <polygon
        id="path-1"
        points="19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12"
      />
    </defs>
    <g id="Home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Add-SIM-card/QR-code" transform="translate(-29.000000, -50.000000)">
        <rect id="Background" fill="#FFFFFF" x="0" y="0" width="21" height="760" />
        <g id="navigation/top-app-bar/title-center" transform="translate(0.000000, 32.000000)">
          <g id="navigation/top-app-bar/base">
            <rect id="Background" x="0" y="0" width="360" height="56" />
            <g id="icons/icon-24px" transform="translate(24.000000, 16.000000)">
              <g id="icons/_glyphs/close">
                <use fill={props.color} fillRule="evenodd" href="#path-1" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
