import React from 'react'
import styled from 'styled-components'
import { DialogContent } from '../styles/DialogContent'
import { useDialogContext } from '../../context/DialogContext'
import { GreenButton } from '../Button/Button'
import { colors } from '../../theme'

interface Props {
  title: string
  content: string
  buttonTitle: string
  onClose?: () => void
}

const Checkmark = styled.div`
  transition: opacity 0.2s;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ActionSuccessfulDialog = ({ title, content, buttonTitle, onClose }: Props) => {
  const { setDialog } = useDialogContext()

  return (
    <DialogContent onClose={onClose !== undefined ? onClose : () => setDialog(null)} verticalCenter={true}>
      <div
        css={`
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
        `}
      >
        <div
          css={`
            display: flex;
            justify-content: flex-start;
            align-items: center;
          `}
        >
          <div
            css={`
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              width: 4rem;
              height: 4rem;
              background-color: ${colors.hydraGreenOpaPointOne};
            `}
          >
            <Checkmark>
              <div
                css={`
                  content: '';
                  margin-bottom: 0.125rem;
                  width: 0.626rem;
                  height: 1.5rem;
                  border: solid ${colors.hydraGreen};
                  border-width: 0 0.125rem 0.125rem 0;
                  transform: rotate(45deg);
                `}
              />
            </Checkmark>
          </div>
        </div>
        <div
          css={`
            display: flex;
            align-items: center;
            font-size: 1rem;
          `}
        >
          <div>
            <h1
              css={`
                margin-top: 3rem;
                font-weight: bold;
                font-size: 1.75rem;
              `}
            >
              {title}
            </h1>
            <p
              css={`
                font-weight: medium;
                font-size: 1rem;
              `}
            >
              {content}
            </p>
            <GreenButton
              css={`
                margin-top: 2rem;
                color: ${colors.white};
                :hover {
                  opacity: 0.7;
                }
              `}
              text={buttonTitle}
              onClick={onClose !== undefined ? onClose : () => setDialog(null)}
            />
          </div>
        </div>
      </div>
    </DialogContent>
  )
}
