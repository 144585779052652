import React from 'react'
import styled from 'styled-components'
import { pipe } from 'fp-ts/lib/function'
import { fold } from 'fp-ts/es6/Either'
import { useTranslation } from 'react-i18next'
import { containerWidths } from '../../theme'
import { LANGUAGES } from '../../i18n'
import { putUpdateUser } from '../../api/api'
import { useDataLoader } from '../../customHooks/useDataLoader'
import { renewJwtTokenOnAuthenticationExpiredError, redirectToSignInOnAuthenticationError } from '../../api/api-utils'
import { exhaustiveCheck } from '../../exhaustive-switch-check'
import { useErrorContext } from '../../context/ErrorContext'
import { useJwtContext } from '../../context/JwtContext'

const BorderlessSelect = styled.select`
  border-top: solid 2px rgba(255, 255, 255, 0.1);
  border-bottom: solid 2px rgba(255, 255, 255, 0.1);
  border-left: none;
  border-right: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 12px;
  font-weight: 600;
  color: white;
  background-color: transparent;
  width: 100%;

  @media screen and (min-width: ${containerWidths.md}px) {
    border-left: solid 2px rgba(255, 255, 255, 0.1);
    border-right: solid 2px rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 0.5rem;
  }
`

export const LanguageSelector = () => {
  const { i18n } = useTranslation()
  const { query } = useDataLoader(putUpdateUser)
  const { onError } = useErrorContext()
  const { jwtToken } = useJwtContext()

  const onLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault()
    void i18n.changeLanguage(event.target.value)
    setLanguage(event.target.value)
  }

  const setLanguage = (language: string) => {
    if (jwtToken === null) {
      return
    }

    query({
      jwtToken,
      body: {
        language,
      },
    })
      .then((response) =>
        pipe(
          response,
          fold(
            (error) => {
              switch (error.type) {
                case 'FETCH_ERROR':
                case 'ABORT_ERROR':
                case 'API_ERROR':
                case 'THROTTLE_ERROR':
                case 'WRONG_PIN_CODE':
                  return
                case 'AUTHENTICATION_EXPIRED_ERROR':
                  return renewJwtTokenOnAuthenticationExpiredError()
                case 'AUTHENTICATION_ERROR':
                  return redirectToSignInOnAuthenticationError()
                default:
                  return exhaustiveCheck(error)
              }
            },
            () => {}
          )
        )
      )
      .catch(onError)
  }

  const sortedLanguages = [...LANGUAGES].sort((a, b) => a.name.localeCompare(b.name))

  return (
    <BorderlessSelect onChange={onLanguageChange} defaultValue={i18n.language}>
      {sortedLanguages.map(({ name, code }) => (
        <option value={code} key={code} title={name}>
          {name}
        </option>
      ))}
    </BorderlessSelect>
  )
}
