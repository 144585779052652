import styled from 'styled-components'

export const PageTitle = styled.h1`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #19224c;
  display: flex;
  position: relative;
`
