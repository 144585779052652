import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import sortBy from 'lodash.sortby'
import { zip } from 'fp-ts/lib/Array'
import styled from 'styled-components'
import { useCountryListLoader } from '../../../customHooks/useCountryListLoader'
import { colors, containerWidths } from '../../../theme'
import { Loading } from '../../Loading/Loading'
import { PageTitle } from '../../styles/PageTitle'
import { Page } from '../../common/Page'
import { urls } from '../../../urls'
import { BackButton } from '../../common/BackButton'
import { Country } from '../../../api/api-types'
import { Picture } from '../../common/Picture'
import { InputField } from '../../common/InputField'
import coverageEarth from './coverage-earth.png'
import allZones from './allzones.png'
import zone1 from './zone1.png'
import zone2 from './zone2.png'
import zone3 from './zone3.png'

const ZoneListItem = styled.li`
  margin-top: 1rem;
  font-size: 24px;
  font-weight: 700;
  color: ${colors.darkGreyOpaPointSix};
`

const ZoneListItemHeader = styled.strong`
  color: ${colors.hydraGreen};
`

const ZoneDescription = () => {
  const { t } = useTranslation()
  return (
    <div
      css={`
        margin-top: 2rem;
        display: flex;
        flex-direction: column-reverse;
        border-radius: 12px;
        background: white;
        @media screen and (min-width: 1024px) {
          flex-direction: row;
        }
      `}
    >
      <div
        css={`
          width: 100%;
        `}
      >
        <div
          css={`
            margin: 2rem 1.5rem;
          `}
        >
          <h2
            css={`
              font-size: 1.75rem;
              font-weight: 700;
            `}
          >
            {t('support.coverage.headerDesciptionTitle')}
          </h2>
          <p
            css={`
              font-size: 1.125rem;
              font-weight: 500;
              color: ${colors.darkGreyOpaPointSix};
              line-height: 1.3;
            `}
          >
            {t('support.coverage.headerDesciptionContent')}
          </p>
          <ul
            css={`
              list-style: none;
              padding-left: 0;
            `}
          >
            <Trans i18nKey="support.coverage.headerDesciptionZoneList">
              <ZoneListItem>
                <ZoneListItemHeader>Zone 1:</ZoneListItemHeader> <>1 GB</>
              </ZoneListItem>
              <ZoneListItem>
                <ZoneListItemHeader>Zone 2:</ZoneListItemHeader> <>500 MB</>
              </ZoneListItem>
              <ZoneListItem>
                <ZoneListItemHeader>Zone 3:</ZoneListItemHeader> <>250 MB</>
              </ZoneListItem>
            </Trans>
          </ul>
        </div>
      </div>

      <div
        css={`
          width: 100%;
          display: none;
          justify-content: flex-end;

          @media screen and (min-width: ${containerWidths.lg}px) {
            width: 50%;
            display: flex;
          }
        `}
      >
        <Picture
          css={`
            img {
              height: 13rem;

              @media screen and (min-width: ${containerWidths.xl}px) {
                height: 16rem;
              }
            }
          `}
          src={coverageEarth}
          alt={'sim card'}
        />
      </div>
    </div>
  )
}

interface ZoneButtonProps {
  zoneFilter: string | null
  zone: string | null
  onClick: () => void
  title: string
  color: string | null
}

const ZoneButton = ({ zoneFilter, zone, onClick, title, color }: ZoneButtonProps) => (
  <div
    css={`
      text-decoration: none;
      color: ${colors.greyText};
      border-radius: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      ${zoneFilter === zone &&
      `
        background-color: ${colors.hydraGreen};
        color: #fff;
        line-height: 1.5;
      `};
    `}
    onClick={onClick}
  >
    <div
      css={`
        display: flex;
        align-items: center;
        margin: 0 20px;

        @media screen and (min-width: ${containerWidths.md}px) {
          margin: 0 10px;
        }
      `}
    >
      <div
        css={`
          width: 20px;
          height: 20px;
          border-radius: 10px;
          background: ${color};
        `}
      />
      <p
        css={`
          margin: 0 12px;
        `}
      >
        {title}
      </p>
    </div>
  </div>
)

interface CountryZoneVisualizationProps {
  sortedUniqueZones: string[]
  zoneFilter: string | null
  setZoneFilter: (zone: string | null) => void
}

const CountryZoneVisualization = ({ sortedUniqueZones, zoneFilter, setZoneFilter }: CountryZoneVisualizationProps) => {
  const { t } = useTranslation()
  const [zoneIndex, setZoneIndex] = useState<number>(0)
  const zonePic = () => {
    switch (zoneIndex) {
      case 0:
        return allZones
      case 1:
        return zone1
      case 2:
        return zone2
      case 3:
        return zone3
    }
    return allZones
  }

  return (
    <div
      css={`
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <h2
        css={`
          font-size: 2.5rem;
          font-weight: 700;
        `}
      >
        {t('support.coverage.coverageWorldMapTitle')}
      </h2>
      <ZoneFilter>
        <ZoneButton
          zoneFilter={zoneFilter}
          zone={null}
          key={'allZones'}
          onClick={() => {
            setZoneFilter(null)
            setZoneIndex(0)
          }}
          title={t('support.coverage.allZones')}
          color={null}
        />
        {zip(sortedUniqueZones, [colors.yellow, colors.teal, colors.darkGrey]).map(([zone, color], index) => (
          <ZoneButton
            zoneFilter={zoneFilter}
            zone={zone}
            key={zone}
            onClick={() => {
              setZoneFilter(zone)
              setZoneIndex(index + 1)
            }}
            title={`${t('activeUserPackage.zone')} ${zone.substring(5)}`}
            color={color}
          />
        ))}
      </ZoneFilter>
      <Picture
        css={`
          margin: 3rem 0;
          img {
            width: 100%;
          }
        `}
        src={zonePic()}
        alt={'zone'}
      />
    </div>
  )
}

const sortByFirstLetter = (countryList: Country[]) => {
  const initialAcc: {
    [index: string]: Country[]
  } = {}
  const groupedByYearAndMont = countryList.reduce((acc, cur) => {
    const key = cur.name.charAt(0)
    return cur.supported ? Object.assign(acc, { [key]: (acc[key] || []).concat(cur) }) : acc
  }, initialAcc)

  const sortedByFirstLetter = Object.entries(groupedByYearAndMont).sort(([a], [b]) => {
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
    return 0
  })

  return sortedByFirstLetter
}

const Wrapper = ({ children }: { children: React.ReactElement }) => {
  const { t } = useTranslation()
  return (
    <Page menu={true} footer={true}>
      <div
        css={`
          grid-column-start: 1;
          grid-column-end: 5;
          @media screen and (min-width: 600px) {
            grid-column-start: 1;
            grid-column-end: 9;
          }

          @media screen and (min-width: 1024px) {
            grid-column-start: 1;
            grid-column-end: 13;
          }
        `}
      >
        <>
          <BackButton
            to={urls.support.index.clientUrl()}
            color={colors.hydraGreen}
            text={t('support.coverage.support')}
          />
          <PageTitle
            css={`
              font-size: 3rem;
              text-align: center;
              display: inline;
            `}
          >
            <div
              css={`
                margin-top: 3rem;
                display: inline-block;
              `}
            >
              <Trans i18nKey="support.coverage.pageHeaderTitle">
                {'Available in over '}
                <strong
                  css={`
                    color: ${colors.hydraGreen};
                  `}
                >
                  {'150 countries'}
                </strong>
                {' across more than '}
                <strong
                  css={`
                    color: ${colors.hydraGreen};
                  `}
                >
                  {'600 networks'}
                </strong>
              </Trans>
            </div>
          </PageTitle>
        </>

        <div
          css={`
            display: flex;
            justify-content: center;
          `}
        >
          <div
            css={`
              margin-top: 2rem;
              font-size: 1.5rem;
              color: ${colors.greyText};
              line-height: 2rem;
              text-align: center;
              width: 70%;
            `}
          >
            {t('support.coverage.headerHelpText')}
          </div>
        </div>

        <ZoneDescription />
        {children}
      </div>
    </Page>
  )
}

const ZoneFilter = styled.div`
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 1.25rem 0 0;
  width: 100%;
  height: 100%;
  padding: 0.5rem;

  > div {
    padding: 0.25rem 0;
    margin-top: 20px;
  }

  @media screen and (min-width: ${containerWidths.md}px) {
    margin: 0 0 1.875rem 0;
    background-color: #fff;
    border-radius: 40px;
    border-width: 1px;
    border-color: ${colors.darkGreyOpaPointSix};
    border-style: solid;
    font-size: 1.125rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    > div {
      padding: 0.25rem 0;
      margin-top: 0;
    }
  }

  @media screen and (min-width: ${containerWidths.xl}px) {
    font-size: 1.125rem;
    flex-direction: row;
    margin: 0 0 1.875rem 0;
    justify-content: space-between;
    div {
      padding: 0.25rem;
    }
  }
`
const Input = styled(InputField)`
  margin-bottom: 2rem;
`

const Searchbar = ({ onTextChanged }: { onTextChanged: (text: string) => void }) => {
  const { t } = useTranslation()
  return (
    <div>
      <Input
        disabled={false}
        type="text"
        onChange={(e) => {
          onTextChanged(e.target.value)
        }}
        placeholder={t('support.coverage.countrySearchPlaceholder')}
      />
    </div>
  )
}

const containsSearchText = (searchText: string | null, countryName: string) => {
  if (searchText === null) {
    return countryName
  }
  return countryName.toLowerCase().includes(searchText.toLowerCase())
}

export const CoveragePage = () => {
  const countryList = useCountryListLoader()
  const zones: string[] = (countryList || []).map((country) => country.zoneName || '').filter((zone) => zone !== '')
  const sortedUniqueZones: string[] = sortBy([...new Set(zones)])
  const [zoneFilter, setZoneFilter] = useState<string | null>(null)
  const [searchText, setSearchText] = useState<string | null>(null)

  if (!countryList) {
    return (
      <Wrapper>
        <Loading
          css={`
            margin-top: 1.875rem;
          `}
          color={'#000'}
        />
      </Wrapper>
    )
  }

  const filteredCountryList = searchText
    ? countryList.filter((country) => containsSearchText(searchText, country.name))
    : countryList

  const sortedByFirstLetter = sortByFirstLetter(
    zoneFilter ? filteredCountryList.filter((country) => country.zoneName === zoneFilter) : filteredCountryList
  )

  return (
    <Wrapper>
      <>
        <CountryZoneVisualization
          sortedUniqueZones={sortedUniqueZones}
          zoneFilter={zoneFilter}
          setZoneFilter={setZoneFilter}
        />
        <Searchbar onTextChanged={setSearchText} />
        <div
          css={`
            display: flex;
            flex-direction: row;
            margin-top: 1.875rem;

            @media screen and (min-width: ${containerWidths.md}px) {
              flex-direction: column;
              margin-top: 0;
            }
          `}
        >
          <div>
            {sortedByFirstLetter.map(([groupName, group], index) => (
              <div
                key={groupName}
                id={groupName}
                css={`
                  background: ${index % 2 === 0 ? colors.bgColor : colors.white};
                  padding: 1rem 1rem;

                  @media screen and (min-width: ${containerWidths.md}px) {
                    display: flex;
                  }
                `}
              >
                <div
                  css={`
                    font-size: 1.875rem;
                    font-weight: 600;
                    width: 4rem;
                    padding-bottom: 0.3125rem;
                    border-bottom: 0.1875rem solid #e4edee;
                    margin: 2.5rem 0 0.625rem 0;
                    display: inline-flex;
                    padding-right: 3.125rem;
                    :first-child {
                      margin-top: 0;
                    }

                    @media screen and (min-width: ${containerWidths.md}px) {
                      padding-bottom: 0.3125rem;
                      border-bottom: none;
                    }
                  `}
                >
                  {groupName}
                </div>
                <div
                  css={`
                    columns: 12rem 3;
                    column-gap: 0rem;
                    div {
                      margin-bottom: 0.9375rem;
                    }
                  `}
                >
                  {group.map((country) => (
                    <div key={country.threeLetterCode}>{country.name}</div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    </Wrapper>
  )
}
