import { Either } from 'fp-ts/es6/Either'
import * as t from 'io-ts'
import { stripeErrors } from '../stripe-errors'

export interface UnsupportedResponse {
  type: 'UNSUPPORTED_RESPONSE'
  messages: string[]
}

export interface AbortError {
  type: 'ABORT_ERROR'
}

export interface FetchError {
  type: 'FETCH_ERROR'
  status?: number
}

export const apiError = {
  API_ERROR: {},
}
export const apiErrorValue = Object.keys(apiError)[0]
export interface ApiError {
  type: keyof typeof apiError
}

export interface PinCodeError {
  type: 'WRONG_PIN_CODE'
}

export interface ThrottleError {
  type: 'THROTTLE_ERROR'
}

export interface AuthenticationExpiredError {
  type: 'AUTHENTICATION_EXPIRED_ERROR'
}

export interface AuthenticationError {
  type: 'AUTHENTICATION_ERROR'
}

export type BaseApiError =
  | AuthenticationExpiredError
  | AuthenticationError
  | AbortError
  | FetchError
  | ApiError
  | ThrottleError
  | PinCodeError
export type CommonApiError = BaseApiError | UnsupportedResponse

export interface ApiParams {
  [index: string]: any
}

export interface JwtApiParams extends ApiParams {
  jwtToken: string
}

export type PostAppQueryRequest = JwtApiParams

export type GetAccountProfileRequest = JwtApiParams

export interface PutAccountProfileRequest extends JwtApiParams {
  body: AccountProfile
}

export interface PostRegisterWithJsonRequestCommon {
  email: string
  displayName: string
  returnJwt: true
  termsAccepted: true
  country: string
  branding: 'wavely'
  loginData: {
    program: 'Hydra'
    referer?: string
    userAgent?: string
    ipAddress?: string
  }
  firstName: null | string
  lastName: null | string
  marketingConsent: boolean
}

export interface PostSoMeRegisterWithJsonRequest extends PostRegisterWithJsonRequestCommon {
  token: string
}

export interface PostEmailRegisterWithJsonRequest extends PostRegisterWithJsonRequestCommon {
  provider: 'Email'
  showPinCode: boolean
  redirectUri: string
}

export interface PostRegisterWithJsonForSomeResponse {
  type: 'some'
  jwt: JwtTokenSuccessResponse
}

export const MagicLinkValidationResponse = t.interface({
  status: t.string,
})

export type MagicLinkValidationResponse = t.TypeOf<typeof MagicLinkValidationResponse>

export type MagicLinkValidationJsonResponse = Either<CommonApiError, MagicLinkValidationResponse>

export const PostValidateMagicLinkRequest = t.interface({
  magicLinkResendToken: t.string,
  magicLinkValidationCode: t.string,
  apiKey: t.string,
})

export type PostValidateMagicLinkRequest = t.TypeOf<typeof PostValidateMagicLinkRequest>

export const ReSendPinTokenRequest = t.interface({
  token: t.string,
  email: t.string,
  apiKey: t.string,
})

export type ReSendPinTokenRequest = t.TypeOf<typeof ReSendPinTokenRequest>

export const PostValidateMagicLinkResponse = t.interface({
  accessToken: t.string,
})

export type PostValidateMagicLinkResponse = t.TypeOf<typeof PostValidateMagicLinkResponse>

export type PostValidateMagicLinkJsonResponse = Either<CommonApiError, PostValidateMagicLinkResponse>

export const ReSendPinTokenResponse = t.interface({
  code: t.string,
})

export type ReSendPinTokenResponse = t.TypeOf<typeof ReSendPinTokenResponse>

export type ReSendPinTokenJsonResponse = Either<CommonApiError, ReSendPinTokenResponse>

export const PostRegisterWithCodeResponse = t.interface({
  type: t.string,
  code: t.string,
  jwt: t.any,
})

export type PostRegisterWithCodeResponse = t.TypeOf<typeof PostRegisterWithCodeResponse>

export type PostRegisterWithJsonResponse = Either<
  CommonApiError,
  PostRegisterWithJsonForSomeResponse | PostRegisterWithCodeResponse
>

export interface RegisterAccountRequest extends ApiParams {
  firstName: null | string
  lastName: null | string
  country: string
  marketingConsent: boolean
}

export const PopulateRegistrationFormSuccessResponse = t.interface({
  accessToken: t.string,
  displayName: t.string,
  email: t.string,
  token: t.string,
})
export type PopulateRegistrationFormSuccessResponse = t.TypeOf<typeof PopulateRegistrationFormSuccessResponse>

export type PopulateRegistrationFormResponse = Either<CommonApiError, PopulateRegistrationFormSuccessResponse>

export const JwtTokenSuccessResponse = t.interface({
  accessToken: t.string,
  refreshToken: t.string,
})
export type JwtTokenSuccessResponse = t.TypeOf<typeof JwtTokenSuccessResponse>

export type JwtTokenResponse = Either<CommonApiError, JwtTokenSuccessResponse>

export interface PostJwtRefreshTokenRequest {
  accessToken: string
  refreshToken: string
}

export interface RegisterWithJsonRequest {
  returnJwt: boolean
  termsAccepted: boolean
  displayName: string
  marketingConsent: boolean
  token: string
  email: string
}

export const AccountProfile = t.intersection([
  t.partial({
    firstName: t.string,
    lastName: t.string,
    country: t.string,
    newsletterOptIn: t.boolean,
  }),
  t.interface({
    email: t.string,
    displayName: t.string,
  }),
])

export type AccountProfile = t.TypeOf<typeof AccountProfile>

export type ProfileResponse = Either<CommonApiError, AccountProfile>

export const RenewedEndUserPackage = t.intersection([
  t.partial({
    expiryDate: t.union([t.null, t.string]),
    renewable: t.boolean,
  }),
  t.interface({
    createdDate: t.string,
    endUserGlobalId: t.string,
    globalId: t.string,
    packageTransitionGlobalId: t.string,
    status: t.union([
      t.literal('ACTIVE'),
      t.literal('PAYMENT_FAILED'),
      t.literal('PENDING'),
      t.literal('REFUNDED'),
      t.literal('EXHAUSTED'),
      t.literal('NEXT_IN_LINE'),
    ]),
    updatedDate: t.union([t.null, t.string]),
  }),
])
export type RenewedEndUserPackage = t.TypeOf<typeof RenewedEndUserPackage>

const BaseNonActiveEndUserPackage = t.interface({
  status: t.union([
    t.literal('PAYMENT_FAILED'),
    t.literal('REFUNDED'),
    t.literal('EXHAUSTED'),
    t.literal('EXPIRED'),
    t.literal('PENDING'),
    t.literal('NEXT_IN_LINE'),
  ]),
  updatedDate: t.union([t.null, t.string]),
})

const BasePendingEndUserPackage = t.interface({
  status: t.union([t.literal('PENDING'), t.literal('EXHAUSTED'), t.literal('NEXT_IN_LINE'),]),
})

const BaseActiveEndUserPackage = t.interface({
  status: t.union([t.literal('ACTIVE'), t.literal('EXHAUSTED'), t.literal('NEXT_IN_LINE'),]),
  updatedDate: t.union([t.null, t.string]),
})

const BytesAmount = t.type({ quantity: t.string, unit: t.string })

const BaseEndUserPackage = t.intersection([
  t.partial({
    snapshotRecordedDate: t.union([t.null, t.string]),
    expiryDate: t.union([t.null, t.string]),
    currency: t.union([t.string, t.null, t.undefined]), // EUR
    dataPoints: t.number,
    totalDataBytes: t.number,
    refundable: t.boolean,
    totalDataBytesAmount: BytesAmount,
    renewable: t.boolean,
    packageTemplateDataGlobalId: t.string,
    unitsRemaining: t.union([t.null, t.number]),
  }),
  t.type({
    price: t.union([t.null, t.number]),
    createdDate: t.string,
    endUserGlobalId: t.string,
    globalId: t.string,
    name: t.union([t.null, t.string]),
    description: t.union([t.null, t.string]),
    packageTypeRenewable: t.boolean,
  }),
])

export const ActiveEndUserPackage = t.intersection([BaseEndUserPackage, BaseActiveEndUserPackage])
export type ActiveEndUserPackage = t.TypeOf<typeof ActiveEndUserPackage>

export const PendingEndUserPackage = t.intersection([BaseEndUserPackage, BasePendingEndUserPackage])
export type PendingEndUserPackage = t.TypeOf<typeof PendingEndUserPackage>

export const Zone = t.type({
  zoneName: t.string,
  amount: t.string,
  quantity: t.string,
  unit: t.string,
})

export type Zone = t.TypeOf<typeof Zone>

export const EndUserPackage = t.intersection([
  BaseEndUserPackage,
  t.union([BaseActiveEndUserPackage, BaseNonActiveEndUserPackage, BasePendingEndUserPackage]),
])
export type EndUserPackage = t.TypeOf<typeof EndUserPackage>

export const EndUserPackages = t.intersection([
  t.partial({
    totalDataBytesBought: t.number,
    daysRemaining: t.number,
  }),
  t.interface({
    remainingPercentage: t.number,
    totalUnitsRemaining: t.number,
    totalUnitsBought: t.number,
    packages: t.array(EndUserPackage),
    packagesActive: t.array(ActiveEndUserPackage),
    dataBytesRemainingByZone: t.array(Zone),
  }),
])
export type EndUserPackages = t.TypeOf<typeof EndUserPackages>

export const Package = t.intersection([
  t.interface({
    price: t.number,
    dataBytesByZone: t.union([t.array(Zone), t.null, t.undefined]),
    globalId: t.string,
    name: t.string,
    amount: t.union([t.string, t.null]),
    free: t.boolean,
    renewable: t.boolean,
  }),
  t.partial({
    dataPoints: t.number,
    dataBytes: t.number,
    currency: t.union([t.string, t.null, t.undefined]),
    description: t.union([t.string, t.null]),
    packageComment: t.union([t.string, t.null]),
    startDate: t.string,
    durationInDays: t.number,
  }),
])
export type Package = t.TypeOf<typeof Package>

export const AvailablePackages = t.interface({
  recommendedPackageIndex: t.number,
  packages: t.array(Package),
  subscriptionPackages: t.array(Package),
})
export type AvailablePackages = t.TypeOf<typeof AvailablePackages>

const CardType = t.union([
  t.keyof({
    visa: null,
    mastercard: null,
    amex: null,
  }),
  t.string,
  t.null,
])
export type CardType = t.TypeOf<typeof CardType>

const BasePaymentMethod = t.intersection([
  t.partial({
    updatedDate: t.union([t.null, t.string]),
    countryCode: t.string, // US
  }),
  t.interface({
    cardType: CardType,
    expiryMonth: t.number,
    createdDate: t.string,
    expiresMonth: t.string,
    expiryDate: t.union([t.null, t.string]),
    expiresYear: t.number,
    isValid: t.boolean,
    lastFour: t.string,
  }),
])
type BasePaymentMethod = t.TypeOf<typeof PaymentMethod>

const PrimaryPaymentMethod = t.intersection([
  BasePaymentMethod,
  t.interface({
    cardRole: t.literal('PRIMARY'),
  }),
])
const SecondaryPaymentMethod = t.intersection([
  BasePaymentMethod,
  t.interface({
    cardRole: t.literal('SECONDARY'),
  }),
])

export const PaymentMethod = t.union([PrimaryPaymentMethod, SecondaryPaymentMethod])
export type PaymentMethod = t.TypeOf<typeof PaymentMethod>

export const PaymentMethodSuccessResponse = t.array(PaymentMethod)
export type PaymentMethodSuccessResponse = t.TypeOf<typeof PaymentMethodSuccessResponse>
export type PaymentMethodResponse = Either<CommonApiError, PaymentMethodSuccessResponse>

export const PartialBillingAddress = t.interface({
  country: t.union([t.string, t.null]),
  email: t.union([t.string, t.null]),
  line1: t.union([t.string, t.null]),
})
export type PartialBillingAddress = t.TypeOf<typeof PartialBillingAddress>

export const BillingAddress = t.interface({
  city: t.union([t.string, t.null]),
  company_name: t.union([t.string, t.null]),
  country: t.union([t.string, t.null]),
  email: t.union([t.string, t.null]),
  first_name: t.union([t.string, t.null]),
  last_name: t.union([t.string, t.null]),
  line1: t.union([t.string, t.null]),
  line2: t.union([t.string, t.null]),
  phone: t.union([t.string, t.null]),
  postal_code: t.union([t.string, t.null]),
  state: t.union([t.string, t.null]),
  tax_id: t.union([t.string, t.null]),
  tax_type: t.union([t.string, t.null]),
})
export type BillingAddress = t.TypeOf<typeof BillingAddress>

// api returns this when user doesn't yet have any sim cards added
export const AppQueryPartial = t.intersection([
  t.partial({
    icc_range_list: t.array(t.string),
  }),
  t.interface({
    availablePackages: t.union([t.null, AvailablePackages]),
    email: t.string,
    hasPendingPurchase: t.boolean,
    hasValidCreditCard: t.boolean,
  }),
])
export type AppQueryPartial = t.TypeOf<typeof AppQueryPartial>

export const PaymentListItem = t.intersection([
  t.partial({
    taxRate: t.union([t.null, t.number]),
    taxCountry: t.union([t.null, t.string]),
    amountWithoutTax: t.union([t.null, t.number]),
    receiptUrl: t.union([t.null, t.string]),
    taxName: t.union([t.null, t.string]),
    taxAmount: t.union([t.null, t.number]),
    cardType: CardType,
  }),
  t.interface({
    amount: t.string, // '1.00000000000000000000'
    createdDate: t.string,
    lastFourDigits: t.string,
    paymentGlobalId: t.string,
    price: t.number,
    status: t.union([t.literal('ON_HOLD'), t.literal('CANCELLED'), t.literal('CAPTURED'), t.literal('PAYMENT_CANCELLED'), t.literal('NEXT_IN_LINE'),t.literal('REFUNDED'), t.literal('COMPLETED'), t.literal('EXHAUSTED'), t.literal('NEXT_IN_LINE'),]),
    name: t.union([t.string, t.null]),
    currency: t.union([t.string, t.null, t.undefined]),
    refundable: t.boolean,
    endUserPackageGlobalId: t.union([t.null, t.string]),
  }),
])
export type PaymentListItem = t.TypeOf<typeof PaymentListItem>

export interface AppQueryRequest {
  randomizeCounts: boolean
}

export const SimCard = t.intersection([
  t.partial({
    currentCountry: t.union([t.null, t.string]),
    currentCountryName: t.union([t.null, t.string]),
    currentZone: t.union([t.null, t.string]),
    downloadUrl: t.union([t.null, t.string]),
    restrictHomeCountryUse: t.union([t.null, t.boolean]),
  }),
  t.interface({
    createdDate: t.string,
    icc: t.string,
    simName: t.union([t.null, t.string]),
    pin1: t.string,
    pin2: t.string,
    puc1: t.string,
    puc2: t.string,
    simcardType: t.keyof({ ESIM: null, STANDALONE: null }),
    state: t.keyof({ TERMINATED: null, ACTIVE: null, OPEN: null, SUSPENDED: null, DISCARDED: null, UNKNOWN: null }),
  }),
])
export type SimCard = t.TypeOf<typeof SimCard>

export interface SimCardNameInUseError {
  type: 'error-name-in-use'
}

export type SimCardResponseError =
  | { type: 'error-pin/icc' }
  | { type: 'error-414'; message?: string }
  | SimCardNameInUseError

export const SimCardResponseSuccess = t.array(SimCard)
export type SimCardResponseSuccess = t.TypeOf<typeof SimCardResponseSuccess>

export type PostSimCardResponse = Either<SimCardResponseError | CommonApiError, SimCardResponseSuccess>
export type PutSimCardResponse = Either<SimCardNameInUseError | CommonApiError, SimCardResponseSuccess>

export type PostRequestSimPinCodeResponse = Either<BaseApiError, void>
export type PostRegisterSimCardWithPinCodeResponse = Either<BaseApiError, void>

export const SimProducts = t.interface({
  currency: t.union([t.string, t.null, t.undefined]),
  priceEsim: t.number,
  includedFreePackage: Package,
})

export type SimProducts = t.TypeOf<typeof SimProducts>

export const AppQuery = t.intersection([
  AppQueryPartial,
  t.partial({
    homeCountryCode: t.union([t.null, t.string]),
  }),
  t.interface({
    availablePackages: AvailablePackages,
    endUserGlobalId: t.string,
    simCards: t.array(SimCard),
    endUserPackages: EndUserPackages,
    paymentMethodList: t.array(PaymentMethod),
    paymentList: t.array(PaymentListItem),
    endUserCreatedDate: t.string,
    billingInformation: t.union([PartialBillingAddress, BillingAddress, t.null, t.undefined]),
    simProducts: t.union([t.null, SimProducts]),
  }),
])
export type AppQuery = t.TypeOf<typeof AppQuery>

export type AppQuerySuccessResponse =
  | {
    type: 'full-response'
    appQuery: AppQuery
  }
  | { type: 'partial-response'; appQuery: AppQuery }

export type AppQueryResponse = Either<CommonApiError, AppQuerySuccessResponse>
export type AppQueryPartialResponse = Either<CommonApiError, AppQueryPartial>

export const Country = t.intersection([
  // TODO: these are not yet available in prod, once they are make them mandatory
  t.partial({
    zoneName: t.union([t.null, t.string]),
    exchangeRate: t.union([t.null, t.number]),
  }),
  t.type({
    name: t.string,
    twoLetterCode: t.string,
    threeLetterCode: t.string,
    numericCode: t.string,
    supported: t.boolean,
  }),
])

export type Country = t.TypeOf<typeof Country>
export const GetCountryListSuccessResponse = t.array(Country)
export type GetCountryListSuccessResponse = t.TypeOf<typeof GetCountryListSuccessResponse>
export type GetCountryListResponse = Either<CommonApiError, GetCountryListSuccessResponse>

export interface PutUpdateUserRequest extends ApiParams {
  jwtToken: string
  body: {
    language: string
  }
}

export type PutUpdateUserResponse = Either<BaseApiError, void>

export const FormattedLocalisations = t.record(t.string, t.string)
export type FormattedLocalisations = t.TypeOf<typeof FormattedLocalisations>

export type PurchasePackageErrorStatus =
  | { type: 'refund-throttle-error'; message: string }
  | { type: 'purchase-error' }
  | BaseApiError
export interface PurchasePackageSuccessStatus {
  type: 'ok'
  globalId?: string
}

export type PurchasePackageStatus = Either<PurchasePackageErrorStatus, PurchasePackageSuccessStatus>

export const PaymentSecretSuccessResponse = t.interface({
  endUserGlobalId: t.string,
  secret: t.string,
})
export type PaymentSecretSuccessResponse = t.TypeOf<typeof PaymentSecretSuccessResponse>
export type PaymentSecretResponse = Either<CommonApiError, PaymentSecretSuccessResponse>

export const PaymentPublicKeySuccessResponse = t.interface({
  publicKey: t.string,
})
export type PaymentPublicKeySuccessResponse = t.TypeOf<typeof PaymentPublicKeySuccessResponse>

export type PaymentPublicKeyResponse = Either<CommonApiError, PaymentPublicKeySuccessResponse>

// TODO: Verify that the call is consisent with both polestar and hydra. Now both packageTemplateGlobalId and packageTransitionGlobalId are passed to make sure it's compadible
export interface PurchasePackageRequest extends JwtApiParams {
  body: { packageTemplateGlobalId: string; packageTransitionGlobalId: string; renewable: boolean }
}

export interface PurchasePackageResponse {
  createdAt: string // "2019-12-02T13:47:22.0526904Z"
  globalId: string // "12329fdd-912d-454b-a39d-3c2da34ac347"
  request: string // "{"packageTemplateGlobalId":"55106281-0544-11ea-a614-005056b26ba2","renewable":false}"
  response: string // = stringified PurchasePackageResponseData
  statusCode: number
  updatedAt: string // "0001-01-01T00:00:00"
  url: string // "endusers/12329fdd-912d-454b-a39d-3c2da34ac347/packages"
}

export interface PurchasePackageResponseData {
  success: boolean
  errorMessage: null | string
  endUserPackage: EndUserPackage
}

export interface PurchasePackageIsFinishedRequest extends JwtApiParams {
  body: { endUserGuid: string }
}

export const PurchasePackageIsFinishedCommon = t.interface({
  elapsed: t.string, // "00:00:02.8318619",
  endUserGuid: t.string, // "12329fdd-912d-454b-a39d-3c2da34ac347",
  finished: t.boolean,
  startTime: t.string, // "2019-12-02T13:47:22.0527298Z",
  status: t.union([t.literal('ACTIVE'), t.literal('PENDING'), t.literal('EXHAUSTED'), t.literal('NEXT_IN_LINE'),]),
  globalId: t.string, // "d5c01ec2-ab2d-4ff3-9d72-db0ed24249f3"
})

export const PurchasePackageIsFinishedError = t.intersection([
  PurchasePackageIsFinishedCommon,
  t.interface({
    success: t.literal(false),
    message: t.literal('PACKAGE_PURCHASE_FAILED'),
    description: t.string,
    failureCode: t.keyof(stripeErrors),
  }),
])
export type PurchasePackageIsFinishedError = t.TypeOf<typeof PurchasePackageIsFinishedError>

export const PurchasePackageIsFinishedSuccess = t.intersection([
  PurchasePackageIsFinishedCommon,
  t.interface({
    success: t.literal(true),
    message: t.null,
    description: t.null,
    failureCode: t.null,
  }),
])
export type PurchasePackageIsFinishedSuccess = t.TypeOf<typeof PurchasePackageIsFinishedSuccess>

export const PurchasePackageIsFinished = t.union([PurchasePackageIsFinishedError, PurchasePackageIsFinishedSuccess])
export type PurchasePackageIsFinished = t.TypeOf<typeof PurchasePackageIsFinished>

export interface PurchaseError {
  type: 'purchase-error'
  message: string
}
export interface PurchaseSuccess {
  type: 'success'
}

export type PurchaseStatusType = PurchaseError | PurchaseSuccess

export type PurchasePackageIsFinishedResponse = Either<
  BaseApiError,
  PurchaseSuccess | { type: 'not-ready' } | PurchaseError
>

export type PurchaseResponse = Either<BaseApiError, PurchaseStatusType>

export interface PostSimCardDeactivateRequest extends JwtApiParams {
  body: {
    action: 'TERMINATE'
    icc: string
  }
}

export type PostSimCardDeactivateFailureResponse = { type: 'SIM_CARD_IMSI_STATE_IMMUTABLE' } | BaseApiError
export interface PostSimCardDeactivateSuccessResponse {
  type: 'success'
}
export type PostSimCardDeactivateResponse = Either<
  PostSimCardDeactivateFailureResponse,
  PostSimCardDeactivateSuccessResponse
>

export interface PostSimCardRequest {
  icc: string
  pin: string
  name?: string
}

export interface PostRequestSimPinCodeRequest extends ApiParams {
  jwtToken: string
  body: {
    icc: string
    phoneNumber: string
  }
}

export interface PostRegisterSimCardWithPinCodeRequest {
  jwtToken: string
  body: {
    icc: string
    pinCode: string
    name?: string
  }
}

export interface PutSimCardRequest extends JwtApiParams {
  body: {
    icc: string
    name: string
  }
}

export interface PostPurchaseEsimRequest extends JwtApiParams {
  body: {
    simName: null | string
    endUserGuid: string
  }
}

export interface PostPurchaseEsimSuccessResponse {
  type: 'success'
}

export type PostPurchaseEsimResponseError =
  | { type: 'error-name-in-use'; message?: string }
  | { type: 'error-no-payment-method'; message?: string }
  | { type: 'error-price-missing'; message?: string }
  | { type: 'error-esim-purchase-ongoing'; message?: string }

export type PostPurchaseEsimResponse = Either<
  PostPurchaseEsimResponseError | CommonApiError,
  PostPurchaseEsimSuccessResponse
>

export const PurchaseEsimIsFinishedCommon = t.interface({
  elapsed: t.string, // "00:00:02.8318619",
  endUserGuid: t.string, // "12329fdd-912d-454b-a39d-3c2da34ac347",
  finished: t.boolean,
  startTime: t.string, // "2019-12-02T13:47:22.0527298Z",
})

export const PurchaseEsimIsFinishedError = t.intersection([
  PurchaseEsimIsFinishedCommon,
  t.interface({
    success: t.literal(false),
  }),
])
export type PurchaseEsimIsFinishedError = t.TypeOf<typeof PurchaseEsimIsFinishedError>

export const PurchaseEsimIsFinishedSuccess = t.intersection([
  PurchaseEsimIsFinishedCommon,
  t.interface({
    success: t.literal(true),
    icc: t.string,
  }),
])
export type PurchaseEsimIsFinishedSuccess = t.TypeOf<typeof PurchaseEsimIsFinishedSuccess>

export const PurchaseEsimIsFinished = t.union([PurchaseEsimIsFinishedError, PurchaseEsimIsFinishedSuccess])
export type PurchaseEsimIsFinished = t.TypeOf<typeof PurchaseEsimIsFinished>

export interface PurchaseEsimIsFinishedRequest extends JwtApiParams {
  body: { endUserGuid: string }
}

export type PurchaseEsimIsFinishedResponse = Either<
  BaseApiError,
  EsimPurchaseSuccess | { type: 'not-ready' } | PurchaseError
>

export interface EsimPurchaseSuccess {
  type: 'success'
  icc: string
}

export type EsimPurchaseStatusType = PurchaseError | EsimPurchaseSuccess

export type EsimPurchaseResponse = Either<BaseApiError, EsimPurchaseStatusType>

export interface PostRenewPackageRequest extends JwtApiParams {
  body: { endUserPackageGlobalId: string }
}

export interface PostRenewPackageResponseSuccess {
  endUserPackage: RenewedEndUserPackage
  errorMessage: null
  success: true
}

export type PostRenewPackageResponseError = { type: 'error'; message: string } | CommonApiError

export type PostRenewPackageResponse = Either<PostRenewPackageResponseError, PostRenewPackageResponseSuccess>

export interface PostRefundPackageRequest extends JwtApiParams {
  body: { endUserPackageGlobalId: string }
}

export interface PostRefundPackageResponseSuccess {
  type: 'refunded'
}
export type PostRefundPackageResponseError =
  | {
    type: 'NO_PACKAGE_REFUND_DUE_TO_USAGE'
  }
  | { type: 'NO_PACKAGE_REFUND_DUE_TO_STATUS' }
  | CommonApiError
export type PostRefundPackageResponse = Either<PostRefundPackageResponseError, PostRefundPackageResponseSuccess>

export interface PostUpdatePackageRequest extends JwtApiParams {
  body: { endUserPackageGlobalId: string; renewable: boolean }
}
export type PostUpdatePackageResponseSuccess = RenewedEndUserPackage
export type PostUpdatePackageResponse = Either<CommonApiError, PostUpdatePackageResponseSuccess>

export interface UpdateAddressRequest extends JwtApiParams {
  body: BillingAddress
}

export interface PostSendMagicLinkEmailRequest extends ApiParams {
  apiKey: string
  body: {
    email: string
    branding: 'wavely'
    loginData: {
      uuid: null
      program: 'Hydra'
      referer: null | string
      userAgent: string
      ipAddress: null | string
      representativeTribeId: null | string
      mccMnc: null | string
    }
    fcmToken: null
    redirectUri: string
    showPinCode: true
  }
}

export const PostSendMagicLinkEmailSuccessResponse = t.interface({
  type: t.string,
  code: t.string,
})

export type PostSendMagicLinkEmailSuccessResponse = t.TypeOf<typeof PostSendMagicLinkEmailSuccessResponse>

export interface PostSendMagicLinkEmailNoAccountResponse {
  type: 'NO_ACCOUNT'
}

export type PostSendMagicLinkEmailResponse = Either<
  BaseApiError | PostSendMagicLinkEmailNoAccountResponse,
  PostSendMagicLinkEmailSuccessResponse
>

export const PostPopulateDisplayNameSuccessResponse = t.interface({
  displayName: t.string,
})
export type PostPopulateDisplayNameSuccessResponse = t.TypeOf<typeof PostPopulateDisplayNameSuccessResponse>

export type PostPopulateDisplayNameResponse = Either<CommonApiError, PostPopulateDisplayNameSuccessResponse>
export type BillingAddressResponse = Either<CommonApiError, BillingAddress>

export interface PostStoreCommentRequest extends ApiParams {
  jwtToken: string | null
  body: {
    text: string
    title: string
    email?: string
  }
}

export type PostStoreCommentResponse = Either<BaseApiError, void>

export type GetVersionResponse = Either<BaseApiError, { type: 'version'; version: string }>

export const TaxType = t.interface({
  tax_type: t.string,
  tax_name: t.string,
  // Optional - Wavely
  taxType: t.string,
  taxName: t.string,
})
export type TaxType = t.TypeOf<typeof TaxType>

export const TaxTypesResponse = t.interface({
  default_tax_type: t.string,
  list: t.array(TaxType),
})
export type TaxTypesResponse = t.TypeOf<typeof TaxTypesResponse>
const Value = t.type({
  string: t.string,
})
type Value = t.TypeOf<typeof Value>

const ContentfulKeyValuePair = t.type({
  key: t.string,
  value: Value,
})
type ContentfulKeyValuePair = t.TypeOf<typeof ContentfulKeyValuePair>

const KeyValuePairsCollection = t.type({
  items: t.array(ContentfulKeyValuePair),
})
type KeyValuePairsCollection = t.TypeOf<typeof KeyValuePairsCollection>

const Sys = t.type({
  publishedVersion: t.unknown,
})
type Sys = t.TypeOf<typeof Sys>

const Items = t.type({
  sys: Sys,
  keyValuePairsCollection: KeyValuePairsCollection,
})
type Items = t.TypeOf<typeof Items>

const AppContentCollection = t.type({
  items: t.array(Items),
})
type AppContentCollection = t.TypeOf<typeof AppContentCollection>

const ContentfulData = t.type({
  appContentCollection: AppContentCollection,
})
type ContentfulData = t.TypeOf<typeof ContentfulData>

export const ContentfulLocalisationResponse = t.type({
  data: ContentfulData,
})
export type ContentfulLocalisationResponse = t.TypeOf<typeof ContentfulLocalisationResponse>

export const ContentfulErrorResponse = t.type({
  data: t.object,
  error: t.array(t.object),
})
export type ContentfulErrorResponse = t.TypeOf<typeof ContentfulErrorResponse>

export interface AddPaymentMethodError {
  type: 'payment-method-error'
  message: string
}

export interface AddPaymentMethodSuccess {
  type: 'success'
}

export type AddPaymentMethodIsFinishedResponse = Either<
  BaseApiError,
  AddPaymentMethodSuccess | { type: 'not-ready' } | AddPaymentMethodError
>

export interface AddPaymentMethodSuccess {
  type: 'success'
}

export type AddPaymentMethodStatusType = AddPaymentMethodError | AddPaymentMethodSuccess

export type AddPaymentMethodResponse = Either<BaseApiError, AddPaymentMethodStatusType>

// Freshdesk
export interface PostCreateFreshdeskTicketRequest {
  name: string
  email: string
  description: string
  status: 2
  priority: 1
  subject: string
  custom_fields: {
    cf_country: 'United States'
    cf_product_group_division: 'Connectivity'
    cf_product_unit: 'Wavely'
    cf_device_type: string
    cf_device_brand: string
    cf_device_model: string
    cf_category: string
    cf_subcategory: string
  }
  source: 1
  tags: ['en_GWS support']
}

export const PostCreateFreshdeskTicketSuccessResponse = t.interface({
  id: t.number,
})

export type PostCreateFreshdeskTicketSuccessResponse = t.TypeOf<typeof PostCreateFreshdeskTicketSuccessResponse>

export type PostCreateFreshdeskTicketResponse = Either<UnsupportedResponse, PostCreateFreshdeskTicketSuccessResponse>

export interface PostNetworkSwitchRequest extends JwtApiParams {
  body: {
    pac: string
    msisdn: string
  }
}
