import { fold } from 'fp-ts/es6/Either'
import { pipe } from 'fp-ts/es6/pipeable'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { getAccountProfile } from '../../../api/api-auth'
import { postCreateFreshdeskTicket } from '../../../api/api-freshdesk'
import { AccountProfile } from '../../../api/api-types'
import {
  redirectToSignInOnAuthenticationError,
  renewJwtTokenOnAuthenticationExpiredError,
} from '../../../api/api-utils'
import { useDialogContext } from '../../../context/DialogContext'
import { useErrorContext } from '../../../context/ErrorContext'
import { useDataLoader } from '../../../customHooks/useDataLoader'
import { exhaustiveCheck } from '../../../exhaustive-switch-check'
import { formatPageTitle } from '../../../formatPageTitle'
import { colors } from '../../../theme'
import { urls } from '../../../urls'
import { Button } from '../../Button/Button'
import { ActionSuccessfulDialog } from '../../common/ActionSuccessfulDialog'
import { BackButton } from '../../common/BackButton'
import { Page } from '../../common/Page'
import { SectionTitle } from '../../common/styles'
import { LoadingPage } from '../../Loading/LoadingPage'
import { PageTitle } from '../../styles/PageTitle'

interface Props {
  jwtToken: string | null
}

const FieldContainer = styled.div`
  margin-top: 0.3125rem;
  margin-bottom: 0.625rem;
`

const SelectContainer = styled.div`
  border-radius: 0.5rem;
  border: 0.125rem solid rgba(0, 30, 45, 0.1);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`

const Select = styled.select`
  border-color: transparent;
  outline: none;
  padding: 0.875rem;
  font-size: 1rem;
  font-weight: 500;
  background: transparent;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
  width: 100%;
`
const SubjectTextarea = styled.textarea`
  border-radius: 0.25rem;
  width: 100%;
  outline: none;
  padding: 0.625rem 0.625rem 0 0.625rem;
  margin-bottom: 0.625rem;
  border: 0.0625rem solid ${colors.borderColor};
`

const Textarea = styled.textarea`
  border-radius: 0.25rem;
  width: 100%;
  min-height: 6.25rem;
  outline: none;
  padding: 0.625rem;
  margin-bottom: 0.625rem;
  border: 0.0625rem solid ${colors.borderColor};
`

const Label = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  margin-bottom: 0.3125rem;
`

export const CreateSupportTicketPage = ({ jwtToken }: Props) => {
  const { t } = useTranslation()
  const { setDialog } = useDialogContext()
  const { onError } = useErrorContext()
  const postCreateFreshdeskTicketApi = useDataLoader(postCreateFreshdeskTicket)
  const { query: postCreateFreshdeskTicketQuery, isLoading } = postCreateFreshdeskTicketApi
  const [subject, setSubject] = useState('')
  const [text, setText] = useState('')
  const [state, setState] = useState<null | AccountProfile>(null)
  const { query: getProfileQuery } = useDataLoader(getAccountProfile)
  const history = useHistory()
  const [category, setCategory] = useState('Account')
  const [deviceType, setDeviceType] = useState('Laptop')
  const [brand, setDeviceBrand] = useState('')
  const [model, setDeviceModel] = useState('')

  const categoryOptions = [
    'Account',
    'Billing and payments',
    'Connectivity and roaming',
    'Product Inquiry',
    'Applications',
    'Other',
  ]
  const deviceTypes = ['Phone', 'Laptop', 'Other']

  useEffect(() => {
    document.title = formatPageTitle('Create support ticket')

    if (state) {
      return
    }

    if (jwtToken !== null) {
      getProfileQuery({ jwtToken })
        .then((response) =>
          pipe(
            response,
            fold(
              (error) => {
                switch (error.type) {
                  case 'ABORT_ERROR':
                    return
                  case 'THROTTLE_ERROR':
                  case 'WRONG_PIN_CODE':
                    return
                  case 'FETCH_ERROR':
                  case 'API_ERROR':
                  case 'UNSUPPORTED_RESPONSE':
                    return onError(error, error.type)
                  case 'AUTHENTICATION_EXPIRED_ERROR':
                    return renewJwtTokenOnAuthenticationExpiredError()
                  case 'AUTHENTICATION_ERROR':
                    return redirectToSignInOnAuthenticationError()
                  default:
                    return exhaustiveCheck(error)
                }
              },
              (profile) => setState(profile)
            )
          )
        )
        .catch(onError)
    }
  }, [onError, getProfileQuery, jwtToken, state, t])

  if (!state) {
    return <LoadingPage />
  }

  return (
    <Page menu={true} footer={true}>
      <div
        css={`
          grid-column-start: 1;
          grid-column-end: 5;
          @media screen and (min-width: 600px) {
            grid-column-start: 1;
            grid-column-end: 9;
          }

          @media screen and (min-width: 1024px) {
            grid-column-start: 1;
            grid-column-end: 13;
          }
        `}
      >
        <BackButton
          color={colors.hydraGreen}
          to={urls.support.root}
          text={'Support'}
          css={`
            display: flex;
            align-items: center;
            justify-content: flex-start;
          `}
        />
        <PageTitle>Create support ticket</PageTitle>
        <SectionTitle>Describe the issue you are facing as clearly as possible.</SectionTitle>
        <FieldContainer
          css={`
            margin-top: 1.25rem;
          `}
        >
          <Label>
            {'Reason'}
            <br />
            <SelectContainer>
              <Select
                id={'category'}
                onChange={(e) => {
                  setCategory(e.currentTarget.value)
                }}
                defaultValue={category}
              >
                {categoryOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </SelectContainer>
          </Label>
        </FieldContainer>

        <FieldContainer
          css={`
            margin-top: 1.25rem;
            margin-bottom: 0.625rem;
          `}
        >
          <Label>
            {'Device Type'}
            <br />
            <SelectContainer>
              <Select
                id={'category'}
                onChange={(e) => {
                  setDeviceType(e.currentTarget.value)
                }}
                defaultValue={deviceType}
              >
                {deviceTypes.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </SelectContainer>
          </Label>
        </FieldContainer>

        <Label
          css={`
            margin-top: 2rem;
          `}
        >
          {'Device brand'}
        </Label>
        <SubjectTextarea autoFocus={true} onChange={(e) => setDeviceBrand(e.target.value)} />

        <Label
          css={`
            margin-top: 1.25rem;
          `}
        >
          {'Device model'}
        </Label>
        <SubjectTextarea autoFocus={true} onChange={(e) => setDeviceModel(e.target.value)} />

        <Label
          css={`
            margin-top: 1.25rem;
          `}
        >
          {'Subject'}
        </Label>
        <SubjectTextarea autoFocus={true} onChange={(e) => setSubject(e.target.value)} />
        <Label
          css={`
            margin-top: 1.25rem;
          `}
        >
          {'Describe your problem'}
        </Label>
        <Textarea onChange={(e) => setText(e.target.value)} />
        <Button
          loading={isLoading}
          disabled={
            text.length === 0 ||
            subject.length === 0 ||
            deviceType.length === 0 ||
            brand.length === 0 ||
            model.length === 0
          }
          text={t('support.feedback.send')}
          onClick={() => {
            postCreateFreshdeskTicketQuery({
              name: `${state.firstName ?? ''} ${state.lastName ?? ''}`,
              email: state.email,
              description: text,
              status: 2,
              priority: 1,
              subject,
              custom_fields: {
                cf_country: 'United States',
                cf_product_group_division: 'Connectivity',
                cf_product_unit: 'Wavely',
                cf_device_type: deviceType,
                cf_device_brand: brand,
                cf_device_model: model,
                cf_category: category,
                cf_subcategory: category === 'Account' ? '…' : '...',
              },
              source: 1,
              tags: ['en_GWS support'],
            })
              .then((response) =>
                pipe(
                  response,
                  fold(
                    (error) => {
                      switch (error.type) {
                        case 'UNSUPPORTED_RESPONSE':
                          return onError(error, error.type)
                      }
                    },
                    () =>
                      setDialog(
                        <ActionSuccessfulDialog
                          title={'Ticket created'}
                          content={'We successfully created a support ticket for you.'}
                          buttonTitle={'Go back to home'}
                          onClose={() => history.push(urls.home)}
                        />
                      )
                  )
                )
              )
              .catch(onError)
          }}
        />
      </div>
    </Page>
  )
}
