import React from 'react'
import { IconWithColor } from '../types'

export const EsimIcon = (props: IconWithColor) => (
  <svg className={props.className} width={'20px'} height={'20px'} viewBox="0 0 16 20" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="eSIM/SIM-cards---Full-" transform="translate(-36.000000, -428.000000)" fill="#00939A">
        <g id="Group-7" transform="translate(24.000000, 410.000000)">
          <g id="-↳-Glyph" transform="translate(8.000000, 16.000000)">
            <path
              d="M14,2 L20,8 L20,20 C20,21.1 19.1,22 18,22 L18,22 L6,22 C4.9,22 4,21.1 4,20 L4,20 L4,4 C4,2.9 4.9,2 6,2 L6,2 L14,2 Z M12.078,9.404 C11.502,9.404 10.988,9.534 10.536,9.794 C10.084,10.054 9.732,10.42 9.48,10.892 C9.228,11.364 9.102,11.904 9.102,12.512 C9.102,13.128 9.228,13.674 9.48,14.15 C9.732,14.626 10.088,14.998 10.548,15.266 C11.008,15.534 11.534,15.668 12.126,15.668 C12.846,15.668 13.412,15.516 13.824,15.212 C14.1673333,14.9586667 14.4509444,14.6608889 14.6748333,14.3186667 L14.802,14.108 L13.71,13.52 C13.566,13.824 13.372,14.068 13.128,14.252 C12.884,14.436 12.558,14.528 12.15,14.528 C11.678,14.528 11.284,14.38 10.968,14.084 C10.6915,13.825 10.5267813,13.50475 10.4738438,13.12325 L10.458,12.956 L14.946,12.956 L14.946,12.416 C14.946,11.816 14.826,11.288 14.586,10.832 C14.346,10.376 14.01,10.024 13.578,9.776 C13.146,9.528 12.646,9.404 12.078,9.404 Z M12.066,10.544 C12.506,10.544 12.862,10.67 13.134,10.922 C13.406,11.174 13.562,11.512 13.602,11.936 L10.47,11.936 C10.526,11.512 10.694,11.174 10.974,10.922 C11.254,10.67 11.618,10.544 12.066,10.544 Z"
              id="-↳-Color-Copy"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
