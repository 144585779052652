import React from 'react'
import { Icon } from '../types'

export const LogoIcon = (props: Icon) => <WavelyIcon className={props.className} />

const WavelyIcon = ({ className }: Icon) => (
  <svg className={className} viewBox="0 0 107 20">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-98.000000, -38.000000)" fill="#29BBB0">
        <g transform="translate(-1.000000, -1.480069)">
          <g transform="translate(1.000000, 1.480069)">
            <g transform="translate(81.000000, 39.000000)">
              <g transform="translate(17.875000, -1.000000)">
                <path d="M105.758047,0 L106,2.19513434 L105.758047,2.19513434 L105.516094,0.731710036 L105.032047,2.19513434 L104.790094,2.19513434 L104.063955,0.731710036 L103.822001,2.19513434 L103.580048,2.19513434 L103.822001,0 L104.063955,0 C104.063955,0 104.063955,0 104.306048,0 L104.790094,1.46342289 L105.274,0 C105.274,0 105.516094,0 105.758047,0 Z" />
                <polygon points="103.337675 0 103.337675 0.243903816 102.611675 0.243903816 102.611675 2.19513434 102.369722 2.19513434 102.369722 0.243903816 101.643583 0.243903816 101.643583 0" />
                <path
                  d="M37.5115693,13.6585713 L37.7536625,14.8780763 L38.9637085,19.0244694 C39.2056616,19.5123053 39.2056616,19.5123053 39.4477548,19.5123053 L42.1097998,19.5123053 C42.351753,19.5123053 42.351753,19.2683168 42.351753,19.0244694 L37.9956157,2.92683168 C37.5115693,1.46342289 37.2696162,0.731710036 36.7855698,0.48780622 C36.3015234,0.243903816 35.3335707,0 34.1235248,0 C32.6715257,0 31.9453861,0.243903816 31.4614798,0.48780622 C30.9774334,0.731710036 30.7353402,1.70732671 30.2514339,2.92683168 L25.6532034,19.0244694 C25.6532034,19.2683168 25.6532034,19.5123053 25.8951566,19.5123053 L28.5573416,19.5123053 C28.7992948,19.5123053 28.7992948,19.5123053 29.041388,19.0244694 L29.7673875,16.3414428 L28.5573416,16.3414428 C28.3152484,16.3414428 28.0732952,16.3414428 28.3152484,15.853748 C28.3152484,15.6097595 28.5573416,15.3659122 28.7992948,15.3659122 L30.2514339,15.1219237 L37.5115693,13.6585713 Z M30.7353402,12.4390523 L33.1554321,3.90244694 C33.3975253,2.92683168 33.3975253,2.92683168 34.1235248,2.92683168 C34.8495243,2.92683168 34.8495243,2.92683168 35.0914775,3.90244694 L36.5436166,9.26831678 L36.5436166,9.75612441 L38.2377089,9.75612441 C38.4796621,9.75612441 38.7216153,9.75612441 38.4796621,10.243932 C38.4796621,10.4878359 38.2377089,10.7317397 37.9956157,10.7317397 L36.5436166,10.9756435 L30.7353402,12.4390523 Z"
                  fillRule="nonzero"
                />
                <path
                  d="M25.8948765,0.243903816 C26.1369697,0.243903816 26.3789229,0.243903816 26.1369697,0.731710036 L22.7487852,16.5854312 C22.2647388,19.2683168 21.7808325,19.7561526 19.3607406,19.7561526 C16.4566024,19.7561526 16.2145092,19.5123053 15.4885097,16.8292786 L13.0684599,6.3414851 L10.648368,16.8292786 C9.9223545,19.2683168 9.68034532,19.7561526 6.77623513,19.7561526 C4.35615731,19.7561526 3.87213894,19.5123053 3.38812058,16.8292786 L0,0.48780622 C0,0.243903816 0,0 0.242008202,0 L2.90410221,0 C3.14611139,0 3.14611139,0 3.14611139,0.48780622 L6.05020758,15.3659122 C6.29221676,16.3414428 6.29221676,16.3414428 6.77623513,16.3414428 C7.2602535,16.3414428 7.50226268,16.0975954 7.74427186,15.3659122 L11.1323864,1.21951485 C11.1323864,0.975612441 11.3743956,0.731710036 11.6164048,0.731710036 L14.0365106,0.731710036 C14.2784638,0.731710036 14.520557,0.731710036 14.520557,1.21951485 L17.9086015,15.3659122 C18.1506947,16.3414428 18.1506947,16.3414428 18.8766943,16.3414428 C19.3607406,16.3414428 19.3607406,16.0975954 19.6026938,15.3659122 L22.506832,0.48780622 C22.506832,0.243903816 22.7487852,0 22.7487852,0 L25.8948765,0 L25.8948765,0.243903816 Z"
                  fillRule="nonzero"
                />
                <path
                  d="M44.2877985,0.243903816 C44.5298916,0.243903816 44.5298916,0.243903816 44.5298916,0.731710036 L48.6440758,16.0975954 C48.8860289,17.0732671 48.8860289,17.0732671 49.3700753,17.0732671 C49.8541217,17.0732671 50.0960748,16.8292786 50.338028,16.0975954 L54.210259,0.731710036 C54.210259,0.48780622 54.210259,0.243903816 54.4522121,0.243903816 L57.1142571,0.243903816 C57.3563503,0.243903816 57.5983035,0.243903816 57.3563503,0.731710036 L53.2421662,16.5854312 C52.5161667,19.2683168 52.2740735,19.5123053 49.3700753,19.5123053 C46.7078903,19.5123053 46.4659371,19.2683168 45.7399376,16.5854312 L41.3838003,0.48780622 C41.3838003,0.243903816 41.3838003,0 41.6257535,0 L44.2877985,0 L44.2877985,0.243903816 Z"
                  fillRule="nonzero"
                />
                <path
                  d="M71.6346681,0.243843136 C71.8767613,0.243843136 72.1187144,0.487746952 72.1187144,0.731649357 L72.1187144,2.68287142 C72.1187144,2.92677523 71.8767613,3.17067905 71.6346681,3.17067905 L62.196394,3.17067905 L62.196394,8.7804527 L70.6667153,8.7804527 C70.9086685,8.7804527 71.1506217,9.02435651 71.1506217,9.26826033 L71.1506217,11.2194767 C71.1506217,11.4633806 70.9086685,11.7072844 70.6667153,11.7072844 L62.196394,11.7072844 L62.196394,17.073126 L71.3927149,17.073126 C71.6346681,17.073126 71.8767613,17.3171145 71.8767613,17.5609618 L71.8767613,19.5121641 C71.8767613,19.7561526 71.6346681,20 71.3927149,20 L59.7763021,20 C59.2922558,20 58.8082094,19.5121641 58.8082094,19.0243283 L58.8082094,1.21945558 C58.8082094,0.731649357 59.2922558,0.243843136 59.7763021,0.243843136 L71.6346681,0.243843136 Z"
                  fillRule="nonzero"
                />
                <path
                  d="M77.9269908,0.243903816 C78.169084,0.243903816 78.4110372,0.48780622 78.4110372,0.731710036 L78.4110372,16.8292786 L87.1233118,16.8292786 C87.365405,16.8292786 87.6073581,17.0732671 87.6073581,17.3171145 L87.6073581,19.5123053 C87.365405,19.2683168 87.365405,19.5123053 87.1233118,19.5123053 L76.2330386,19.5123053 C75.7489922,19.5123053 75.2649458,19.0244694 75.2649458,18.5366336 L75.2649458,0.48780622 C75.2649458,0.243903816 75.506899,0 75.7489922,0 L77.9269908,0 L77.9269908,0.243903816 Z"
                  fillRule="nonzero"
                />
                <path
                  d="M100.191444,0.243903816 C100.433397,0.243903816 100.67535,0.48780622 100.433397,0.731710036 L93.6571676,13.4146675 L93.6571676,19.0244694 C93.6571676,19.5123053 93.4152145,19.5123053 93.1731213,19.5123053 L90.7530294,19.5123053 C90.5110763,19.5123053 90.2689831,19.2683168 90.2689831,19.0244694 L90.2689831,13.4146675 L83.492754,0.731710036 C83.2508008,0.243903816 83.492754,0.243903816 83.7348472,0.243903816 L86.3968922,0.243903816 C86.8809385,0.243903816 86.8809385,0.48780622 87.1228917,0.731710036 L91.2370758,9.02441296 C91.479029,9.51222059 92.2051685,9.51222059 92.6890749,9.02441296 L96.803259,0.48780622 C97.0452122,0.243903816 97.0452122,0 97.5292586,0 L100.191444,0 L100.191444,0.243903816 Z"
                  fillRule="nonzero"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
