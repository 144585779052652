import React from 'react'
import styled from 'styled-components'
import { ResponsivePadding } from './styles'

interface Props {
  children: React.ReactElement | null
}

const DialogContainer = styled.div`
  ${ResponsivePadding};
  padding-top: 3.125rem;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100%;
  z-index: 99999;
  background-color: #fff;
  transition: opacity 0.5s cubic-bezier(0.6, 0, 0.4, 1), transform 0.5s cubic-bezier(0.6, 0, 0.4, 1);
  overflow-x: auto;

  @media screen and (min-width: 1024px) {
    width: 42vw;
  }
`

const DialogBackground = styled.div`
  width: 0vw;
  height: 0vh;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s cubic-bezier(0.6, 0, 0.4, 1);
  position: fixed;
  z-index: 100000;
  opacity: 0;
  display: none;
  overflow-x: hidden;

  @media screen and (min-width: 1024px) {
    display: block;
  }
`

export const Dialog = ({ children }: Props) => (
  <DialogBackground css={children ? `width: 100vw; height: 100vh; display: block; opacity: 1;` : ``}>
    <DialogContainer
      css={`
        opacity: ${children ? '1' : '0'};
        transform: translate(0vw);
        @media screen and (min-width: 1024px) {
          transform: translate(${children ? '58vw' : '100vw'});
        }
      `}
    >
      {children}
    </DialogContainer>
  </DialogBackground>
)
