import React from 'react'
import styled from 'styled-components'
import { colors } from '../../theme'
import { defaultBorderRadius } from '../styles/defaultBorderRadius'
import { ButtonText } from './ButtonText'
import { StyledBaseButton } from './StyledBaseButton'

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  loading?: boolean
  text: string | JSX.Element
}

interface IconProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  icon: JSX.Element
}

export const StyledButton = styled(StyledBaseButton) <{ isloading?: boolean }>`
  min-height: 2.5625rem;
  border-radius: ${defaultBorderRadius};
  padding: 1rem 1.5rem;
  color: #fff;
  background-color: #00b7ae;
  vertical-align: middle;
  box-shadow: 0 3px 20px 0 rgba(0, 183, 174, 0.4);

  /* :hover {
    box-shadow: 0 3px 20px 0 rgba(0, 183, 174, 0.4);
    background-color: #00b7ae;
  } */

  :disabled {
    background-color: #ebeef8;
    color: #a2a8c1;
    box-shadow: none;
    opacity: 1;
  }

  /* :disabled:hover {
      box-shadow: none;
      background-color: #ebeef8;
  } */
  
`

export const WavelyButton = (props: Props) => {
  const { text, loading, disabled, ref, ...rest } = props

  return (
    <StyledButton as="button" isloading={loading} disabled={disabled || loading} {...rest}>
      <ButtonText isLoading={loading} text={text} />
    </StyledButton>
  )
}

export const WavelyIconButton = (props: IconProps) => {
  const { icon, disabled, ref, ...rest } = props

  return (
    <StyledBaseButton
      css={`
        background-color: transparent;
      `}
      as="button"
      disabled={disabled}
      {...rest}
    >
      {icon}
    </StyledBaseButton>
  )
}

export const WavelyCancelButton = styled(WavelyButton)`
  background-color: #fff;
  border: 0.0625rem solid #e6e9ea;
  color: ${colors.hydraGreen};
  &:hover {
    opacity: 1;
    background-color: #e6e9ea;
  }
`

export const WavelyTextButton = styled(WavelyButton)`
  background-color: transparent;
  font-size: 0.875rem;
  padding: 0.3125rem 0;
  :disabled {
    color: ${colors.greyText};
    background-color: transparent;
    border: transparent;
  }
`

export const WavelyGreenButton = styled(WavelyButton)`
  background-color: ${colors.hydraGreen};
`

export const WavelyWarningButton = styled(WavelyButton)`
  background-color: ${colors.warningRed};
`

export const WavelyErrorButton = styled(WavelyButton)`
  background-color: ${colors.errorRed};
`

export const WavelyGreenTextButton = styled(WavelyTextButton)`
  color: ${colors.hydraGreen};
`

export const WavelyWarningTextButton = styled(WavelyTextButton)`
  color: ${colors.warningRed};
`
