import React from 'react'
import { IconWithColor } from '../types'

export const AccountIcon = (props: IconWithColor) => (
  <svg className={props.className} width="22px" height="24px" viewBox="0 0 22 24" version="1.1">
    <g id="Home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icons/_glyphs/user-color">
        <path
          css={`
            fill: transparent !important;
          `}
          d="M12,13 C9.243,13 7,10.309 7,7 C7,3.2 7.818,1 12,1 C16.182,1 17,3.2 17,7 C17,10.309 14.757,13 12,13"
          fill="#F7F8F8"
        />
        <path
          d="M23,17.9404 L23,22.0004 C23,22.0004 19,24.0004 12,24.0004 C5,24.0004 1,22.0004 1,22.0004 L1,17.9404 C1,15.2054 3.044,12.9244 5.739,12.5864 C7.206,14.6624 9.465,16.0004 12,16.0004 C14.535,16.0004 16.794,14.6624 18.261,12.5864 C20.956,12.9244 23,15.2054 23,17.9404 M12,12.0004 C9.794,12.0004 8,9.7564 8,7.0004 C8,3.1214 12,4.0004 13,2.0004 C15,2.0004 16,3.1214 16,7.0004 C16,9.7564 14.206,12.0004 12,12.0004 M12,0.0004 C6.882,0.0004 6,3.1334 6,7.0004 C6,10.8664 8.687,14.0004 12,14.0004 C15.313,14.0004 18,10.8664 18,7.0004 C18,3.1334 17.118,0.0004 12,0.0004"
          fill={props.color}
        />
      </g>
    </g>
  </svg>
)

export const WavelyAccountIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15" fill="#F9DDE6" />
    <path
      d="M19 10.7143C19 11.8509 18.5786 12.941 17.8284 13.7447C17.0783 14.5485 16.0609 15 15 15C13.9391 15 12.9217 14.5485 12.1716 13.7447C11.4214 12.941 11 11.8509 11 10.7143V9.28571C11 8.14907 11.4214 7.05898 12.1716 6.25526C12.9217 5.45153 13.9391 5 15 5C16.0609 5 17.0783 5.45153 17.8284 6.25526C18.5786 7.05898 19 8.14907 19 9.28571V10.7143Z"
      fill="#F5657F"
    />
    <path
      d="M19 10C19 10 15 9.66667 12.6667 8C11.3333 10 11 10 11 10"
      stroke="#F9DDE6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8 21C8 18.2386 10.2386 16 13 16H18C20.7614 16 23 18.2386 23 21H8Z" fill="#F5657F" />
  </svg>
)
