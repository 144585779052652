import { fold, left, right } from 'fp-ts/es6/Either'
import { chain } from 'fp-ts/es6/TaskEither'
import { pipe } from 'fp-ts/lib/function'
import { urls } from '../urls'
import { createUrlWithHost } from '../utils/url-utils'
import {
  AccountProfile,
  GetAccountProfileRequest,
  JwtTokenResponse,
  JwtTokenSuccessResponse,
  PopulateRegistrationFormResponse,
  PopulateRegistrationFormSuccessResponse,
  PostEmailRegisterWithJsonRequest,
  PostJwtRefreshTokenRequest,
  PostPopulateDisplayNameResponse,
  PostPopulateDisplayNameSuccessResponse,
  PostRegisterWithJsonResponse,
  PostSendMagicLinkEmailRequest,
  PostSendMagicLinkEmailResponse,
  PostSoMeRegisterWithJsonRequest,
  PostValidateMagicLinkJsonResponse,
  PostValidateMagicLinkRequest,
  PostValidateMagicLinkResponse,
  ProfileResponse,
  PutAccountProfileRequest,
  RegisterAccountRequest,
  ReSendPinTokenJsonResponse,
  ReSendPinTokenRequest,
  ReSendPinTokenResponse
} from './api-types'
import { externalApi, formatAuthorizationHeader, onApiJsonValidationError, onAuthenticationError } from './api-utils'

export const getPopulateRegistrationForm =
  (controller: AbortController) =>
    async (token: string): Promise<PopulateRegistrationFormResponse> => {
      const url = `/auth/populateRegistrationForm/${token}`
      return externalApi({ apiType: 'auth', doNotRetryStatusCodes: [] })
        .signal(controller)
        .url(url)
        .get()
        .json((json) =>
          pipe(
            PopulateRegistrationFormSuccessResponse.decode(json),
            fold(onApiJsonValidationError, (data) => right(data))
          )
        )
    }

export const postRegisterWithJsonForSoMe =
  (controller: AbortController) =>
    async (body: PostSoMeRegisterWithJsonRequest): Promise<PostRegisterWithJsonResponse> => {
      const url = `/auth/registerWithJson`

      return externalApi({ apiType: 'auth', doNotRetryStatusCodes: [] })
        .signal(controller)
        .url(url)
        .post(body)
        .json((json) =>
          pipe(
            JwtTokenSuccessResponse.decode(json),
            fold(onApiJsonValidationError, (data) => right({ type: 'some', jwt: data }))
          )
        )
    }

export const postRegisterWithJsonForEmail =
  (controller: AbortController) =>
    async (body: PostEmailRegisterWithJsonRequest): Promise<PostRegisterWithJsonResponse> => {
      const url = `/auth/registerWithCode`

      return externalApi({ apiType: 'auth', doNotRetryStatusCodes: [] })
        .signal(controller)
        .url(url)
        .post(body)
        .text((_text) => right({ type: 'email', code: JSON.parse(_text), jwt: 'jwt' }))
    }

export const postValidateMagicLink =
  (controller: AbortController) =>
    (requestData: PostValidateMagicLinkRequest): Promise<PostValidateMagicLinkJsonResponse> => {
      const url = `/MagicLink/validateMagicLink`

      return externalApi({ apiType: 'auth', doNotRetryStatusCodes: [412, 429, 500] })
        .signal(controller)
        .headers({ 'x-api-key': requestData.apiKey })
        .url(url)
        .post({
          magicLinkResendToken: requestData.magicLinkResendToken,
          magicLinkValidationCode: requestData.magicLinkValidationCode,
        })
        .json((json) =>
          pipe(
            PostValidateMagicLinkResponse.decode(json),
            fold(onApiJsonValidationError, (data) => right(data))
          )
        )
    }

export const putResendMagicLink =
  (controller: AbortController) =>
    (requestData: ReSendPinTokenRequest): Promise<ReSendPinTokenJsonResponse> => {
      const url = `/MagicLink/resendMagicLinkEmail`

      return externalApi({ apiType: 'auth', doNotRetryStatusCodes: [412, 429] })
        .signal(controller)
        .headers({ 'x-api-key': requestData.apiKey })
        .url(url)
        .put({ token: requestData.token, email: requestData.email })
        .json((json) =>
          pipe(
            ReSendPinTokenResponse.decode({ code: json }),
            fold(onApiJsonValidationError, (data) => right(data))
          )
        )
    }

export const registerAccountWithSoMeApi =
  (singleUseToken: string) =>
    (controller: AbortController) =>
      ({
        firstName,
        lastName,
        country,
        marketingConsent,
      }: RegisterAccountRequest): Promise<PostRegisterWithJsonResponse> => {
        const first = () => getPopulateRegistrationForm(controller)(singleUseToken)
        const userAgent = navigator.userAgent
        const referer = document.referrer
        const refererExists = !!referer && referer !== ''
        const second =
          ({ token, displayName, email }: PopulateRegistrationFormSuccessResponse) =>
            () =>
              postRegisterWithJsonForSoMe(controller)({
                token,
                displayName,
                branding: 'wavely',
                email,
                returnJwt: true,
                termsAccepted: true,
                marketingConsent,
                country,
                firstName,
                lastName,
                loginData: refererExists
                  ? {
                    program: 'Hydra',
                    userAgent,
                    referer,
                  }
                  : {
                    program: 'Hydra',
                    userAgent,
                  },
              })

        return pipe(first, chain(second))()
      }

export const registerAccountWithEmailApi =
  (email: string) =>
    (controller: AbortController) =>
      ({
        firstName,
        lastName,
        country,
        marketingConsent,
      }: RegisterAccountRequest): Promise<PostRegisterWithJsonResponse> => {
        const first = () => postPopulateDisplayName(controller)({ email })
        const userAgent = navigator.userAgent
        const referer = document.referrer
        const refererExists = !!referer && referer !== ''

        const second =
          ({ displayName }: PostPopulateDisplayNameSuccessResponse) =>
            () =>
              postRegisterWithJsonForEmail(controller)({
                displayName,
                branding: 'wavely',
                email,
                returnJwt: true,
                termsAccepted: true,
                marketingConsent,
                showPinCode: true,
                provider: 'Email',
                redirectUri: createUrlWithHost(urls.registeredUserRedirect),
                country,
                firstName,
                lastName,
                loginData: refererExists
                  ? {
                    program: 'Hydra',
                    userAgent,
                    referer,
                  }
                  : {
                    program: 'Hydra',
                    userAgent,
                  },
              })
        return pipe(first, chain(second))()
      }

export const getJwtToken =
  (controller: AbortController) =>
    async (token: string): Promise<JwtTokenResponse> => {
      const url = `/auth/token2/${token}`
      return externalApi({ apiType: 'auth', doNotRetryStatusCodes: [409] })
        .signal(controller)
        .url(url)
        .get()
        .error(409, onAuthenticationError)
        .json((json) =>
          pipe(
            JwtTokenSuccessResponse.decode(json),
            fold(onApiJsonValidationError, (data) => right(data))
          )
        )
    }

export const postJwtRefreshToken =
  () =>
    async (requestData: PostJwtRefreshTokenRequest): Promise<JwtTokenResponse> => {
      const url = `/auth/refresh2`

      return externalApi({ apiType: 'auth', doNotRetryStatusCodes: [] }).url(url).post(requestData)
    }

export const postSendMagicLinkEmail =
  (controller: AbortController) =>
    (requestData: PostSendMagicLinkEmailRequest): Promise<PostSendMagicLinkEmailResponse> => {
      const url = `/AuthBackend/sendMagicLinkEmail`

      return (
        externalApi({ apiType: 'auth', doNotRetryStatusCodes: [412] })
          .signal(controller)
          .headers({ 'x-api-key': requestData.apiKey })
          .url(url)
          .post(requestData.body)
          // "Login with no account existing, email foo.bar@reaktor.fi"
          .error(412, () => left({ type: 'NO_ACCOUNT' }))
          .text((_text) => right({ type: 'email', code: JSON.parse(_text) }))
      )
    }

export const postPopulateDisplayName =
  (controller: AbortController) =>
    (body: { email: string }): Promise<PostPopulateDisplayNameResponse> => {
      const url = `/auth/populateDisplayName`

      return externalApi({ apiType: 'auth', doNotRetryStatusCodes: [] })
        .signal(controller)
        .url(url)
        .post(body)
        .json((json) =>
          pipe(
            PostPopulateDisplayNameSuccessResponse.decode(json),
            fold(onApiJsonValidationError, (data) => right(data))
          )
        )
    }

export const getAccountProfile =
  (controller: AbortController) =>
    async ({ jwtToken }: GetAccountProfileRequest): Promise<ProfileResponse> => {
      const url = `/Profile/partial`

      return externalApi({ apiType: 'hydra', doNotRetryStatusCodes: [] })
        .signal(controller)
        .auth(formatAuthorizationHeader(jwtToken))
        .url(url)
        .get()
        .json((json) =>
          pipe(
            AccountProfile.decode(json),
            fold(onApiJsonValidationError, (data) => right(data))
          )
        )
    }

export const putAccountProfile =
  (controller: AbortController) =>
    async ({ body, jwtToken }: PutAccountProfileRequest): Promise<ProfileResponse> => {
      const url = `/Profile/partial`

      return externalApi({ apiType: 'hydra', doNotRetryStatusCodes: [] })
        .signal(controller)
        .auth(formatAuthorizationHeader(jwtToken))
        .url(url)
        .put(body)
        .json((json) =>
          pipe(
            AccountProfile.decode(json),
            fold(onApiJsonValidationError, (data) => right(data))
          )
        )
    }
