import styled from 'styled-components'

export const StyledBaseButton = styled.div`
  -webkit-tap-highlight-color: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 1.125rem;
  text-decoration: none;
  transition: 0.3s;
  &:hover:not(:disabled) {
    opacity: 0.7;
  }
  :disabled {
    cursor: default;
  }
`
