import luhn from 'luhn'

export const shortIccNumberLength = 8
export const iccNumberTestLength = 21
export const iccNumberLength = 20

export const matchesShortIccNumberFormat = (input: string): boolean => input?.length === shortIccNumberLength
export const matchesIccNumberFormat = (input: string): boolean => input?.length === iccNumberLength
export const matchesIccNumberTestFormat = (input: string): boolean => input?.length === iccNumberTestLength

export const isValidIccNumber = (input: string, iccPrefixes: string[]) =>
  input && listValidIccNumbers(input, iccPrefixes).length !== 0

export const listValidIccNumbers = (input: string, iccPrefixes: string[]): string[] => {
  if (matchesShortIccNumberFormat(input)) {
    return iccPrefixes.map((prefix) => `${prefix}${input}`).filter((x) => matchesIccNumberFormat(x) && luhn.validate(x))
  }
  if (matchesIccNumberTestFormat(input)) {
    return [input]
  }

  // && luhn.validate(input) - test for valid icc number (disable for now)
  if (matchesIccNumberFormat(input)) {
    return [input]
  }
  return []
}
