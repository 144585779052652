import { DateTime } from 'luxon'

enum Weekday {
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday,
}

const freshchatOpeningTimes = { weekdayStart: Weekday.monday, weekdayEnd: Weekday.saturday, open: 10, close: 19 }

export const humanReadableOpeningTimes = () => {
  const rezoned = DateTime.local().setZone('US/Central')
  return `Mon-Sat 10-19 ${rezoned.setLocale('en-us').toFormat('ZZZZ')}`
}

export const isFreshchatOpen = (now: DateTime) => {
  const rezoned = now.setZone('US/Central')
  if (rezoned.weekday >= freshchatOpeningTimes.weekdayStart && rezoned.weekday <= freshchatOpeningTimes.weekdayEnd) {
    const open = DateTime.utc(now.year, now.month, now.day, freshchatOpeningTimes.open, 0).setZone('US/Central', {
      keepLocalTime: true,
    })
    const close = DateTime.utc(now.year, now.month, now.day, freshchatOpeningTimes.close, 0).setZone('US/Central', {
      keepLocalTime: true,
    })
    return rezoned >= open && rezoned <= close
  }
  return false
}
