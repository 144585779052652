import { useState, useEffect } from 'react'
import { useLocation } from 'react-router'

export const useDialog = () => {
  const [dialog, setDialog] = useState<null | React.ReactElement>(null)
  const [location, setLocation] = useState('')

  const { pathname } = useLocation()

  useEffect(() => {
    if (location !== pathname) {
      setLocation(pathname)
      setDialog(null)
    }
  }, [location, pathname])

  return { dialog, setDialog }
}
