import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { OnUpdateAccept } from '../../types'
import { useErrorContext } from '../../context/ErrorContext'
import { SuccessToast } from './Toast'

interface Props {
  onAccept: OnUpdateAccept
  onDiscard: () => void
}

const Text = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`

export const UpdateAvailableTopBar = ({ onAccept, onDiscard }: Props) => {
  const { onError } = useErrorContext()
  const { t } = useTranslation()
  return (
    <SuccessToast
      onClose={onDiscard}
      message={
        <Text
          onClick={() => {
            onAccept().catch(onError)
          }}
        >
          {t('updateAvailableTopBar.text')}
        </Text>
      }
    />
  )
}
