import React, { useContext } from 'react'

export interface DialogContext {
  setDialog: (children: null | React.ReactElement) => void
  setToast: (children: null | React.ReactElement) => void
  setPopup: (children: null | React.ReactElement) => void
}

export const DialogContext = React.createContext<null | DialogContext>(null)

export const useDialogContext = () => {
  const context = useContext(DialogContext)
  if (!context) {
    throw new Error('DialogContext is not available, Component needs to be child of DialogContext provider')
  }
  return context
}
