import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { formatPageTitle } from '../../formatPageTitle'
import { colors } from '../../theme'
import { urls } from '../../urls'
import { createUrlWithHost } from '../../utils/url-utils'
import { Loading } from '../Loading/Loading'
import { SignInWrapper } from './SignInWrapper'
import { WavelyEmailLogin } from './WavelyEmailLogin'

export const createSignInUrl = (type: 'Facebook' | 'Google' | 'Apple') =>
  `${process.env.AUTH_API_BASE_URL}/auth/signin/${type}?redirectUri=${createUrlWithHost(
    urls.registeredUserRedirect
  )}&regUrl=${createUrlWithHost(urls.createAccountWithSome.clientUrl())}&program=Hydra`

export const LoginLink = styled.a`
  transition: 0.3s;
  padding: 0 1.25rem;
  border: 0.125rem solid rgb(230, 233, 234);
  border-radius: 0.5rem;
  color: ${colors.hydraGreen};
  background-color: #fff;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  svg {
    margin-left: auto;
  }
  &:hover {
    opacity: 0.7;
  }
`

export const WavelyLoginLink = styled.a`
  transition: 0.3s;
  padding: 0 1.25rem;
  border-radius: 10px;
  box-shadow: 0 3px 20px 0 rgba(196, 205, 232, 0.4);
  background-color: #ffffff;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-decoration: none;
  letter-spacing: normal;
  text-align: left;
  color: #19224c;

  svg {
    margin-left: auto;
  }
  &:hover {
    opacity: 0.7;
  }
`

export const ButtonContainer = styled.div`
  margin: 1.25rem 0;
`

export const BottomTerms = styled.div`
  text-align: center;
  color: #acabac;
  font-size: 0.875rem;
`

const WavelyTitle = styled.div`
  color: #19224c;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
`

interface Props {
  showEmailField: boolean
}

export const SignIn = ({ showEmailField }: Props) => {
  const { t } = useTranslation()
  const [isLoading] = useState(false)

  useEffect(() => {
    document.title = formatPageTitle('Sign in')
  }, [])

  return (
    <SignInWrapper>
      <>
        <WavelyTitle>{t('signinPage.title')} </WavelyTitle>
        {isLoading ? (
          <Loading
            css={`
              margin-top: 1.25rem;
            `}
            color={colors.hydraGreen}
          />
        ) : (
          <>
            <div
              css={`
                font-size: 13px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.54;
                letter-spacing: normal;
                text-align: left;
                color: #7d88af;
              `}
            >
              {t('signinPage.continueActivateSim')}
            </div>
            <ButtonContainer>{showEmailField && <WavelyEmailLogin />}</ButtonContainer>
          </>
        )}
      </>
    </SignInWrapper>
  )
}
