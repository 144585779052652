import React from 'react'
import { Page } from '../common/Page'
import { LogoIcon } from '../../icons/LogoIcon'
import { Loading } from './Loading'

export const LoadingPage = () => (
  <Page footer={true} menu={false} centered={true}>
    <div
      css={`
        text-align: center;
        margin: auto;
        font-size: 1.25rem;
        width: 9.375rem;
      `}
    >
      <LogoIcon />
      <Loading color={'#000'} />
    </div>
  </Page>
)
