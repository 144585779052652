import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { DialogContext } from '../../context/DialogContext'
import { AccountIcon } from '../../icons/AccountIcon'
import { DataPlansIcon } from '../../icons/DataPlansIcon'
import { HomeIcon } from '../../icons/HomeIcon'
import { LifePreserverIcon } from '../../icons/LifePreserver'
import { LogoIcon } from '../../icons/LogoIcon'
import { SimIcon } from '../../icons/SimIcon'
import {
  MENU_ACCOUNT_ID,
  MENU_BUTTON_ID,
  MENU_DATA_PLANS_ID,
  MENU_HOME_ID,
  MENU_SIM_CARDS_ID,
} from '../../test-selectors'
import { colors, containerWidths } from '../../theme'
import { urls } from '../../urls'
import { MenuIcon } from './MenuIcon'

const Logo = styled(LogoIcon)``

export const desktopMenuWidth = '19rem'
const mobileMenuSize = '3.4375rem'

const MenuItemText = styled.div`
  padding-left: 0.625rem;
`

const MenuContent = styled.nav<{ isVisible: boolean }>`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  background-color: #fff;
  position: relative;
  width: 100%;
  ${Logo} {
    display: none;
  }

  @media screen and (min-width: ${containerWidths.lg}px) {
    padding: 4.375rem 0 0 0;
    display: flex;
    top: 0;
    position: fixed;
    height: 100%;
    width: ${desktopMenuWidth};
    ${Logo} {
      display: block;
    }
  }
`

const MenuButton = styled.div`
  -webkit-tap-highlight-color: transparent;
  position: sticky;
  top: 0;
  color: ${colors.hydraGreen};
  cursor: pointer;
  width: ${mobileMenuSize};
  height: ${mobileMenuSize};
  padding: 0.8125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  outline: none;
  margin-right: 0.625rem;
  &:hover {
    opacity: 0.7;
  }

  svg {
    width: 100%;
    height: 100%;
  }
  @media screen and (min-width: ${containerWidths.lg}px) {
    display: none;
  }
`

const menuInactiveColor = '#71828a'
const menuActiveColor = colors.hydraGreen
const MenuItem = styled.div`
  margin: 0.625rem 0;
  color: ${menuInactiveColor};
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.3125rem;
    * {
      fill: ${menuInactiveColor};
    }
  }

  &:hover {
    opacity: 0.7;
  }
`

const MenuLinkStyle = css`
  text-decoration: none;
  display: flex;
  align-items: center;
  color: ${menuInactiveColor};

  padding: 0.3125rem 0 0.3125rem 0.625rem;
  @media screen and (min-width: ${containerWidths.lg}px) {
    padding: 0.625rem 0 0.625rem 1.25rem;
  }

  &.active {
    color: ${menuActiveColor};
    border-left: 0.25rem solid ${menuActiveColor};
    svg {
      * {
        fill: ${menuActiveColor};
      }
    }

    padding: 0.3125rem 0 0.3125rem 0.375rem;
    @media screen and (min-width: ${containerWidths.lg}px) {
      padding: 0.625rem 0 0.625rem 1.625rem;
    }
  }
`

const MenuLink = styled(NavLink)`
  ${MenuLinkStyle};
`

export const Menu = () => {
  const { t } = useTranslation()
  const [menuIsVisible, setMenuIsVisible] = useState(false)
  const dialogContext = useContext(DialogContext)

  const routesForAllUsers = dialogContext
    ? [
        <MenuItem key="support">
          <MenuLink to={urls.support.index.clientUrl()}>
            <LifePreserverIcon color={'#000'} />
            <MenuItemText>{t('menu.support')}</MenuItemText>
          </MenuLink>
        </MenuItem>,
      ]
    : []

  const routesForCompleteUsers = [
    <MenuItem key="home">
      <MenuLink id={MENU_HOME_ID} to={urls.home}>
        <HomeIcon color={'#000'} />
        <MenuItemText>{t('menu.home')}</MenuItemText>
      </MenuLink>
    </MenuItem>,

    <MenuItem key="user-packages">
      <MenuLink id={MENU_DATA_PLANS_ID} to={urls.userPackages.clientUrl()}>
        <DataPlansIcon color={'#000'} />
        <MenuItemText>{t('menu.dataPlans')}</MenuItemText>
      </MenuLink>
    </MenuItem>,

    <MenuItem key="sim-cards">
      <MenuLink id={MENU_SIM_CARDS_ID} to={urls.simList}>
        <SimIcon color={'#000'} />
        <MenuItemText>{t('menu.simCards')}</MenuItemText>
      </MenuLink>
    </MenuItem>,
    <MenuItem key="account">
      <MenuLink id={MENU_ACCOUNT_ID} to={urls.account}>
        <AccountIcon color={'#000'} />
        <MenuItemText>{t('menu.account')}</MenuItemText>
      </MenuLink>
    </MenuItem>,
  ]

  return (
    <nav
      css={`
        position: sticky;
        top: 0;
        z-index: 9999;
        background: #fff;

        @media screen and (min-width: ${containerWidths.lg}px) {
          width: ${desktopMenuWidth};
        }
      `}
    >
      <div
        css={`
          display: flex;
          align-items: center;
          height: ${mobileMenuSize};
        `}
      >
        <MenuButton id={MENU_BUTTON_ID} onClick={() => setMenuIsVisible(!menuIsVisible)}>
          <MenuIcon color={colors.hydraGreen} />
        </MenuButton>
        <Link
          css={`
            display: flex;
            text-decoration: none;
          `}
          to={urls.home}
        >
          <LogoIcon
            css={`
              width: 6.875rem;
              height: 100%;
              display: inline-block;
              @media screen and (min-width: ${containerWidths.lg}px) {
                display: none;
              }
            `}
          />
        </Link>
      </div>

      <MenuContent isVisible={menuIsVisible}>
        <div
          css={`
            border-bottom: 0.0625rem solid #f3f9fa;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 3.75rem;
            justify-content: center;
            align-items: center;

            display: none;
            @media screen and (min-width: ${containerWidths.lg}px) {
              display: flex;
            }
          `}
        >
          <Link
            css={`
              display: flex;
              text-decoration: none;
            `}
            to={urls.home}
          >
            <LogoIcon
              css={`
                height: 1.5625rem;
                width: 8.75rem;
              `}
            />
          </Link>
        </div>
        {routesForCompleteUsers.concat(routesForAllUsers)}
      </MenuContent>
    </nav>
  )
}
