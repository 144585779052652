import * as queryString from 'query-string'
import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router'
import { AppQueryLoader } from './AppQueryLoader'
import { AdaptiveWrapper } from './components/AdaptiveWrapper'
import { EmailCreateAccountRoute } from './components/CreateAccount/EmailCreateAccountRoute'
import { SoMeCreateAccountRoute } from './components/CreateAccount/SoMeCreateAccountRoute'
import { EsimInstructionsRoute } from './components/EsimInstructions/EsimInstructionsRoute'
import { PageNotFound } from './components/PageNotFound'
import { RegisteredUserRedirect } from './components/RegisteredUserRedirect'
import { EmailLoginMobile } from './components/SignIn/EmailLoginMobile'
import { EmailPinCodeLoginRoute } from './components/SignIn/EmailPinCodeLoginRoute'
import { SignIn } from './components/SignIn/SignIn'
import { SignInMobile } from './components/SignIn/SignInMobile'
import { RegisterSimCardRoute } from './components/Sim/RegisterSimCardRoute'
import { SupportRoutes } from './components/Support/SupportRoutes'
import { config } from './config'
import { useCountryListLoader } from './customHooks/useCountryListLoader'
import { persistIcc } from './storage-api'
import { urls } from './urls'

interface Props {
  jwtToken: string | null
  jwtRefreshToken: string | null
}

const redirectToSignIn = <Redirect to={urls.signIn} />

export const Routes = ({ jwtToken }: Props) => {
  const location = useLocation()
  const history = useHistory()

  // refresh & cache country data
  useCountryListLoader()

  useEffect(() => {
    const { icc } = queryString.parse(location.search)
    if (typeof icc === 'string') {
      persistIcc(icc)
    }
  }, [location.search])

  return (
    <Switch>
      <Route exact={true} path={urls.signIn}>
        {jwtToken ? (
          <Redirect to={urls.home} />
        ) : (
          <AdaptiveWrapper
            mobileView={<SignInMobile />}
            desktopView={<SignIn showEmailField={!!config.emailLoginApiKey} />}
            adaptiveEnabled={true}
          />
        )}
      </Route>

      <Route path={urls.initialRegisterSimCard}>
        {jwtToken ? (
          <RegisterSimCardRoute jwtToken={jwtToken} onSimAddedSuccess={() => history.push(urls.availablePackages.clientUrl())} />
        ) : (
          redirectToSignIn
        )}
      </Route>

      <Route path={urls.authenticatedUserBaseUrl}>
        {jwtToken ? <AppQueryLoader jwtToken={jwtToken} /> : redirectToSignIn}
      </Route>

      <Route path={urls.createAccountWithSome.url}>
        <SoMeCreateAccountRoute />
      </Route>

      <Route path={urls.createAccountWithEmail.url}>
        <EmailCreateAccountRoute />
      </Route>

      <Route path={urls.showMobileEmailLogin}>
        <EmailLoginMobile />
      </Route>

      <Route path={urls.support.root}>
        <SupportRoutes jwtToken={jwtToken} />
      </Route>

      <Route path={urls.registeredUserRedirect}>
        <RegisteredUserRedirect />
      </Route>

      <Route path={urls.enterEmailLoginPinCode.url}>
        <EmailPinCodeLoginRoute />
      </Route>

      <Route path={urls.esimInstructions}>
        <EsimInstructionsRoute />
      </Route>

      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  )
}
