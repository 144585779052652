import { fold } from 'fp-ts/es6/Either'
import { pipe } from 'fp-ts/lib/function'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { postSendMagicLinkEmail } from '../../api/api-auth'
import { PostSendMagicLinkEmailRequest } from '../../api/api-types'
import { config } from '../../config'
import { useErrorContext } from '../../context/ErrorContext'
import { useDataLoader } from '../../customHooks/useDataLoader'
import { exhaustiveCheck } from '../../exhaustive-switch-check'
import {
  FixedFooter,
  MobileBottomTermsLink,
  MobileContentBody,
  MobileContentWrapper,
  MobileNote,
  MobileSubtitle,
  MobileTitle,
  SignInToolbar
} from '../../MobileStyledComponents'
import { EMAIL_LOGIN_BUTTON_ID, EMAIL_LOGIN_INPUT_ID } from '../../test-selectors'
import { urls } from '../../urls'
import { createUrlWithHost } from '../../utils/url-utils'
import { WavelyButton } from '../Button/WavelyButton'

const EmailInput = styled.input`
  font-size: 14px;
  font-weight: 600;
  color: #000;
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  line-height: normal;

  /* chrome autofill styling fix */
  @keyframes autofill {
    0%,
    100% {
      color: #000;
      background: transparent;
    }
  }
  &:-webkit-autofill {
    animation-delay: 1s;
    animation-name: autofill;
    animation-fill-mode: both;
  }
`

const InputContainer = styled.div<{ active: boolean }>`
  display: flex;
  height: 55px;
  padding-left: 14px;
  border-radius: 10px;
  border: ${(props) => (props.active ? 'solid 1px #00b7ae' : 'solid 1px #ebeef8')};
  background-color: #ffffff;
`

export const EmailLoginMobile = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const history = useHistory()
  const { onError } = useErrorContext()
  const magicLinkApi = useDataLoader(postSendMagicLinkEmail)
  const [emailFieldFocus, setEmailFieldFocus] = useState(false)

  const emailIsInvalid = !validateEmail(email)

  function validateEmail(email: string) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const onEmailLogin = (event: React.FormEvent<HTMLFormElement | HTMLButtonElement>) => {
    event.preventDefault()
    const requestData: PostSendMagicLinkEmailRequest = {
      body: {
        email,
        branding: 'wavely',
        loginData: {
          uuid: null,
          program: 'Hydra',
          referer: null,
          userAgent: window.navigator.userAgent,
          ipAddress: null,
          representativeTribeId: null,
          mccMnc: null,
        },
        fcmToken: null,
        showPinCode: true,
        redirectUri: createUrlWithHost(urls.registeredUserRedirect),
      },
      apiKey: config.emailLoginApiKey,
    }
    magicLinkApi
      .query(requestData)
      .then((response) =>
        pipe(
          response,
          fold(
            (error) => {
              switch (error.type) {
                case 'FETCH_ERROR':
                case 'API_ERROR':
                  return onError(error, error.type)
                case 'ABORT_ERROR':
                case 'THROTTLE_ERROR':
                case 'WRONG_PIN_CODE':
                  return
                case 'AUTHENTICATION_EXPIRED_ERROR':
                  return onError(new Error('email magiclink login authentication renewal error'))
                case 'AUTHENTICATION_ERROR':
                  return onError(new Error('email magiclink login authentication error'))
                case 'NO_ACCOUNT':
                  return history.push(urls.createAccountWithEmail.clientUrl(email))
                default:
                  return exhaustiveCheck(error)
              }
            },
            (data) => history.push(urls.enterEmailLoginPinCode.clientUrl(email, data.code))
          )
        )
      )
      .catch(onError)
  }

  return (
    <form onSubmit={onEmailLogin}>
      <>
        <SignInToolbar />
        <MobileContentWrapper>
          <MobileContentBody>
            <MobileTitle css="margin-bottom: 17px;">Enter your email address</MobileTitle>
            <MobileSubtitle css="margin-bottom: 30px;">
              Enter your email address to receive<br />your one-time Access Code
            </MobileSubtitle>
            <div css="width: 100%;">
              <label
                css={`
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.5;
                letter-spacing: normal;
                text-align: left;
                color: #19224c;
              `}
              >
                Email
              </label>
              <InputContainer active={emailFieldFocus}>
                <div
                  css={`
                    line-height: 2.125rem;
                  `}
                />

                <EmailInput
                  css="
                        ::placeholder {
                            color: #cbcfdf;
                            opacity: 1; 
                          }
                          
                        "
                  id={EMAIL_LOGIN_INPUT_ID}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder={t('emailLogin.placeholder')}
                  name="email"
                  autoComplete="email"
                  onFocus={(_e) => setEmailFieldFocus(true)}
                  onBlur={(_e) => setEmailFieldFocus(false)}
                />
              </InputContainer>
            </div>
          </MobileContentBody>
        </MobileContentWrapper>
        <FixedFooter>
          <WavelyButton
            css={`
              width: 100%;
              margin-bottom: 26px;
            `}
            id={EMAIL_LOGIN_BUTTON_ID}
            type={'submit'}
            loading={magicLinkApi.isLoading}
            disabled={magicLinkApi.isLoading || emailIsInvalid}
            text={
              <div
                css={`
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  font-weight: 800;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.54;
                  letter-spacing: normal;
                  text-align: center;
                `}
              >
                Send Access Code
              </div>
            }
            onSubmit={onEmailLogin}
          />
          <MobileNote>
            <div>
              <Trans i18nKey="terms">
                {`By continuing, you agree to ` + `Wavely's `}
                <MobileBottomTermsLink href={config.serviceTermsUrl}>
                  <>Service Terms</>
                </MobileBottomTermsLink>
                ,{' '}
                <MobileBottomTermsLink href={config.privacyPolicyUrl}>
                  <>Privacy Policy</>
                </MobileBottomTermsLink>{' '}
                {'Wavely'}{' '}
                <MobileBottomTermsLink href={config.privacySupplementUrl}>
                  <>Privacy Supplement</>
                </MobileBottomTermsLink>
              </Trans>
            </div>
          </MobileNote>
        </FixedFooter>
      </>
    </form>
  )
}
