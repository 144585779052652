import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { config } from '../../config'
import { WavelyIcon } from '../../icons/WavelyIcon'
import { colors, containerWidths } from '../../theme'
import { urls } from '../../urls'
import { ExternalLink } from '../common/ExternalLink'
import { LanguageSelector } from './LanguageSelector'

export const Footer = () => {
  const { t } = useTranslation()
  const getDesktopFooter = () => window.matchMedia(`(min-width: ${containerWidths.md}px)`).matches
  const [showDesktopFooter, setDesktopFooter] = useState(getDesktopFooter())

  useEffect(() => {
    const handleResize = () => {
      setDesktopFooter(getDesktopFooter())
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return showDesktopFooter ? (
    <footer
      css={`
        color: #3f5561;
        font-size: 0.875rem;
        background-color: ${colors.darkGrey};
        width: 100%;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        height: 3.75rem;
      `}
    >
      <div
        css={`
          margin-left: 32px;
        `}
      >
        {config.enableLocalization && <LanguageSelector />}
      </div>
      <>
        <div
          css={`
            margin-left: 1.25rem;
            margin-right: 0.3125rem;
          `}
        >
          <WavelyIcon css={``} color={'#435E69'} width={60} height={30} />
        </div>
        <div
          css={`
            flex-grow: 1;
            min-width: 160px;
          `}
        >
          {'© 2021 Wavely. All rights reserved.'}
        </div>
      </>
      <div
        css={`
          padding: 0 1.25rem;
          display: flex;
          flex-direction: column;
          width: 100%;
          text-align: right;
          a,
          div {
            cursor: pointer;
            color: ${colors.hydraGreen};
            text-decoration: none;
            :hover {
              opacity: 0.7;
            }
            padding: 0.3125rem 0;
            @media screen and (min-width: ${containerWidths.md}px) {
              margin: 0 0.625rem;
            }
          }

          @media screen and (min-width: ${containerWidths.md}px) {
            flex-direction: row;
            width: auto;
            text-align: left;
          }
        `}
      >
        <ExternalLink href={config.privacyPolicyUrl}>
          <>{t('privacyPolicy')}</>
        </ExternalLink>
        <ExternalLink href={config.serviceTermsUrl}>
          <>{t('termsOfService')}</>
        </ExternalLink>
        <Link to={urls.support.index.clientUrl()}>{t('footer.support')}</Link>
      </div>
    </footer>
  ) : (
    <footer
      css={`
        color: #3f5561;
        font-size: 0.875rem;
        background-color: ${colors.darkGrey};
        width: 100%;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 8.75rem;
        padding-top: 24px;
        padding-bottom: 24px;
      `}
    >
      <div
        css={`
          padding: 0 1.25rem;
          display: flex;
          justify-content: space-evenly;
          width: 100%;
          text-align: right;
          margin-bottom: 25px;
          a,
          div {
            cursor: pointer;
            color: ${colors.hydraGreen};
            text-decoration: none;
            :hover {
              opacity: 0.7;
            }
            padding: 0.3125rem 0;
          }
        `}
      >
        <ExternalLink href={config.privacyPolicyUrl}>
          <>{t('privacyPolicy')}</>
        </ExternalLink>
        <ExternalLink href={config.serviceTermsUrl}>
          <>{t('termsOfService')}</>
        </ExternalLink>
        <Link to={urls.support.index.clientUrl()}>{t('footer.support')}</Link>
      </div>
      <div
        css={`
          width: 90%;
        `}
      >
        {config.enableLocalization && <LanguageSelector />}
      </div>
      <>
        <div
          css={`
            margin: 0.3125rem 0 0.3125rem 0;
          `}
        >
          <WavelyIcon css={``} color={'#435E69'} width={100} height={40} />
        </div>
        <div>{'© 2021 Wavely. All rights reserved.'}</div>
      </>
    </footer>
  )
}
