import React from 'react'
import { Route, Switch } from 'react-router'
import { urls } from '../../urls'
import { CoveragePage } from './Coverage/CoveragePage'
import { DeviceSettingsPage } from './DeviceSettings/DeviceSettingsPage'
import { FeedbackPage } from './Feedback/FeedbackPage'
import { CreateSupportTicketPage } from './FreshdeskEmailSupport/CreateSupportTicketPage'
import { GetConnectedPage } from './GetConnected/GetConnectedPage'
import { SupportLiveChatPage } from './LiveChat/SupportLiveChatPage'
import { SupportPage } from './SupportPage'

interface Props {
  jwtToken: string | null
}

export const SupportRoutes = ({ jwtToken }: Props) => (
  <Switch>
    <Route path={urls.support.coverage.url}>
      <CoveragePage />
    </Route>

    <Route path={urls.support.getConnected.url}>
      <GetConnectedPage />
    </Route>

    <Route path={urls.support.deviceSettings.url}>
      <DeviceSettingsPage />
    </Route>

    <Route path={urls.support.feedback.url}>
      <FeedbackPage />
    </Route>

    <Route path={urls.support.liveChat.url}>
      <SupportLiveChatPage jwtToken={jwtToken} />
    </Route>

    <Route path={urls.support.supportTicket.url}>
      <CreateSupportTicketPage jwtToken={jwtToken} />
    </Route>

    <Route path={urls.support.index.url}>
      {/* keep the index route as the last one */}
      <SupportPage />
    </Route>
  </Switch>
)
