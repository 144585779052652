import { Workbox } from 'workbox-window'
import { config } from './config'
import { getNewVersionAvailable } from './storage-api'

export const initServiceWorker = (showUpdateNotification: (onAccept: () => Promise<void>) => void) => {
  if (!config.serviceWorkerDisabled && 'serviceWorker' in navigator) {
    const wb = new Workbox('/sw.js')

    const onAccept = async () => {
      wb.addEventListener('controlling', (_event) => {
        window.location.reload()
      })
      await wb.messageSW({ type: 'SKIP_WAITING' })
    }

    wb.addEventListener('waiting', (_event) => {
      // if we detect a new version is available while the app is running, we'll set a flag & do a refresh to update
      // the sw cache. Once the cache is updated we'll take the new version into use automatically.
      const loadNewVersion = getNewVersionAvailable()
      if (loadNewVersion) {
        onAccept().catch((_error) => {
          // NOOP
        })
      } else {
        showUpdateNotification(onAccept)
      }
    })

    wb.register().catch((_error) => {
      // NOOP
    })
  }
}
