import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { config } from '../../config'
import { LogoIcon } from '../../icons/LogoIcon'
import { containerWidths } from '../../theme'
import { urls } from '../../urls'
import { Page, PageContent } from '../common/Page'
import { ResponsivePadding } from '../common/styles'
import { SignInLanguageSelector } from './SignInLanguageSelector'
import { WavelyTutorialSection } from './WavelyTutorialSection'

const WavelyLogo = styled(LogoIcon)`
  margin-bottom: 40px;
  margin-top: 0.625rem;
  width: 120px;
`

const SignInContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  ${ResponsivePadding};
  margin-left: auto;
  margin-right: auto;
  height: 100%;
`

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Col = styled.div``

interface Props {
  children: React.ReactElement
}

export const SignInWrapper = ({ children }: Props) => (
  <Page
    menu={false}
    footer={false}
    disableGrid={true}
    css={`
      ${PageContent} {
        max-width: 100%;
        background: inherit;
        padding: 0;
        display: flex;
        flex-direction: column;
        min-height: unset;
        max-height: unset;
        margin-left: auto;
        margin-right: auto;
        height: unset;
        @media screen and (min-width: ${containerWidths.md}px) {
          flex-direction: row;
          height: 100vh;
          ${Col} {
            width: 50vw;
          }
        }
      }
    `}
  >
    <Col
      css={`
        flex: 1;
        background-color: #F9FAFF;
      `}
    >
      <SignInContainer css="height: 100vh; overflow-y:auto;">
        <TopContainer>
          <div
            css={`
              display: inline-block;
            `}
          >
            <Link
              title={'Wavely'}
              css={`
                text-decoration: none;
                outline: none;
              `}
              to={urls.home}
            >
              {<WavelyLogo />}
            </Link>
            {config.enableLocalization ? <SignInLanguageSelector /> : null}
          </div>
        </TopContainer>
        {children}
      </SignInContainer>
    </Col>
    <Col
      css={`
        /* background-color: #001e2d; */
      `}
    >
      {<WavelyTutorialSection />}
    </Col>
  </Page>
)
