import React from 'react'
import { useTranslation } from 'react-i18next'
import { urls } from '../../urls'
import { AlignCenter } from './styles'
import { Page, PageContent } from './Page'

export const ErrorPage = () => {
  const { t } = useTranslation()

  return (
    <Page
      menu={true}
      footer={true}
      css={`
        ${PageContent} {
          padding: 0;
        }
      `}
    >
      <AlignCenter>
        <p
          css={`
            padding: 0.625rem;
          `}
        >
          {t('errorPage.errorText')}
        </p>
        <div>
          <a
            css={`
              color: #000;
            `}
            href={urls.signIn}
          >
            {t('errorPage.home')}
          </a>
        </div>
      </AlignCenter>
    </Page>
  )
}
