import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { WavelyAccountIcon } from '../../icons/AccountIcon'
import { WavelyDataPlansIcon } from '../../icons/DataPlansIcon'
import { WavelyHomeIcon } from '../../icons/HomeIcon'
import { WavelySuportIcon } from '../../icons/LifePreserver'
import { LogoIcon } from '../../icons/LogoIcon'
import { WavelySimIcon } from '../../icons/SimIcon'
import {
  MENU_ACCOUNT_ID,
  MENU_DATA_PLANS_ID,
  MENU_HOME_ID,
  MENU_SIM_CARDS_ID,
  MENU_SUPPORT_ID,
} from '../../test-selectors'
import { urls } from '../../urls'

const Logo = styled(LogoIcon)``

export const wavelyDesktopMenuWidth = '19rem'

const MenuItemText = styled.div`
  padding-top: 0.625rem;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`

const MenuContent = styled.nav`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  background-color: #fff;
  position: relative;
  width: 100%;
  ${Logo} {
    display: none;
  }
`

const menuInactiveColor = '#71828a'
const MenuItem = styled.div`
  width: 25%;
  color: ${menuInactiveColor};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.7;
  }
`

const MenuLinkStyle = css`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(135, 145, 182, 0.5);
  &.active {
    font-weight: 800;
    color: #19224c;
  }
`

const MenuLink = styled(NavLink)`
  ${MenuLinkStyle};
`

export const MobileWavelyMenu = () => {
  const { t } = useTranslation()

  const routesForCompleteUsers = [
    <MenuItem key="home">
      <MenuLink id={MENU_HOME_ID} to={urls.home}>
        <WavelyHomeIcon margin={false} />
        <MenuItemText>{t('menu.home')}</MenuItemText>
      </MenuLink>
    </MenuItem>,

    <MenuItem key="user-packages">
      <MenuLink id={MENU_DATA_PLANS_ID} to={urls.userPackages.clientUrl()}>
        <WavelyDataPlansIcon />
        <MenuItemText>{t('menu.dataPlans')}</MenuItemText>
      </MenuLink>
    </MenuItem>,

    <MenuItem key="sim-cards">
      <MenuLink id={MENU_SIM_CARDS_ID} to={urls.simList}>
        <WavelySimIcon margin={false} />
        <MenuItemText>{t('menu.simCards')}</MenuItemText>
      </MenuLink>
    </MenuItem>,
    <MenuItem key="account">
      <MenuLink id={MENU_ACCOUNT_ID} to={urls.account}>
        <WavelyAccountIcon />
        <MenuItemText>{t('menu.account')}</MenuItemText>
      </MenuLink>
    </MenuItem>,

    <MenuItem key="support">
      <MenuLink id={MENU_SUPPORT_ID} to={urls.support.index.clientUrl()}>
        <WavelySuportIcon margin={false} />
        <MenuItemText>{t('menu.support')}</MenuItemText>
      </MenuLink>
    </MenuItem>,
  ]

  return (
    <nav
      css={`
        width: 100%;
        height: 85px;
        position: sticky;
        bottom: 0;
        z-index: 9999;
        background: #fff;
      `}
    >
      <MenuContent>{routesForCompleteUsers}</MenuContent>
    </nav>
  )
}
