import { ReactElement } from 'react'
import { useMedia } from 'react-media'

interface AdaptiveChildrenProps {
  mobileView: ReactElement
  desktopView: ReactElement
  adaptiveEnabled: boolean
}

export const AdaptiveWrapper = ({ mobileView, desktopView, adaptiveEnabled }: AdaptiveChildrenProps) => {
  const isSmallScreen = useMedia({ query: '(max-width: 700px)' })
  const isShortScreen = useMedia({ query: '(max-height: 500px)' })

  if (!adaptiveEnabled) {
    return desktopView
  }

  return (isSmallScreen || isShortScreen) ? mobileView : desktopView
}
