import React, { Suspense, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDialogContext } from '../../context/DialogContext'
import { QrIcon } from '../../icons/QrIcon'
import { QuestionMarkIcon } from '../../icons/QuestionMarkIcon'
import {
  PHONENUMBER_NUMBER_INPUT,
  PIN_NUMBER_INPUT,
  SIM_NAME_INPUT,
  SIM_NAME_LABEL,
  SIM_NUMBER_INPUT,
  SIM_NUMBER_LABEL
} from '../../test-selectors'
import { colors } from '../../theme'
import { iccNumberTestLength, shortIccNumberLength } from '../../utils/icc-utils'
import { InputField } from '../common/InputField'
import { Loading } from '../Loading/Loading'
const QrCodeScanner = React.lazy(() => import('./QrCodeScanner'))

export const Header = styled.div`
  font-size: 20px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #19224c;
  line-height: 2.375rem;
  margin-top: 1.25rem;
  margin-bottom: 0;
`

export const HelpText = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 1.5rem;
  color: #7d88af;
`

const LabelText = styled.div`
  margin-bottom: 0.3125rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
`

const WavelyLabelText = styled.div`
  /* margin: 16px 17.8px 6px 1px; */
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #19224c;
`

// from qr-scanner https://github.com/nimiq/qr-scanner/blob/18c3e4dff87077e026a7312f3cf78751c1c19343/src/qr-scanner.js
export const hasCamera = () => {
  if (!(navigator.mediaDevices && navigator.mediaDevices.enumerateDevices)) {
    return Promise.resolve(false)
  }

  return navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => devices.some((device) => device.kind === 'videoinput'))
    .catch(() => false)
}

export const SimCardIccEditor = ({
  icc,
  iccPrefixes,
  validationError,
  onChange,
  onScannerValidRead,
  placeholder,
}: {
  icc: string
  iccPrefixes: string[]
  validationError: string | undefined
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
  onScannerValidRead: (icc: string) => void
  placeholder?: string
}) => {
  const { setDialog } = useDialogContext()
  const [scanningIsSupported, setScanningIsSupported] = useState(false)

  useEffect(() => {
    if (location.protocol === 'https:') {
      hasCamera()
        .then((hasCamera: boolean) => setScanningIsSupported(hasCamera))
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error)
        })
    }
  }, [])

  return (
    <label id={SIM_NUMBER_LABEL}>
      {<WavelyLabelText>ICC ID</WavelyLabelText>}
      <div
        css={`
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          position: relative;
        `}
      >
        <InputField
          id={SIM_NUMBER_INPUT}
          type="text"
          inputMode="numeric"
          pattern={`[0-9]{${shortIccNumberLength},${iccNumberTestLength}}`}
          name="icc"
          value={icc}
          autoCapitalize={'off'}
          autoComplete={'off'}
          autoCorrect={'off'}
          minLength={shortIccNumberLength}
          maxLength={iccNumberTestLength}
          validationError={validationError}
          onChange={onChange}
          placeholder={placeholder}
        >
          {scanningIsSupported && (
            <div
              onClick={(e) => {
                e.preventDefault()
                setDialog(
                  <Suspense fallback={<Loading color={colors.hydraGreen} />}>
                    <QrCodeScanner
                      onCancel={() => setDialog(null)}
                      onValidRead={onScannerValidRead}
                      iccPrefixes={iccPrefixes}
                    />
                  </Suspense>
                )
              }}
              css={`
                position: absolute;
                top: 1.3rem;
                right: 0.875rem;
                height: 100%;
                margin-left: 0.125rem;
                cursor: pointer;
                /* display: flex;
                align-items: center; */
                svg {
                  width: 1.875rem;
                  height: 1.875rem;
                }
                :hover {
                  opacity: 0.7;
                }
              `}
            >
              <QrIcon color={colors.hydraGreen} />
            </div>
          )}
        </InputField>
      </div>
    </label>
  )
}

export const SimCardNameEditor = ({
  name,
  validationError,
  onChange,
}: {
  name: string
  validationError: string | undefined
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
}) => {
  const { t } = useTranslation()

  return (
    <label id={SIM_NAME_LABEL}>
      {<WavelyLabelText>{t('registerSimCard.simName')}</WavelyLabelText>}
      <InputField
        placeholder={'Enter a name'}
        id={SIM_NAME_INPUT}
        type="text"
        name="name"
        autoCapitalize={'off'}
        autoComplete={'off'}
        autoCorrect={'off'}
        value={name}
        minLength={1}
        maxLength={20}
        validationError={validationError}
        onChange={onChange}
      />
    </label>
  )
}

export const pinLength = 4

export const PinCodeInput = ({
  pin,
  showHelpButton,
  onChange,
}: {
  pin: string
  showHelpButton: boolean
  onChange: (pin: string) => void
}) => {
  const { t } = useTranslation()
  const [showPinHelp, setShowPinHelp] = useState(false)
  const pinIsInvalid = pin.length !== pinLength || !Number.isInteger(parseInt(pin, 10))

  return (
    <>
      <label>
        <LabelText css={`
                      font-size: 12px;
                      font-weight: 600;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: normal;
                      letter-spacing: normal;
                      text-align: left;
                      color: #19224c;
                `
        }>
          {`${t('registerSimCard.pinCode')} `}
          {showHelpButton && (
            <div
              css={`
                cursor: pointer;
                margin-left: 0.625rem;
                display: flex;
                :hover {
                  opacity: 0.7;
                }
              `}
              onClick={() => setShowPinHelp(!showPinHelp)}
            >
              <QuestionMarkIcon color={colors.hydraGreen} />
            </div>
          )}
        </LabelText>

        {showPinHelp && (
          <div
            css={`
                font-size: 12px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #19224c;
            `}
          >
            {`${t('registerSimCard.helpText')} `}
          </div>
        )}
        <InputField
          placeholder={'Enter PIN Code'}
          css={`
            width: 50%;
          `}
          id={PIN_NUMBER_INPUT}
          type="text"
          inputMode="numeric"
          pattern={`[0-9]{${pinLength}}`}
          name="pin-code"
          value={pin}
          autoCapitalize={'off'}
          autoComplete={'off'}
          autoCorrect={'off'}
          max={9999}
          minLength={pinLength}
          maxLength={pinLength}
          onChange={(e) => onChange(e.target.value.slice(0, pinLength))}
          validationError={pinIsInvalid ? t('registerSimCard.invalidPin') : undefined}
        />
      </label>
    </>
  )
}

export const PhoneNumberInput = ({
  placeholder,
  phoneNumber,
  onChange,
}: {
  placeholder?: string
  phoneNumber: string
  onChange: (phoneNumber: string) => void
}) => (
  <>
    <div>
      {<WavelyLabelText>{`Mobile number with country code`}</WavelyLabelText>}
      <InputField
        id={PHONENUMBER_NUMBER_INPUT}
        type="text"
        inputMode="tel"
        name="phone-number"
        value={phoneNumber}
        autoCapitalize={'off'}
        autoComplete={'off'}
        autoCorrect={'off'}
        max={9999}
        minLength={3}
        maxLength={20}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  </>
)
