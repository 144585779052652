import React from 'react'
import { IconWithColor } from '../types'

export const CopyIcon = (props: IconWithColor) => (
  <svg className={props.className} width="15px" height="18px" viewBox="0 0 15 18" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-823.000000, -1027.000000)" fill={props.color}>
        <g transform="translate(451.000000, 872.000000)">
          <g transform="translate(371.000000, 155.000000)">
            <path d="M12,0.75 L3,0.75 C2.175,0.75 1.5,1.425 1.5,2.25 L1.5,12.75 L3,12.75 L3,2.25 L12,2.25 L12,0.75 Z M14.25,3.75 L6,3.75 C5.175,3.75 4.5,4.425 4.5,5.25 L4.5,15.75 C4.5,16.575 5.175,17.25 6,17.25 L14.25,17.25 C15.075,17.25 15.75,16.575 15.75,15.75 L15.75,5.25 C15.75,4.425 15.075,3.75 14.25,3.75 Z M14.25,15.75 L6,15.75 L6,5.25 L14.25,5.25 L14.25,15.75 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
