import React from 'react'
import { IconWithColor } from '../types'

export const LeftArrowIcon = (props: IconWithColor) => (
  <svg className={props.className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <title>Left arrow</title>
    <desc>Left arrow.</desc>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-16.000000, -46.000000)">
        <g transform="translate(16.000000, 46.000000)">
          <polygon points="0 0 24 0 24 24 0 24" />
          <polygon
            fill={props.color}
            fillRule="nonzero"
            points="20 11 7.83 11 13.42 5.41 12 4 4 12 12 20 13.41 18.59 7.83 13 20 13"
          />
        </g>
      </g>
    </g>
  </svg>
)
