export const GOOGLE_SIGN_IN_ID = 'google-signin'
export const APPLE_SIGN_IN_ID = 'apple-signin'
export const FACEBOOK_SIGN_IN_ID = 'facebook-signin'
export const EMAIL_SIGN_IN_ID = 'email-signin'
export const PURCHASE_PACKAGE_BUTTON_ID = 'purchase-package-id'
export const AVAILABLE_DATA_PACKAGE_CLASSNAME = 'available-data-package'
export const AVAILABLE_PAYMENT_CARD_CLASSNAME = 'available-payment-card'
export const CONFIRM_PURCHASE_BUTTON_ID = 'confirm-purchase-id'
export const CANCEL_AUTOREFILL_BUTTON_ID = 'cancel-autorefill-id'
export const PURCHASE_SUCCESS_ID = 'purchase-success-id'
export const MENU_BUTTON_ID = 'menu-button-id'
export const MENU_PURCHASE_PACKAGE_ID = 'menu-purchase-package-id'
export const MENU_HOME_ID = 'menu-home-id'
export const MENU_DATA_PLANS_ID = 'MENU_DATA_PLANS_ID'
export const MENU_SIM_CARDS_ID = 'menu-sim-cards-id'
export const MENU_SUPPORT_ID = 'menu-support-id'
export const MENU_ACCOUNT_ID = 'MENU_ACCOUNT_ID'
export const ADD_SIM_BUTTON_ID = 'add-sim-button-id'
export const SIM_NUMBER_INPUT = 'sim-number-input'
export const PIN_NUMBER_INPUT = 'pin-number-input'
export const PHONENUMBER_NUMBER_INPUT = 'phone-number-input'
export const SIM_NUMBER_LABEL = 'sim-number-label'
export const SIM_NAME_INPUT = 'sim-name-input'
export const SIM_NAME_LABEL = 'sim-name-label'
export const VALIDATION_ERROR_CLASSNAME = 'validation-error'
export const REGISTER_SIM_BUTTON = 'register-sim-button'
export const REQUEST_SIM_PIN_BUTTON = 'request-sim-pin-button'
export const SIM_ROW_CLASSNAME = 'sim-row'
export const MANAGE_ACTIVE_PACKAGE_BUTTON_ID = 'MANAGE_ACTIVE_PACKAGE_BUTTON_ID'
export const REFUND_PACKAGE_BUTTON_CLASSNAME = 'REFUND_PACKAGE_BUTTON_CLASSNAME'
export const REFUND_SUCCESS_NOTIFICATION_CLASSNAME = 'REFUND_SUCCESS_NOTIFICATION_CLASSNAME'
export const ADD_DATA_BUTTON_ID = 'ADD_DATA_BUTTON_ID'
export const EMAIL_LOGIN_INPUT_ID = 'EMAIL_LOGIN_INPUT_ID'
export const EMAIL_LOGIN_BUTTON_ID = 'EMAIL_LOGIN_BUTTON_ID'
export const COUNTRY_SELECT_ID = 'COUNTRY_SELECT_ID'
export const SIGN_UP_BUTTON_ID = 'SIGN_UP_BUTTON_ID'
export const VERIFY_EMAIL_LABEL_ID = 'VERIFY_EMAIL_LABEL_ID'
export const ENTER_ACCESS_CODE_ID = 'ENTER_ACCESS_CODE_ID'
export const PAYMENT_METHOD_SECTION_TITLE = 'PAYMENT_METHOD_SECTION_TITLE'
export const PAYMENT_METHOD_SECTION_CLASSNAME = 'PAYMENT_METHOD_SECTION_CLASSNAME'
export const ADD_PAYMENT_METHOD_TEST_ID = 'ADD_PAYMENT_METHOD_TEST_ID'
export const REMOVE_PAYMENT_METHOD_TEST_ID = 'REMOVE_PAYMENT_METHOD_TEST_ID'
export const PAYMENT_METHOD_MAKE_PRIMARY_TEST_ID = 'PAYMENT_METHOD_MAKE_PRIMARY_TEST_ID'
export const PAYMENT_METHOD_LIST_LOADER_TEST_ID = 'PAYMENT_METHOD_LIST_LOADER_TEST_ID'
export const PAYMENT_METHOD_LIST_TEST_ID = 'PAYMENT_METHOD_LIST_TEST_ID'
export const RENAME_SIM_TEST_ID = 'RENAME_SIM_TEST_ID'
export const UPDATE_SIM_NAME_SIM_TEST_ID = 'UPDATE_SIM_NAME_SIM_TEST_ID'
export const DEACTIVATE_SIM_BUTTON_TEST_ID = 'DEACTIVATE_SIM_BUTTON_TEST_ID'
export const CANCEL_AUTOREFILL_BUTTON_CLASSNAME = 'CANCEL_AUTOREFILL_BUTTON_CLASSNAME'
export const ACCESS_CODE_FORM_ID = 'ACCESS_CODE_FORM_ID'
export const ACCESS_CODE_FORM_INPUT_CLASS = 'ACCESS_CODE_FORM_INPUT_CLASS'
export const SKIP_ACTIVATION_ID = 'SKIP_ACTIVATION_ID'
export const SETTINGS_LOGOUT_ID = 'SETTINGS_LOGOUT_ID'
export const SETTINGS_EDIT_PROFILE_ID = 'SETTINGS_EDIT_PROFILE_ID'
export const SETTINGS_EDIT_PROFILE_BUTTON_ID = 'SETTINGS_EDIT_PROFILE_BUTTON_ID'
export const HOME_PAGE_ID = 'HOME_PAGE_ID'
export const PURCHASE_ESIM_PROFILE_BUTTON_ID = 'PURCHASE_ESIM_PROFILE_BUTTON_ID'
