import React from 'react'
import styled from 'styled-components'
import { useDialogContext } from '../../context/DialogContext'
import { CloseIcon } from '../../icons/CloseIcon'

interface Props {
  children: React.ReactElement
  onClose?: () => void
  hideClose?: boolean
  verticalCenter?: boolean
}

export const Frame = styled.div<{ verticalCenter: boolean }>`
  max-width: 760px;
  margin: auto;
  height: ${(props) => (props.verticalCenter ? '100%' : 'auto')};
`

export const DialogContent = ({ children, onClose, verticalCenter, hideClose }: Props) => {
  const { setDialog } = useDialogContext()

  return (
    <Frame verticalCenter={verticalCenter ?? false}>
      {children}
      {!hideClose && (
        <div
          title={'Close dialog'}
          onClick={onClose !== undefined ? onClose : () => setDialog(null)}
          css={`
            position: fixed;
            top: 2.8125rem;
            right: 3.5rem;
            width: 1.5rem;
            height: 1.5rem;
            cursor: pointer;
            :hover {
              opacity: 0.7;
            }
          `}
        >
          <CloseIcon
            color={'#667881'}
            css={`
              width: 1.5rem;
              height: 1.5rem;
            `}
          />
        </div>
      )}
    </Frame>
  )
}
