import React from 'react'
import { IconWithColor } from '../types'

export const TrashcanIcon = (props: IconWithColor) => (
  <svg className={props.className} width="14px" height="18px" viewBox="0 0 14 18" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-500.000000, -1526.000000)" fill={props.color}>
        <g transform="translate(451.000000, 1495.000000)">
          <g transform="translate(44.000000, 28.000000)">
            <path d="M6,19 C6,20.1 6.9,21 8,21 L16,21 C17.1,21 18,20.1 18,19 L18,7 L6,7 L6,19 Z M8,9 L16,9 L16,19 L8,19 L8,9 Z M15.5,4 L14.5,3 L9.5,3 L8.5,4 L5,4 L5,6 L19,6 L19,4 L15.5,4 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
