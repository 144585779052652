import React from 'react'
import { Loading } from '../Loading/Loading'

interface Props {
  isLoading?: boolean
  text: string | JSX.Element
}

export const ButtonText = ({ isLoading, text }: Props) =>
  isLoading ? (
    <>
      <Loading
        color={'#000'}
        size={20}
        margin={0}
        css={`
          margin-right: 0.3125rem;
          display: flex;
        `}
      />
      {text}
    </>
  ) : (
    <>{text}</>
  )
