import { useEffect, useState } from 'react'
import { pipe } from 'fp-ts/lib/function'
import { fold } from 'fp-ts/es6/Either'
import { useTranslation } from 'react-i18next'
import { getCountryList } from '../api/api'
import { GetCountryListSuccessResponse } from '../api/api-types'
import { useErrorContext } from '../context/ErrorContext'
import { getCachedCountryList, setCachedCountryList } from '../storage-api'
import { exhaustiveCheck } from '../exhaustive-switch-check'
import { redirectToSignInOnAuthenticationError, renewJwtTokenOnAuthenticationExpiredError } from '../api/api-utils'
import { useDataLoader } from './useDataLoader'

export const useCountryListLoader = () => {
  const { i18n } = useTranslation()
  const [listLanguage, setListLanguage] = useState<string>(i18n.language)
  const [state, setState] = useState<null | GetCountryListSuccessResponse>(getCachedCountryList(i18n.language))
  const { query } = useDataLoader(getCountryList)
  const { onError } = useErrorContext()

  useEffect(() => {
    const fetchCountries = () => {
      if (state && listLanguage === i18n.language) {
        return
      }
      const cachedCountryList = getCachedCountryList(i18n.language)
      if (cachedCountryList) {
        setListLanguage(i18n.language)
        setState(cachedCountryList)
        return
      }
      query(i18n.language)
        .then((response) =>
          pipe(
            response,
            fold(
              (error) => {
                switch (error.type) {
                  case 'ABORT_ERROR':
                    return
                  case 'THROTTLE_ERROR':
                  case 'WRONG_PIN_CODE':
                    return
                  case 'FETCH_ERROR':
                  case 'API_ERROR':
                  case 'UNSUPPORTED_RESPONSE':
                    return onError(error, error.type)
                  case 'AUTHENTICATION_EXPIRED_ERROR':
                    return renewJwtTokenOnAuthenticationExpiredError()
                  case 'AUTHENTICATION_ERROR':
                    return redirectToSignInOnAuthenticationError()
                  default:
                    return exhaustiveCheck(error)
                }
              },
              (countryList) => {
                setListLanguage(i18n.language)
                setCachedCountryList(i18n.language, countryList)
                setState(countryList)
              }
            )
          )
        )
        .catch(onError)
    }
    fetchCountries()
    i18n.on('languageChanged', (_lng) => {
      fetchCountries()
    })
  }, [onError, query, state, i18n, listLanguage])

  return state
}
