import React, { useEffect } from 'react'
import { formatPageTitle } from '../formatPageTitle'
import { AlignCenter } from './common/styles'
import { Page } from './common/Page'

export const PageNotFound = () => {
  useEffect(() => {
    document.title = formatPageTitle('Page not found')
  }, [])
  return (
    <Page menu={true} footer={true}>
      <AlignCenter>404: Page not found</AlignCenter>
    </Page>
  )
}
