import React from 'react'
import { IconWithColor } from '../types'

export const QrIcon = (props: IconWithColor) => (
  <svg className={props.className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g id="icons/_glyphs/qr-code" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M14.028,10 L22.028,10 L22.028,2 L14.028,2 L14.028,10 Z M16.027,8 L20.028,8 L20.028,4 L16.027,4 L16.027,8 Z M11,4 L13,4 L13,2 L11,2 L11,4 Z M2,10 L10,10 L10,2 L2,2 L2,10 Z M4,8 L8.001,8 L8.001,4 L4,4 L4,8 Z M17,6.972 L19,6.972 L19,4.972 L17,4.972 L17,6.972 Z M4.973,6.972 L6.973,6.972 L6.973,4.972 L4.973,4.972 L4.973,6.972 Z M11,9.001 L13,9.001 L13,5 L11,5 L11,9.001 Z M11,10 L11,11 L7,11 L7,13.014 L11,13.014 L11,13.008 L13,13.008 L13,10 L11,10 Z M17.027,11 L17.027,14.014 L14,14.014 L14,15.821 L14,17.008 L11,17.008 L11,19.008 L16,19.008 L16,18.972 L16,17.042 L17.027,17.042 L17.027,17.015 L19.027,17.015 L20.028,17.015 L20.028,18.972 L22.028,18.972 L22.028,15.014 L19.027,15.014 L19.027,11 L17.027,11 Z M14,13 L16.001,13 L16.001,11 L14,11 L14,13 Z M2,13.014 L6.001,13.014 L6.001,11.001 L2,11.001 L2,13.014 Z M20.028,13.007 L22.028,13.007 L22.028,11.006 L20.028,11.006 L20.028,13.007 Z M2,22 L10,22 L10,14 L2,14 L2,22 Z M4,20.001 L8.001,20.001 L8.001,16 L4,16 L4,20.001 Z M11,16.015 L13,16.015 L13,14.014 L11,14.014 L11,16.015 Z M4.973,18.972 L6.973,18.972 L6.973,16.972 L4.973,16.972 L4.973,18.972 Z M17.027,19.972 L19.027,19.972 L19.027,17.972 L17.027,17.972 L17.027,19.972 Z M20.028,22 L22.028,22 L22.028,20 L20.028,20 L20.028,22 Z M11,22 L16,22 L16,20 L11,20 L11,22 Z"
        fill={props.color}
      />
    </g>
  </svg>
)
