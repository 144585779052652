import React, { useContext } from 'react'
import { GlobalErrorTypes } from '../types'

export type OnErrorHandler = (error: any, errorType?: GlobalErrorTypes) => void
export interface ErrorContext {
  onError: OnErrorHandler
}

export const ErrorContext = React.createContext<null | ErrorContext>(null)

export const useErrorContext = () => {
  const context = useContext(ErrorContext)
  if (!context) {
    throw new Error('Error Context is not available, Component needs to be child of Error Context provider')
  }
  return context
}
