import { DateTime } from 'luxon'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { config } from '../../config'
import { formatPageTitle } from '../../formatPageTitle'
import { EmailIcon } from '../../icons/EmailIcon'
import { FeedbackIcon } from '../../icons/FeedbackIcon'
import { QuestionMarkIcon } from '../../icons/QuestionMarkIcon'
import { SimIcon } from '../../icons/SimIcon'
import { colors } from '../../theme'
import { urls } from '../../urls'
import { Page } from '../common/Page'
import { SectionLink, SectionLinkWithExternalURL } from '../common/SectionLink'
import { SectionTitle } from '../common/styles'
import { PageTitle } from '../styles/PageTitle'
import { humanReadableOpeningTimes, isFreshchatOpen } from './opening-times-utils'

export const SupportPage = () => {
  const { t } = useTranslation()
  useEffect(() => {
    document.title = formatPageTitle(t('support.supportPage.pageTitle'))
  }, [t])
  return (
    <Page menu={true} footer={true}>
      <div
        css={`
          grid-column-start: 1;
          grid-column-end: 5;
          @media screen and (min-width: 600px) {
            grid-column-start: 1;
            grid-column-end: 9;
          }

          @media screen and (min-width: 1024px) {
            grid-column-start: 1;
            grid-column-end: 13;
          }
        `}
      >
        <PageTitle>{t('support.supportPage.pageTitle')}</PageTitle>
        <SectionTitle>{t('support.supportPage.sectionTitle')}</SectionTitle>

        <SectionLinkWithExternalURL
          to={'https://wavely.io/connect/faq'}
          title={'FAQ'}
          icon={<QuestionMarkIcon color={colors.hydraGreen} />}
        />

        {config.enableEsimFeature && (
          <SectionLink
            to={urls.support.deviceSettings.clientUrl()}
            title={t('support.supportPage.deviceSettings')}
            icon={<SimIcon color={colors.hydraGreen} />}
          />
        )}

        <SectionLink
          to={urls.support.supportTicket.clientUrl()}
          title={t('support.supportPage.supportTicket')}
          icon={<EmailIcon color={colors.hydraGreen} />}
        />

        <SectionLink
          to={urls.support.liveChat.clientUrl()}
          title={t('support.supportPage.liveChat')}
          subtitle={`Open ${humanReadableOpeningTimes()}`}
          disabled={!isFreshchatOpen(DateTime.local())}
          icon={<FeedbackIcon color={colors.hydraGreen} />}
        />
      </div>
    </Page>
  )
}
