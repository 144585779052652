import React, { Suspense, useCallback, useEffect, useReducer, useState } from 'react'
import { useHistory } from 'react-router'
import { checkNetworkConnection } from './api/api'
import { Dialog } from './components/common/Dialog'
import { LocalisationLoader } from './components/Loading/LocalisationLoader'
import { ErrorState, ErrorToast } from './components/Toast/ErrorToast'
import { OfflineToast } from './components/Toast/OfflineToast'
import { WarningToast } from './components/Toast/Toast'
import { UpdateAvailableTopBar } from './components/Toast/UpdateAvailableTopBar'
import { config } from './config'
import { AppQueryContext, appQueryReducer } from './context/AppQueryContext'
import { DialogContext } from './context/DialogContext'
import { ErrorContext } from './context/ErrorContext'
import { JwtContext } from './context/JwtContext'
import { useDialog } from './customHooks/useDialog'
import { useVersionCheck } from './customHooks/useVersionCheck'
import { Routes } from './Routes'
import { getCachedJwtRefreshToken, getCachedJwtToken, persistJwtRefreshToken, persistJwtToken } from './storage-api'
import { initServiceWorker } from './sw-loader'
import TelemetryProvider from './telemetry-provider'
import { GlobalErrorTypes, OnUpdateAccept } from './types'
import { urls } from './urls'
import { parseUnknownError, reportError } from './utils/error-utils'

const win = window as Window &
  typeof globalThis & {
    __GLOBAL_STATE: string
    APP_VERSION: string
  }
win.APP_VERSION = config.appVersion

window.dataLayer = window.dataLayer || []
declare global {
  interface Window {
    dataLayer: any[]
  }
}

interface OnAcceptState {
  onAccept: null | OnUpdateAccept
}

export const App = () => {
  const [jwtToken, setJwtToken] = useState<string | null>(getCachedJwtToken())
  const [jwtRefreshToken, setJwtRefreshToken] = useState<string | null>(getCachedJwtRefreshToken())
  const [error, setError] = useState<ErrorState | null>(null)
  const [onUpdateAccepted, setOnUpdateAccepted] = useState<OnAcceptState>({ onAccept: null })
  const { dialog, setDialog } = useDialog()
  const [appQuery, dispatch] = useReducer(appQueryReducer, null)
  const history = useHistory()
  const redirectToInitialRegisterSimCard = useCallback(() => history.push(urls.initialRegisterSimCard), [history])
  const [toast, setToast] = useState<null | React.ReactElement>(null)
  const [popup, setPopup] = useState<null | React.ReactElement>(null)

  const onError = (error: any, errorType?: GlobalErrorTypes) => {
    const err = parseUnknownError(error)
    err.stack = error?.stack
    reportError(err, errorType)
    reportError(error, errorType)
    setError({ error: err, errorType })
    // eslint-disable-next-line no-console
    console.error('onError:', error, err)
  }

  useVersionCheck(setOnUpdateAccepted)

  const [isOnline, setIsOnline] = useState(navigator.onLine)
  useEffect(() => {
    checkNetworkConnection()
      .then((isOnline) => {
        setIsOnline(isOnline)
      })
      .catch(() => setIsOnline(false))

    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine)
    }

    window.addEventListener('online', updateOnlineStatus)
    window.addEventListener('offline', updateOnlineStatus)
    return () => {
      window.removeEventListener('online', updateOnlineStatus)
      window.removeEventListener('offline', updateOnlineStatus)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('error', (event: ErrorEvent) => {
      onError({ error: new Error(event.message) })
    })
  }, [])

  useEffect(() => persistJwtToken(jwtToken), [jwtToken])
  useEffect(() => persistJwtRefreshToken(jwtRefreshToken), [jwtRefreshToken])

  useEffect(() => {
    initServiceWorker((onAccept: OnUpdateAccept) => {
      setOnUpdateAccepted({ onAccept })
    })
  }, [])

  return (
    <Suspense fallback="loading">
      <TelemetryProvider instrumentationKey={config.nodeEnv === 'development' ? '' : config.appInsightsKey}>
        <ErrorContext.Provider
          value={{
            onError: useCallback(onError, []),
          }}
        >
          <JwtContext.Provider value={{ jwtToken, setJwtToken, jwtRefreshToken, setJwtRefreshToken }}>
            <AppQueryContext.Provider value={{ appQuery, dispatch, redirectToInitialRegisterSimCard }}>
              <DialogContext.Provider value={{ setDialog, setToast, setPopup }}>
                <LocalisationLoader>
                  <Dialog>{dialog}</Dialog>

                  {error && (
                    <WarningToast
                      message={<ErrorToast error={error.error} errorType={error.errorType} />}
                      onClose={() => setError(null)}
                    />
                  )}
                  {onUpdateAccepted.onAccept && (
                    <UpdateAvailableTopBar
                      onAccept={onUpdateAccepted.onAccept}
                      onDiscard={() => setOnUpdateAccepted({ onAccept: null })}
                    />
                  )}
                  {!isOnline && <WarningToast message={<OfflineToast />} onClose={() => setIsOnline(true)} />}
                  {toast}
                  {popup}
                  <Routes jwtToken={jwtToken} jwtRefreshToken={jwtRefreshToken} />
                </LocalisationLoader>
              </DialogContext.Provider>
            </AppQueryContext.Provider>
          </JwtContext.Provider>
        </ErrorContext.Provider>
      </TelemetryProvider>
    </Suspense>
  )
}
