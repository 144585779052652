import React from 'react'
import styled from 'styled-components'
import { colors } from '../../theme'
import { defaultBorderRadius } from '../styles/defaultBorderRadius'
import { ButtonText } from './ButtonText'
import { StyledBaseButton } from './StyledBaseButton'

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  loading?: boolean
  text: string | JSX.Element
}

interface IconProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  icon: JSX.Element
}

export const StyledButton = styled(StyledBaseButton) <{ isloading?: boolean }>`
  min-height: 2.5625rem;
  border-radius: ${defaultBorderRadius};
  /* box-shadow: 0 3px 20px 0 rgba(0, 183, 174, 0.4); */
  padding: 1rem 1.5rem;
  background-color: ${colors.hydraGreen};
  vertical-align: middle;
  font-size: 14px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;

  :disabled {
    background-color: #ebeef8;
    /* border: 0.0625rem solid #dedede; */
    box-shadow: none;
    font-size: 14px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #a2a8c1;
  }
`

export const Button = (props: Props) => {
  const { text, loading, disabled, ref, ...rest } = props

  return (
    <StyledButton as="button" isloading={loading} disabled={disabled || loading} {...rest}>
      <ButtonText isLoading={loading} text={text} />
    </StyledButton>
  )
}

export const IconButton = (props: IconProps) => {
  const { icon, disabled, ref, ...rest } = props

  return (
    <StyledBaseButton
      css={`
        background-color: transparent;
      `}
      as="button"
      disabled={disabled}
      {...rest}
    >
      {icon}
    </StyledBaseButton>
  )
}

export const CancelButton = styled(Button)`
  background-color: #fff;
  border: 0.0625rem solid #e6e9ea;
  color: ${colors.hydraGreen};
  &:hover {
    opacity: 1;
    background-color: #e6e9ea;
  }
`

export const TextButton = styled(Button)`
  background-color: transparent;
  font-size: 12px;
  font-weight: 600;
  padding: 0.3125rem 0;
  :disabled {
    color: ${colors.greyText};
    background-color: transparent;
    border: transparent;
  }
`

export const GreenButton = styled(Button)`
  background-color: ${colors.hydraGreen};
`

export const WarningButton = styled(Button)`
  background-color: ${colors.warningRed};
`

export const ErrorButton = styled(Button)`
  background-color: ${colors.errorRed};
`

export const GreenTextButton = styled(TextButton)`
  color: ${colors.hydraGreen};
`

export const WarningTextButton = styled(TextButton)`
  color: ${colors.warningRed};
`
