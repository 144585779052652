import React from 'react'

interface Props {
  src: string
  alt?: string
  className?: string
}

const getWebpPath = (pngPath: string) => (pngPath || ''.includes('.png') ? pngPath.replace('.png', '.webp') : null)

export const Picture = ({ src, alt, className }: Props) => {
  const webpSrc = getWebpPath(src)

  return (
    <picture className={className}>
      {webpSrc && <source type="image/webp" srcSet={webpSrc} />}
      <img src={src} alt={alt} />
    </picture>
  )
}
