import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { GlobalErrorTypes } from '../../types'

export interface ErrorState {
  error: Error
  errorType?: GlobalErrorTypes
}

type Props = ErrorState

const Content = styled.div``

export const ErrorToast = ({ error, errorType }: Props) => {
  const { t } = useTranslation()
  const tryAgainText = t('errorToast.tryAgainText')
  const errorReportText = t('errorToast.errorReportText')
  const body = (() => {
    switch (errorType) {
      case 'FETCH_ERROR':
        return t('errorToast.fetchError')
      case 'UNSUPPORTED_RESPONSE':
        return t('errorToast.unsupportedResponse', { tryAgainText, errorReportText })
      case 'API_ERROR':
        return t('errorToast.apiError', { tryAgainText, errorReportText })
      default:
        return 'Error: ' + error.message
    }
  })()

  return (
    <Content>
      <p>{body}</p>
    </Content>
  )
}
