import React, { useState, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import styled from 'styled-components'
import { Page } from '../../common/Page'
import { checkNetworkConnection } from '../../../api/api'
import { Button } from '../../Button/Button'
import { colors, containerWidths } from '../../../theme'
import { ExternalLink } from '../../common/ExternalLink'
import { BackButton } from '../../common/BackButton'
import { urls } from '../../../urls'
import { PageTitle } from '../../styles/PageTitle'
import ios1 from './ios1.jpg'
import ios2 from './ios2.jpg'
import android1 from './android1.png'
import android2 from './android2.png'
import android3 from './android3.png'

type OnlineState = { type: 'loading' } | { type: 'online' } | { type: 'offline' }

const SectionTitle = styled.h3`
  font-weight: 600;
  font-size: 1.125rem;
  border-bottom: 0.0625rem solid ${colors.greyText};
  margin-bottom: 0.625rem;
`

export const GetConnectedPage = () => {
  const { t } = useTranslation()
  const [onlineState, setOnlineState] = useState<OnlineState>({ type: 'loading' })

  const checkStatus = () => {
    checkNetworkConnection()
      .then((isOnline) => {
        setOnlineState({ type: isOnline ? 'online' : 'offline' })
      })
      .catch((_error) => setOnlineState({ type: 'offline' }))
  }

  useEffect(() => {
    checkStatus()
  }, [])

  const troubleshootingListItems = [
    'support.getConnected.troubleshooting.0',
    'support.getConnected.troubleshooting.1',
    'support.getConnected.troubleshooting.2',
  ].map(t)

  return (
    <Page footer={true} menu={true} css={``}>
      <div
        css={`
          grid-column-start: 1;
          grid-column-end: 5;
          @media screen and (min-width: 600px) {
            grid-column-start: 1;
            grid-column-end: 9;
          }

          @media screen and (min-width: 1024px) {
            grid-column-start: 1;
            grid-column-end: 13;
          }
        `}
      >
        <>
          <BackButton
            to={urls.support.index.clientUrl()}
            color={colors.hydraGreen}
            text={t('support.getConnected.backButton')}
          />
          <PageTitle>{t('support.getConnected.pageTitle')}</PageTitle>
        </>
        <p
          css={`
            line-height: 1.875rem;
          `}
        >
          {t('support.getConnected.helpText')}
        </p>

        <div
          css={`
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @media screen and (min-width: ${containerWidths.md}px) {
              flex-direction: row;
            }
          `}
        >
          <div>{t('support.getConnected.deviceNetworkStatus')}</div>
          <div
            css={`
              margin-left: 0.3125rem;
              font-weight: 600;
              font-size: 1.125rem;
              color: ${(() => {
                switch (onlineState.type) {
                  case 'offline':
                    return colors.warningRed
                  case 'online':
                    return colors.hydraGreen
                  case 'loading':
                    return colors.greyText
                }
              })()};
            `}
          >
            {onlineState.type}
          </div>

          <Button
            css={`
              margin-left: 1.25rem;
              margin-top: 0.3125rem;
              @media screen and (min-width: ${containerWidths.md}px) {
                margin-top: 0;
              }
            `}
            disabled={onlineState.type === 'loading'}
            loading={onlineState.type === 'loading'}
            onClick={() => {
              setOnlineState({ type: 'loading' })

              // show spinner for a second so it is more clear for the user that something is happening
              setTimeout(checkStatus, 1000)
            }}
            text={t('support.getConnected.testNetworkConnection')}
          />
        </div>

        <ol
          css={`
            line-height: 1.875rem;
          `}
        >
          {troubleshootingListItems.map((content, i) => (
            <li key={i}>{content}</li>
          ))}
        </ol>

        <SectionTitle>Android</SectionTitle>
        <ul
          css={`
            a {
              display: block;
              line-height: 1.875rem;
              color: #000;
            }
          `}
        >
          <li>
            <ExternalLink href={android1}>
              <>{t('support.getConnected.android.0')}</>
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href={android2}>
              <>{t('support.getConnected.android.1')}</>
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href={android3}>
              <>{t('support.getConnected.android.2')}</>
            </ExternalLink>
          </li>
        </ul>

        <SectionTitle>iOS</SectionTitle>
        <ul
          css={`
            line-height: 1.875rem;
            a {
              color: #000;
            }
          `}
        >
          <li>
            <Trans i18nKey="support.getConnected.iOS.0">
              <ExternalLink
                css={`
                  margin-right: 0.3125rem;
                `}
                href={'https://support.apple.com/en-us/HT202769'}
              >
                <>Control panel</>
              </ExternalLink>
              should look like{' '}
              <ExternalLink href={ios1}>
                <>this</>
              </ExternalLink>
            </Trans>
          </li>
          <li>
            <Trans i18nKey="support.getConnected.iOS.1">
              <ExternalLink
                css={`
                  margin-right: 0.3125rem;
                `}
                href={'https://support.apple.com/en-us/HT201299'}
              >
                <>Cellular data settings</>
              </ExternalLink>
              should look like{' '}
              <ExternalLink href={ios2}>
                <>this</>
              </ExternalLink>
            </Trans>
          </li>
        </ul>
      </div>
    </Page>
  )
}
