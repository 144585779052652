import * as React from 'react'
import { ErrorInfo } from 'react'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { ErrorPage } from './components/common/ErrorPage'
import { getAppInsights } from './TelemetryService'

export class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_error: any) {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const appInsights = getAppInsights()

    const err = error instanceof Error ? error : new Error(error)
    if (appInsights) {
      appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error })
    }

    // eslint-disable-next-line no-console
    console.error(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />
    }
    return this.props.children
  }
}
