import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { colors } from '../../theme'
import { SectionContainer } from './styles'

interface Props {
  to: string
  title: string
  icon: JSX.Element
  disabled?: boolean | undefined
  subtitle?: string | undefined
}

const DisablerWrapper = ({ disabled, children }: { disabled: boolean; children: React.ReactElement }) => (
  <div
    css={`
      pointer-events: ${disabled ? 'none' : 'auto'};
      opacity: ${disabled ? '0.4' : '1'};
    `}
  >
    {children}
  </div>
)

const Subtitle = styled.p`
  margin: 0;
  font-size: 0.75rem;
  font-weight: 500;
`

export const SectionLink = ({ to, title, icon, disabled, subtitle }: Props) => (
  <DisablerWrapper disabled={disabled ?? false}>
    <Link
      to={to}
      css={`
        margin-top: 1.25rem;
        text-decoration: none;
        color: #000;
        display: block;
        font-size: 1.125rem;
        font-weight: 600;
      `}
    >
      <SectionContainer
        css={`
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          flex-direction: column;
        `}
      >
        <div
          css={`
            border-radius: 50%;
            width: 3.75rem;
            height: 3.75rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: ${colors.bgColor};
            margin-bottom: 1.25rem;
            svg {
              width: 60%;
              height: 60%;
            }
          `}
        >
          {icon}
        </div>
        {title}
        <Subtitle>{subtitle}</Subtitle>
      </SectionContainer>
    </Link>
  </DisablerWrapper>
)

// Helper method

const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) { newWindow.opener = null }
}

export const SectionLinkWithExternalURL = ({ to, title, icon, disabled, subtitle }: Props) => (
  <DisablerWrapper disabled={disabled ?? false}>
    <a
      // href={to}
      onClick={(e) => {
        e.preventDefault()
        openInNewTab(to)
      }}
      css={`
        margin-top: 1.25rem;
        text-decoration: none;
        color: #000;
        display: block;
        font-size: 1.125rem;
        font-weight: 600;
      `}
    >
      <SectionContainer
        css={`
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          flex-direction: column;
        `}
      >
        <div
          css={`
            border-radius: 50%;
            width: 3.75rem;
            height: 3.75rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: ${colors.bgColor};
            margin-bottom: 1.25rem;
            svg {
              width: 60%;
              height: 60%;
            }
          `}
        >
          {icon}
        </div>
        {title}
        <Subtitle>{subtitle}</Subtitle>
      </SectionContainer>
    </a>
  </DisablerWrapper>
)
