import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { config } from '../../config'
import { MailIconColor } from '../../icons/MailIcon'
import {
  FixedFooter,
  MobileBottomTermsLink,
  MobileContentBody,
  MobileContentWrapper,
  MobileNote,
  MobileSubtitle, SignInToolbar
} from '../../MobileStyledComponents'
import { EMAIL_SIGN_IN_ID } from '../../test-selectors'
import { colors } from '../../theme'
import { urls } from '../../urls'
import { Loading } from '../Loading/Loading'
import { WavelyLoginLink } from './SignIn'

export const MobileSignInTitle = styled.label`
  font-size: 40px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #19224c;
`

export const SignInMobile = () => {
  const [isLoading, setIsLoading] = useState(false)
  const onClick = () => setIsLoading(true)
  // const history = useHistory()

  return isLoading ? (
    <div
      css="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 100vh;
        "
    >
      <Loading
        css={`
          margin-top: 1.25rem;
        `}
        color={colors.hydraGreen}
      />
    </div>
  ) : (
    <>
      <SignInToolbar />

      <MobileContentWrapper>
        <MobileContentBody>
          <MobileSignInTitle css="margin-bottom: 17px;">Hello!</MobileSignInTitle>
          <MobileSubtitle css="margin-bottom: 44px;">
            <div>Activate a new SIM card or sign in to your account</div>
          </MobileSubtitle>
          <div
            css="
                    width: 100%;
                    "
          >
            {/* <WavelyLoginLink
              id={GOOGLE_SIGN_IN_ID}
              onClick={onClick}
              href={createSignInUrl('Google')}
              data-gtm-cta={'Sign-in-google'}
              css={{
                'justify-content': 'space-between',
              }}
            >
              {t('signinPage.loginButtons.google')} <GoogleIconColor />
            </WavelyLoginLink>
            <WavelyLoginLink
              id={APPLE_SIGN_IN_ID}
              onClick={onClick}
              href={createSignInUrl('Apple')}
              data-gtm-cta={'Sign-in-apple'}
              css={{
                'justify-content': 'space-between',
              }}
            >
              {t('signinPage.loginButtons.apple')} <AppleIconColor />
            </WavelyLoginLink>
            <WavelyLoginLink
              id={FACEBOOK_SIGN_IN_ID}
              onClick={onClick}
              href={createSignInUrl('Facebook')}
              data-gtm-cta={'Sign-in-facebook'}
              css={{
                'justify-content': 'space-between',
              }}
            >
              {t('signinPage.loginButtons.facebook')} <FacebookIconColor />
            </WavelyLoginLink> */}
            <WavelyLoginLink
              id={EMAIL_SIGN_IN_ID}
              onClick={onClick}
              href={urls.showMobileEmailLogin}
              // data-gtm-cta={'Sign-in-facebook'}
              css={{
                'justify-content': 'start',
              }}
            >
              <MailIconColor
                color={'#7F8E96'}
                css={`
                  margin-right: 23px;
                  width: 24px,
                  height: 24px,
                  vertical-align: middle;
                `}
              />
              Continue with your Email

            </WavelyLoginLink>
          </div>
        </MobileContentBody>
      </MobileContentWrapper>
      <FixedFooter>
        <MobileNote>
          <div>
            <Trans i18nKey="terms">
              {`By continuing, you agree to ` + `Wavely's `}
              <MobileBottomTermsLink href={config.serviceTermsUrl}>
                <>Service Terms</>
              </MobileBottomTermsLink>
              ,{' '}
              <MobileBottomTermsLink href={config.privacyPolicyUrl}>
                <>Privacy Policy</>
              </MobileBottomTermsLink>{' '}
              {'Wavely'}{' '}
              <MobileBottomTermsLink href={config.privacySupplementUrl}>
                <>Privacy Supplement</>
              </MobileBottomTermsLink>
            </Trans>
          </div>
        </MobileNote>
      </FixedFooter>
    </>
  )
}
