export const colors = {
  hydraGrey: '#E6E8EA',
  hydraGreen: '#00b7ae',
  hydraGreenOpaPointOne: 'rgba(0, 147, 154, 0.1)',
  hydraGreenOpaPointSix: 'rgba(0, 147, 154, 0.6)',
  lightGreen: '#39C89D',
  gunPowder: '#484852',
  errorRed: '#f94a61',
  warningRed: '#EF4E52',
  warningRedOpaPointOne: 'rgba(239,78,82, 0.1)',
  textColor: '#202124',
  greyText: '#8890A1',
  lightGrey: '#E6E8EA',
  dimText: '#A8DBDD',
  bgColor: '#f9faff',
  borderColor: '#E6F5F5',
  newBorderColor: 'rgba(0,30,45,0.1)',
  darkGreyBorder: '#E6E9EA',
  darkRed: '#A73E4B',
  darkGrey: '#001E2D',
  darkGreyOpaPointSix: 'rgb(0, 30, 45, 0.6)',
  darkGreyText: '#4d616c',
  concrete: '#F1F3F3',
  white: '#FFFFFF',
  red: '#CB4344',
  orange: 'rgba(242, 112, 66, 1)',
  yellow: '#FFCC31',
  teal: '#7BC5C8',
}

// TODO CHECK BORDER COLORS (made with opacity)
// TODO CHECK hydra green rgba colors

export const containerWidths = {
  sm: 320,
  modernPhone: 375,
  md: 700,
  lg: 1024,
  xl: 1280,
}
