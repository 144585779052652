import { fold } from 'fp-ts/es6/Either'
import { pipe } from 'fp-ts/lib/function'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { postSendMagicLinkEmail } from '../../api/api-auth'
import { PostSendMagicLinkEmailRequest } from '../../api/api-types'
import { config } from '../../config'
import { useErrorContext } from '../../context/ErrorContext'
import { useDataLoader } from '../../customHooks/useDataLoader'
import { exhaustiveCheck } from '../../exhaustive-switch-check'
import { MailIconColor } from '../../icons/MailIcon'
import { EMAIL_LOGIN_BUTTON_ID, EMAIL_LOGIN_INPUT_ID } from '../../test-selectors'
import { urls } from '../../urls'
import { createUrlWithHost } from '../../utils/url-utils'
import { WavelyButton } from '../Button/WavelyButton'
import { ExternalLink } from '../common/ExternalLink'

function validateEmail(email: string) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
export const BottomTermsWavely = styled.div`
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  letter-spacing: normal;
  text-align: left;
  color: #7d88af;
`

export const BottomTermsLink = styled(ExternalLink)`
  color: #00B7AE;
  text-decoration: none;
  text-decoration: underline;
`

const EmailContainer = styled.form`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
// margin-top: 1.875rem;

const EmailInput = styled.input`
  color: #000;
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  line-height: normal;
  font-size: 14px;

  /* chrome autofill styling fix */
  @keyframes autofill {
    0%,
    100% {
      color: #000;
      background: transparent;
    }
  }
  &:-webkit-autofill {
    animation-delay: 1s;
    animation-name: autofill;
    animation-fill-mode: both;
  }

  ::placeholder {
              color: #cbcfdf;
              opacity: 1; 
              font-size: 14px;
  }
`

const InputContainer = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.9375rem;
  margin-top: 0.5rem;
  border-radius: 10px;
  /* border: solid 1px #ebeef8; */
  background-color: #ffffff;
  height: 55px;
  border: ${(props) => (props.active ? 'solid 1px #00b7ae' : 'solid 1px #ebeef8')};
  &:hover {
    border: solid 1px #00b7ae;
  }
`

export const WavelyEmailLogin = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [emailFieldFocus, setEmailFieldFocus] = useState(false)
  const history = useHistory()
  const { onError } = useErrorContext()
  const magicLinkApi = useDataLoader(postSendMagicLinkEmail)

  const onEmailLogin = (event: React.FormEvent<HTMLFormElement | HTMLButtonElement>) => {
    event.preventDefault()
    const requestData: PostSendMagicLinkEmailRequest = {
      body: {
        email,
        branding: 'wavely',
        loginData: {
          uuid: null,
          program: 'Hydra',
          referer: null,
          userAgent: window.navigator.userAgent,
          ipAddress: null,
          representativeTribeId: null,
          mccMnc: null,
        },
        fcmToken: null,
        showPinCode: true,
        redirectUri: createUrlWithHost(urls.registeredUserRedirect),
      },
      apiKey: config.emailLoginApiKey,
    }
    magicLinkApi
      .query(requestData)
      .then((response) =>
        pipe(
          response,
          fold(
            (error) => {
              switch (error.type) {
                case 'FETCH_ERROR':
                case 'API_ERROR':
                  return onError(error, error.type)
                case 'ABORT_ERROR':
                case 'THROTTLE_ERROR':
                case 'WRONG_PIN_CODE':
                  return
                case 'AUTHENTICATION_EXPIRED_ERROR':
                  return onError(new Error('email magiclink login authentication renewal error'))
                case 'AUTHENTICATION_ERROR':
                  return onError(new Error('email magiclink login authentication error'))
                case 'NO_ACCOUNT':
                  return history.push(urls.createAccountWithEmail.clientUrl(email))
                default:
                  return exhaustiveCheck(error)
              }
            },
            (data) => history.push(urls.enterEmailLoginPinCode.clientUrl(email, data.code))
          )
        )
      )
      .catch(onError)
  }

  const emailIsInvalid = !validateEmail(email)

  return (
    <EmailContainer onSubmit={onEmailLogin}>
      <label
        css={`
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.54;
          letter-spacing: normal;
          text-align: left;
          color: #19224c;
        `}
      >
        Email
        <InputContainer active={emailFieldFocus}>
          <MailIconColor
            color={'#7F8E96'}
            css={`
                height: 23px;
                width: 23px;
                margin-left: 29px;
                margin-right: 23px;
              `}
          />
          <EmailInput
            id={EMAIL_LOGIN_INPUT_ID}
            onChange={(e) => setEmail(e.target.value)}
            onFocus={(_e) => setEmailFieldFocus(true)}
            onBlur={(_e) => setEmailFieldFocus(false)}
            type="email"
            placeholder={t('emailLogin.placeholder')}
            name="email"
          />
        </InputContainer>
      </label>
      <div
        css={`
        position: fixed;
        bottom: 40px;
      `}
      >
        <WavelyButton
          css={`
            width: 100%;
            margin-right: 3.125rem;
          `}
          id={EMAIL_LOGIN_BUTTON_ID}
          type={'submit'}
          loading={magicLinkApi.isLoading}
          disabled={magicLinkApi.isLoading || emailIsInvalid}
          text={
            <div
              css={`
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 800;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.54;
                letter-spacing: normal;
                text-align: center;
              `}
            >
              {t('emailLogin.continue')}

            </div>
          }
          onSubmit={onEmailLogin}
        />
        <BottomTermsWavely css='margin-top: 25px; 
        width: 100%;
        max-width: 45vw;'>
          <div>
            <Trans i18nKey="terms">
              {`By continuing, you agree to ` + `Wavely's `}
              <BottomTermsLink href={config.serviceTermsUrl}>
                <>Service Terms</>
              </BottomTermsLink>
              ,{' '}
              <BottomTermsLink href={config.privacyPolicyUrl}>
                <>Privacy Policy</>
              </BottomTermsLink>{' '}
              {'Wavely'}{' '}
              <BottomTermsLink href={config.privacySupplementUrl}>
                <>Privacy Supplement</>
              </BottomTermsLink>
            </Trans>
          </div>
        </BottomTermsWavely>
      </div>
    </EmailContainer>
  )
}
