const parseBooleanString = (value?: string) => value === 'true'

export const config = {
  apiBaseUrl: process.env.API_BASE_URL as string,
  authApiBaseUrl: process.env.AUTH_API_BASE_URL as string,
  appInsightsKey: process.env.APP_INSIGHTS_KEY as string,
  appVersion: process.env.APP_VERSION as string,
  nodeEnv: process.env.NODE_ENV as string,
  serviceTermsUrl: process.env.SERVICE_TERMS_URL as string,
  privacyPolicyUrl: process.env.PRIVACY_POLICY_URL as string,
  privacySupplementUrl: process.env.PRIVACY_POLICY_SUPPLEMENT_URL as string,
  checkoutUrl: 'https://www.hmdconnect.com/checkout',
  contentfulGraphQlUrl: 'https://graphql.contentful.com/content/v1/spaces/139shpeoio1v',
  contentfulApiKey: process.env.CONTENTFUL_API_KEY as string,
  contentfulPreview: parseBooleanString(process.env.PREVIEW_CONTENTFUL_CONTENT),
  enableSimulateDataUsageButton: parseBooleanString(process.env.ENABLE_SIMULATE_DATA_USAGE_BUTTON),
  enableDeleteAccountButton: parseBooleanString(process.env.ENABLE_DELETE_ACCOUNT_BUTTON),
  emailLoginApiKey: process.env.EMAIL_LOGIN_API_KEY as string,
  useApiProxy: parseBooleanString(process.env.USE_API_PROXY),
  showRenewPackage: parseBooleanString(process.env.SHOW_RENEW_PACKAGE),
  serviceWorkerDisabled: parseBooleanString(process.env.DISABLE_SW),
  showNonProductionEnvWarning: parseBooleanString(process.env.IS_NON_PROD_ENV),
  enableLocalization: parseBooleanString(process.env.ENABLE_LOCALIZATION),
  enableEsimFeature: parseBooleanString(process.env.ENABLE_ESIM_FEATURE),
  freshdeskEmailTicketApiBaseUrl: process.env.FRESHDESK_EMAIL_API_BASE_URL as string,
  freshdeskEmailTicketApiKey: process.env.FRESHDESK_EMAIL_API_KEY as string,
  freshchatToken: process.env.FRESHCHAT_TOKEN as string,
  freshchatHost: process.env.FRESHCHAT_HOST as string,
}
