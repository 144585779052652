import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { WretcherError } from 'wretch'
import { getAppInsights } from '../TelemetryService'

export interface ErrorParams {
  error: any
  details?: string
}

export const parseUnknownError = (error: any) => {
  if (error && typeof error.status === 'number') {
    const wretchError = error as WretcherError
    return new Error(`Network error. HTTP status: ${wretchError.status}`)
  }

  if (error instanceof Error) {
    return error
  }

  if (error.constructor === String) {
    return new Error(error as string)
  }
  try {
    return new Error(JSON.stringify(error))
  } catch {
    return new Error(`Unknown error`)
  }
}

export const reportError = (error: any, additionalInformation?: string) => {
  const appInsights = getAppInsights()
  if (appInsights) {
    appInsights.trackException({ error, severityLevel: SeverityLevel.Error })
    if (additionalInformation) {
      appInsights.trackTrace({ message: additionalInformation, severityLevel: SeverityLevel.Error })
    }
  }
}
